import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-both-offer-dates',
  templateUrl: './change-both-offer-dates.component.html',
  styleUrls: ['./change-both-offer-dates.component.scss']
})
export class ChangeBothOfferDatesComponent implements OnInit {
  @Input() type;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.type === 'casco') {
      this.type = 'rca';
    } else {
      this.type = 'casco';
    }
  }

  recalculateJustOne() {
    this.passEntry.emit(this.type);
  }

  recalculateBoth() {
    this.passEntry.emit('both');
  }
}
