import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component, OnDestroy,
  OnInit
} from '@angular/core';
import {ProductInstallment} from 'src/app/models/installment.model';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import {PaymentService} from '../../../../services/payment.service';
import {setCreditNoteUUID} from '../../../../store/actions/selectedOffer.actions';
import {Store} from '@ngrx/store';
import {
  AppState,
  selectPayInstallmentAndCar,
  selectUnPayedInstallment,
  selectUUIDCreditNote
} from '../../../../store';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {fetchPoliciesData} from '../../../../store/actions/policies.actions';
import {ApiService} from '../../../../services/api.service';
import {environment} from '../../../../../environments/environment';
import {GetDataService} from '../../../../shared/services/get-data.service';
import {AddLicencePlateNumberComponent} from '../../../../shared/components/add-licence-plate-number/add-licence-plate-number.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PlatformLocation} from '@angular/common';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'app-pay-installment',
  templateUrl: './pay-installment.component.html',
  styleUrls: ['./pay-installment.component.scss']
})
export class PayInstallmentComponent implements OnInit, OnDestroy{
  warningTypesEnums: any;
  cart: any;
  productData: ProductInstallment;
  creditNotePayRateUUID;
  error;
  loading = true;
  errorMsg;
  sub: Subscription;
  selectPayInstallmentAndCarSub: Subscription;
  isOverdueDateSub: Subscription;
  creditNoteTokenId;
  resCreditNotePayRateData;

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private apiService: ApiService,
    private getDataService: GetDataService,
    private store: Store<AppState>,
    private router: Router,
    private ref: ChangeDetectorRef,
    private platformLocation: PlatformLocation
  ) {
    platformLocation.onPopState(() => this.modal.dismissAll());
    this.productData = new ProductInstallment();
  }

  ngOnInit(): void {
    // this.isOverdueDate();
    this.warningTypesEnums = WarningTypeEnum;
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        if (!!params['credit-note-token']) {
          this.store.dispatch(fetchPoliciesData());
          this.creditNoteTokenId = params['credit-note-token'];
        }
      });
    this.paymentService.getCreditNoteData(this.creditNoteTokenId)?.subscribe(cart => {
      if (cart){
        this.cart = cart;
        this.productData.policyNumber = this.cart[0].policy.number;
        this.paymentService.getCreditNotePayRateData(this.cart[`0`].creditNote.id).subscribe(resCreditNotePayRateData => {
          this.resCreditNotePayRateData = resCreditNotePayRateData;
          this.productData.dueDate = this.resCreditNotePayRateData.dueDate;
          this.loading = false;
          this.store.dispatch(setCreditNoteUUID({setCreditNoteUUID: resCreditNotePayRateData[`uuid`]}));
        });
      } else {

      }
    });
    setTimeout(() => this.getLicenceNumber());
  }
  ngOnDestroy() {
    if (this.isOverdueDateSub) {
      this.isOverdueDateSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.selectPayInstallmentAndCarSub) {
      this.selectPayInstallmentAndCarSub.unsubscribe();
    }
  }

  getIsOverdueDate() {
    return JSON.parse(sessionStorage.getItem('isOverdueDate'));
  }



  get cartItem() {
    return this.cart[0];
  }

  getLicenceNumber() {
   this.selectPayInstallmentAndCarSub = this.store.select(selectPayInstallmentAndCar).subscribe(carAndPolicy => {
      if (carAndPolicy) {
        this.goToAddLicenceNumber(carAndPolicy);
      }
    });
  }

  goToAddLicenceNumber(item) {
    setTimeout(() => {
      if (item.vehicle?.registrationNumber === '' || item.vehicle?.registrationNumber === null) {
        const modalRef = this.modal.open(AddLicencePlateNumberComponent as Component, {
          centered: true, size: 'lg',
          keyboard: false, backdrop: 'static'
        });
        modalRef.componentInstance.data = {item};
        modalRef.result.then((result) => {
        }, (reason) => {
          this.modal.dismissAll();
        });
      }
    }, 1000);
  }

  payRate() {
    this.store.select(selectUUIDCreditNote).pipe(take(1)).subscribe(payRateUUID => {
      this.creditNotePayRateUUID = payRateUUID;
    });
    this.paymentService.preparePayment(this.creditNotePayRateUUID).subscribe(preparePayment => {
      const preparePaymentResp: any = preparePayment;
      if (preparePaymentResp.error === true) {
        this.errorMsg = preparePaymentResp.message;
      } else {
        this.paymentService.redirectByPost(preparePayment[`data`], environment.netopiaUrl);
      }
    });
  }

  get productItem() {
    return this.productData;
  }

}
