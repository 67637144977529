import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentsFilter',
    pure: false
})
//Get the first unpayed installment
export class PolicyPaymentsFilterPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        return [items.filter(installment => installment.payment === null)[0]];
    }
}
