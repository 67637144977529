import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Utils from '../../../../shared/utils/utils';

@Component({
  selector: 'app-warning-change-configuration-implications',
  templateUrl: './warning-change-configuration-implications.component.html',
  styleUrls: ['./warning-change-configuration-implications.component.scss']
})
export class WarningChangeConfigurationImplicationsComponent implements OnInit {

  appType = environment.app;

  faClose = faWindowClose;
  isMobile: boolean;
  constructor(private modal: NgbModal) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
  }

  closeModal(){
    this.modal.dismissAll();
  }
}
