import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {fetchPoliciesToDownload, fetchPolicyConfirmationData} from '../../../store/actions/policies.actions';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {
  orderId;
  subParams: Subscription;
  respPaymentConfirmation;
  policiesToDownloadIdOBJ = [];
  error = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const toast = this.toastr.currentlyActive;
      this.toastr.clear(toast);
    });
    // 9900004810225098
    // 111
    // /payment-confirmation/37dd3f19-dfcf-4941-8c63-35c760c20ec8?orderId=37dd3f19-dfcf-4941-8c63-35c760c20ec8 rate
    // /payment-confirmation/6ccf7914-36e2-417a-a3f4-86ef79095de7?orderId=6ccf7914-36e2-417a-a3f4-86ef79095de7 offer
    setTimeout(() => {
        this.orderId = this.activatedRoute.snapshot.params.orderId;
        if (this.orderId) {
          this.checkNetopiaPaymentStatus();
        } else {
          this.router.navigate(['/home']);
        }
      }, 15000
    );
  }

  checkNetopiaPaymentStatus() {
    this.paymentService.getCreditNote(this.orderId).subscribe(paymentConfirmation => {
      this.paymentService.checkNetopiaPaymentConfirmation(paymentConfirmation[`data`][0].id).subscribe(checkNetopiaPaymentConfirmation => {
        this.respPaymentConfirmation = checkNetopiaPaymentConfirmation;
        if (this.respPaymentConfirmation) {
          this.respPaymentConfirmation.forEach(respPaymentConfirmationItem => {
            if (respPaymentConfirmationItem.installmentNumber === 1) {
              if (respPaymentConfirmationItem.isPayed === true && respPaymentConfirmationItem.policy !== null) {
                this.policiesToDownloadIdOBJ.push(respPaymentConfirmationItem.policy.id);
              }
              if (this.policiesToDownloadIdOBJ.length > 0) {
                this.store.dispatch(fetchPoliciesToDownload({policesToDownload: this.policiesToDownloadIdOBJ}));
                this.router.navigate(['/offer-download']);
              } else {
                this.error = true;
              }
            } else if (respPaymentConfirmationItem.installmentNumber > 1) {
              if (respPaymentConfirmationItem.isPayed === true) {
                this.store.dispatch(fetchPolicyConfirmationData({policyPaymentConfirmationData: respPaymentConfirmationItem}));
                this.router.navigate(['/payment-confirmed']);
              } else {
                this.error = true;
              }
            }
          });
        } else {
          this.error = true;
        }
      });
    });
  }

}
