import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {LoginSuccess} from '../store/actions/auth.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {ApiService} from './api.service';
import {LocalStorageService} from './local-storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private modal: NgbModal,
    private toastrService: ToastrService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addAuthHeader(req, next));
  }

  private addAuthHeader(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.startsWith(environment.apiUrl)) {
      const tokenOkta = window.sessionStorage.getItem('okta-token-storage');
      const responseApigee = window.sessionStorage.getItem('apigee');
      const loginSuccess = [{tokenOkta, responseApigee}];
      this.store.dispatch(LoginSuccess({payload: loginSuccess}));


      if (this.jwtHelper.isTokenExpired(tokenOkta)) {
        sessionStorage.clear();
        localStorage.clear();
        this.modal.dismissAll();
        this.store.dispatch(LoginSuccess({payload: loginSuccess}));
        this.router.navigate(['/login']);
      } else {}
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'MARSH-SPA userAccessToken=' + tokenOkta + ',apiGatewayAccessToken=' + responseApigee)
      });
    }
    // return next.handle(req).toPromise();
    return next.handle(req).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 404 || err.status === 500) {
            this.toastrService.error('Ne pare rău, a intervenit o eroare. Te rugăm să ne contactezi la adresa de mail asigurare.cobrand@marsh.com sau la numărul de telefon 021.380 85 53 de luni până vineri în intervalul 9.00-18.00.', '', {
              timeOut: 8000,
            });
          }
          if (err.status === 401) {
            this.toastrService.info('Sesiunea a expirat. Mulțumim pentru intelegere.', '', {
              timeOut: 5000,
            });
            sessionStorage.clear();
            localStorage.clear();
            this.router.navigate(['/login']);
            location.reload();
          }
        }
      }));
  }

}

