import {Action} from '@ngrx/store';
import {PoliciesActionTypes} from '../actions/policies.actions';

export interface StatePolicies {
  policies: any;
  selectedPolicyInstallments: any;
  selectedPolicyHistory: any;
  policesToDownload: any;
  policyPaymentConfirmationData: any;
  payInstallmentAndCar: any;
  unPayedInstallment: any;
}

export const initialState: StatePolicies = {
  policies: [{}],
  selectedPolicyInstallments: null,
  selectedPolicyHistory: null,
  policesToDownload: null,
  policyPaymentConfirmationData: null,
  payInstallmentAndCar: null,
  unPayedInstallment: null
};

export function reducer(statePolicies = initialState, action: Action): StatePolicies {
  switch (action.type) {
    case PoliciesActionTypes.FETCH_POLICIES_DATA:
      return {
        ...statePolicies
      };
    case PoliciesActionTypes.FETCH_POLICIES_DATA_SUCCESS:
      return {
        ...statePolicies,
        policies: action[`myPolicies`]
      };
    case PoliciesActionTypes.FETCH_POLICY_INSTALLMENTS:
      return {
        ...statePolicies,
        selectedPolicyInstallments: action[`selectedPolicyInstallments`][`0`]
      };
    case PoliciesActionTypes.FETCH_POLICY_HISTORY:
      return {
        ...statePolicies,
        selectedPolicyHistory: action[`selectedPolicyHistory`][`0`]
      };
    case PoliciesActionTypes.FETCH_POLICIES_TO_DOWNLOAD:
      return {
        ...statePolicies,
        policesToDownload: action[`policesToDownload`]
      };
    case PoliciesActionTypes.FETCH_POLICY_CONFIRMATION_DATA:
      return {
        ...statePolicies,
        policyPaymentConfirmationData: action[`policyPaymentConfirmationData`]
      };
    case PoliciesActionTypes.FETCH_INSTALLMENT_TO_PAY_AND_CAR:
      return {
        ...statePolicies,
        payInstallmentAndCar: action[`installmentToPayAndCar`]
      };
    case PoliciesActionTypes.FETCH_UN_PAYED_INSTALLMENT:
      return {
        ...statePolicies,
        unPayedInstallment: action[`unPayedInstallment`]
      };
    default:
      return statePolicies;
  }
}


export const fetchMyPolicies = (statePolicies: StatePolicies) => statePolicies.policies;
export const fetchMyPolicyInstallments = (statePolicies: StatePolicies) => statePolicies.selectedPolicyInstallments;
export const fetchMyPolicyHistory = (statePolicies: StatePolicies) => statePolicies.selectedPolicyHistory;
export const fetchMyPolicesToDownload = (statePolicies: StatePolicies) => statePolicies.policesToDownload;
export const fetchPolicyConfirmationData = (statePolicies: StatePolicies) => statePolicies.policyPaymentConfirmationData;
export const fetchPayInstallmentAndCar = (statePolicies: StatePolicies) => statePolicies.payInstallmentAndCar;
export const fetchUnPayedInstallment = (statePolicies: StatePolicies) => statePolicies.unPayedInstallment;
