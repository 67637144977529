import {RecoveryPassComponent} from '../core/components/auth/recovery-pass/recovery-pass.component';
import {ChangeEmailComponent} from '../core/components/auth/change-email/change-email.component';
import {ChangePassFromMyAccountComponent} from '../core/components/auth/change-pass-from-my-account/change-pass-from-my-account.component';
import {UserCarSelectorComponent} from '../pages/user/user-car-selector/user-car-selector.component';
import {PayInstallmentComponent} from '../pages/payment/manage-payments/pay-installment/pay-installment.component';
import {PIDComponent} from '../shared/components/footer-links/pid/pid.component';
import {CookiePolicyComponent} from '../shared/components/footer-links/cookie-policy/cookie-policy.component';
import {
  FrequentQuestionsComponent
} from '../shared/components/footer-links/frequent-questions/frequent-questions.component';
import {TermsConditionsComponent} from '../shared/components/footer-links/terms-conditions/terms-conditions.component';
import {GdprComponent} from '../shared/components/footer-links/gdpr/gdpr.component';
import {AboutUsComponent} from '../shared/components/footer-links/about-us/about-us.component';
import {PaymentHistoryComponent} from '../pages/payment/manage-payments/payment-history/payment-history.component';

export const MappingNames = {
  HomeComponent: 'Asigurări',
  PayRateComponent: 'Plată rată',
  OfferComponent: '',
  PaymentsComponent: 'Plăți',
  AdaugareNumarComponent: 'Adăugare Numar',
  PoliciesListComponent: 'Polițele mele',
  UserDetailsComponent: 'Date personale',
  UserCarDetailsComponent: 'Date mașină',
  ManagePaymentsComponent: 'Plățile mele',
  PaymentConfirmedComponent: 'Plată rată',
  PetitionsComponent: 'Petiții',
  ContactComponent: 'Contact',
  LoginComponent: 'Autentificare în platforma de vânzări asigurări',
  RegisterComponent: 'Creează cont',
  ChangeEmailComponent: 'Schimbă email',
  ChangePassFromMyAccountComponent: 'Schimbă parola',
  RecoveryPassComponent: 'Recuperare parolă',
  UserCarSelectorComponent: 'Date MAȘINĂ',
  PayInstallmentComponent: 'Plată rată',
  PIDComponent: 'PRODUSELE / SERVICIILE NOASTRE',
  CookiePolicyComponent: 'Politica de cookies',
  FrequentQuestionsComponent: 'ÎNTREBĂRI FRECVENTE',
  TermsConditionsComponent: 'Termeni și condiții ',
  GdprComponent: 'Politica de prelucrare a datelor cu caracter personal',
  AboutUsComponent: 'Despre noi ',
  PaymentHistoryComponent: 'Istoric Plați',
};

export class WarningType {
  name: '';
  color: '';
}

export enum WarningTypeEnum {
  OfferCasco,
  OfferRca,
  Car,
  UserFirst,
  UserSecond,
  ChangeEmail,
  AiAlesSaCumperi,
  SolicitareConsultant,
  OfferSummaryUrgent,
  CerereReactivarePolita,
  InspectieRiscAsigurator,
  InspectieRiscTipCasco,
  PolicyDueDate,
  OfferPreconfiguration,
  MtplPreconfigurationDate,
  PolicyReactivation
}

export const MonthMapping = [
  'ian',
  'feb',
  'mar',
  'apr',
  'mai',
  'iun',
  'iul',
  'aug',
  'sept',
  'oct',
  'nov',
  'dec'
  ];

export const InsurerCodes = [
    'AZT', // Allianz
    'OMN', // Omniasig
    'GAM' // Groupama
  ];

export const CascoInstallments = [
    '1',
    '4',
    '12'
  ];

export const OfferSteps = {
    "casco": 1,
    "mtpl": 2,
    "payment": 3,
    "download": 4
  };
