import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RenewalOfferProduct } from 'src/app/models/casco-table.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AppState } from 'src/app/store';
import { toggleIfSelectedOfferCASCO, toggleIfSelectedOfferMTPL } from 'src/app/store/actions/offerToggle.actions';
import { setSelectedOfferCASCO, setSelectedOfferMTPL } from 'src/app/store/actions/selectedOffer.actions';

@Injectable({
  providedIn: 'root'
})
export class SetRenewalProductsService {
    private cascoProducts: any[] = [];
    private mtplProducts: any[] = [];

    constructor(private store: Store<AppState>, private localStorage: LocalStorageService) {}

    saveProducts(offerId: number){
      if (this.cascoProducts && this.cascoProducts.length > 0){
        const renewalProduct = this.cascoProducts.find(product => product.offerId === offerId);
        if (renewalProduct){
          this.saveRenewalCascoDataToStore(renewalProduct);
          this.saveRenewalProductToLocalStorage(renewalProduct);
        } else {
          this.saveRenewalCascoDataToStore(null);
        }
      } else if (this.mtplProducts && this.mtplProducts.length > 0){
        const renewalProduct = this.mtplProducts.find(product => product.offerId === offerId);
        if (renewalProduct){
          this.saveRenewalMTPLDataToStore(renewalProduct);
          this.saveRenewalProductToLocalStorage(renewalProduct);
        } else {
          this.saveRenewalMTPLDataToStore(null);
        }
      }
    }

    setRenewalData(offerId: number, policy?: any) {
      const renewalProduct = new RenewalOfferProduct();
      renewalProduct.productId = policy.product.id;
      renewalProduct.offerId = offerId;
      renewalProduct.packageId = policy.offerProduct?.package.id;
      renewalProduct.installmentNumberId = policy.installmentNumber.id;
      renewalProduct.type = policy.product.type.code;
      renewalProduct.cascoType = policy.product.cascoType?.code;
      renewalProduct.cascoProviderName = policy.product.insurer.name;
      renewalProduct.renewalDueDate = new Date(policy.endDate);
      if (renewalProduct.cascoType){
        this.cascoProducts.push(renewalProduct);
      } else {
        this.mtplProducts.push(renewalProduct);
      }
    }

    private saveRenewalCascoDataToStore(product){
      this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: [product]}));
      this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: true}));
    }

    private saveRenewalMTPLDataToStore(product){
      this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: [product]}));
      this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: true}));
    }

    private saveRenewalProductToLocalStorage(product){
      const productRenewalProductToLocalStorage = JSON.stringify(product);
      sessionStorage.setItem('selectedProduct', productRenewalProductToLocalStorage);
    }
}
