import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {POLICY_TYPES} from '../../../models/casco-table.model';
import {ProgressModalComponent} from 'src/app/shared/components/progress-modal/progress-modal.component';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {AppState, selectMyCars, selectOfferListData, selectUnPayedInstallment} from 'src/app/store';
import {GetDataService} from '../../../shared/services/get-data.service';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {SetRenewalProductsService} from '../../../shared/services/set-renewal-products.service';
import {AppStorageService} from 'src/app/services/app-storage.service';
import {filter, take} from 'rxjs/operators';
import {UpdateNotificationsService} from '../../../services/update-notifications.service';
import {PayRateObserverService} from '../../../shared/services/pay-rate-observer.service';
import {ApiService} from '../../../services/api.service';
import {setSelectedOfferCASCO} from '../../../store/actions/selectedOffer.actions';
import {OfferErrorsService} from '../../../services/offer-errors.service';
import {environment} from '../../../../environments/environment';
import * as fromStore from '../../../store';
import {fetchInstallmentToPayAndCar} from '../../../store/actions/policies.actions';
import {PaymentService} from '../../../services/payment.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: any = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  notificationsRate: any = [];
  backToOfferNotification: boolean;
  customerId;
  @Input() sideMenu: boolean;
  customerVehicles: any[] = [];
  update;
  registrationNumber: string;
  loadingInNotifications = true;
  allUnfilteredPolicies: any;
  componentName: any;
  offerWithSamePolicyChassis: any;
  env;
  hasWrongData = false;

  constructor(private modal: NgbModal,
              private store: Store<AppState>,
              private router: Router,
              private localStorageService: LocalStorageService,
              private myPoliciesService: MyPoliciesService,
              private getDataService: GetDataService,
              private updateNotificationsService: UpdateNotificationsService,
              private appStorageService: AppStorageService,
              private apiService: ApiService,
              private paymentService: PaymentService,
              private offerErrorsService: OfferErrorsService,
              private payRateObserverService: PayRateObserverService,
              private getRenewalProductsService: SetRenewalProductsService) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe((e: NavigationEnd) => {
      this.componentName = e.url;
    });
  }

  public ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.apiService.sessionData().subscribe(sessionData => {
      if (sessionData) {
        this.hasWrongData = sessionData.hasWrongData;
      }
    });
    this.env = environment.env;
    if (window.sessionStorage.getItem('showToast')) {
      this.backToOfferNotification = JSON.parse(window.sessionStorage.getItem('showToast'));
    }
    setTimeout(() => {
      this.getAllUnfilteredPolicies();
    }, 800);
    setTimeout(() => {
      this.store.select(fromStore.selectOfferDataCustomer).subscribe(customer => {
        if (customer && customer.id) {
          this.getAllUnfilteredPolicies();
          this.myPoliciesService.checkUnpayedPolicies(customer.id)?.then(result => {
            if (result) {
              this.payRateObserverService.sendUnpayedRateCheck(result);
            }
          }).catch(error => {
            console.error(error);
          });
        }
      });
    });
    this.getDataService.getOffers();
    this.getPayRateNotification();
    // this.apiService.sessionData().subscribe(() => {});
    setTimeout(() => {
      this.loadingInNotifications = false;
    }, 4000);

  }

  setIsOverdueDate(date) {
    const currentDate = new Date();
    const policyDueDate = new Date(date);
    if (currentDate > policyDueDate) {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(true));
    } else {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(false));
    }
  }

  validateInstallment(installmentProduct, chassisId, policy) {
    this.store.select(selectMyCars).subscribe(allCars => {
      if (allCars) {
        allCars.forEach(car => {
          if (car.chassis === chassisId && (car.registrationNumber === null || car.registrationNumber === '')) {
            const installmentPolicyAndCar = {vehiclePolicy: policy, vehicle: car};
            this.store.dispatch(fetchInstallmentToPayAndCar({installmentToPayAndCar: installmentPolicyAndCar}));
          }
        });
      }
    });
    this.setIsOverdueDate(installmentProduct.dueDate);
    this.paymentService
      .validateInstallment(installmentProduct)
      .then(_ => {
        this.router.navigate(['/pay-installment']);
      });
    // send policy installment data to service
    this.paymentService.setProductData(installmentProduct);
  }

  closeOfferNotification() {
    window.sessionStorage.setItem('showToast', 'false');
  }

  getPayRateNotification() {
    this.store.select(selectUnPayedInstallment).subscribe(unPayedInstallment => {
      if (unPayedInstallment) {
        if (this.allUnfilteredPolicies) {
          let policyType = '';
          let policyRateNR = '';
          const policyFull = this.allUnfilteredPolicies.filter(policy => policy.number === unPayedInstallment.policy.number);
          const getPolicyVehicle = this.customerVehicles.filter(vehicle => policyFull['0'].vehicle.chassis === vehicle.chassis);
          if (unPayedInstallment.policy.product.type.code === 'MTPL') {
            policyType = 'RCA';
            policyRateNR = unPayedInstallment.number;
          } else {
            policyType = unPayedInstallment.policy.product.type.code;
            policyRateNR = unPayedInstallment.number;
          }
          if (getPolicyVehicle.length > 0) {
            const notification = {
              message: `Plătește rata ${policyRateNR} pentru polița ${policyType} ${policyFull['0'].vehicle.model.name} ${getPolicyVehicle['0'].registrationNumber}`,
              policyNumber: unPayedInstallment.policy.number,
              policy: policyFull,
              vehicleChassis: policyFull['0'].vehicle.chassis,
              installmentProduct: unPayedInstallment
            };
            if (!this.notificationsRate.find(notificationList => notificationList.policyNumber === unPayedInstallment.policy.number)) {
              this.notificationsRate.push(notification);
            }
          } else {
            const notification = {
              message: `Plătește rata ${policyRateNR} pentru polița ${policyType} ${policyFull['0'].vehicle.model.name}`,
              policyNumber: unPayedInstallment.policy.number,
              policy: policyFull,
              vehicleChassis: policyFull['0'].vehicle.chassis,
              installmentProduct: unPayedInstallment
            };
            if (!this.notificationsRate.find(notificationList => notificationList.policyNumber === unPayedInstallment.policy.number)) {
              this.notificationsRate.push(notification);
            }
          }
        }
      }
    });
  }

  goToRate() {
    this.router.navigate(['/pay-installment']);
  }

  getAllUnfilteredPolicies() {
    const customerId = window.sessionStorage.getItem('customerId');
    this.myPoliciesService.getAllPoliciesByCustomerId(customerId).subscribe(allUnfilteredPolicies => {
      if (allUnfilteredPolicies) {
        const allUnfilteredPoliciesArray = allUnfilteredPolicies.data;
        if (allUnfilteredPoliciesArray && allUnfilteredPolicies.count > 0) {
          this.allUnfilteredPolicies = allUnfilteredPoliciesArray;
          this.getNotificationsForOffers();
        } else {
          this.getNotificationsForOffers();
        }
      }
    });
  }

  get showToastStatus() {
    return window.sessionStorage.getItem('showToast');
  }

  get compWithoutToastMessage() {
    return this.componentName === '/offer-download' ||
      this.componentName === '/offer?type=casco' ||
      this.componentName === '/offer?type=rca' ||
      this.componentName === '/offer-summary' ||
      this.componentName === '/payment-confirmation' ||
      this.componentName === '/report-wrong-data';
  }

  goToOfferSideMenu() {
    const offerId = JSON.parse(window.sessionStorage.getItem('offerId'));
    const policyMTPL = JSON.parse(window.sessionStorage.getItem('policyMTPL'));
    const policyCASCO = JSON.parse(window.sessionStorage.getItem('policyCASCO'));
    sessionStorage.setItem('justMtplButtonPressed', 'false');

    if (offerId) {
      this.getDataService.getOfferById(offerId);
      this.store.pipe(select(selectOfferListData), take(1)).subscribe(offers => {
        if (offers && offers.length > 0) {
          const offer = offers.find(offerList => offerList.id === offerId);
          if (offer) {
            let type: any;
            if ((offer.offerForCasco === true && offer.offerForMtpl === true) && (policyMTPL === true && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === false) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === false && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else {
              type = POLICY_TYPES.casco;
            }
            this.getRenewalProductsService.saveProducts(offer.id);
            this.router.navigate(['/offer'], {queryParams: {type}});
          }
        }
      });
    }
  }

  goToOffer(offerId: number, policyMTPL, policyCASCO) {
    if (offerId) {
      this.getDataService.getOfferById(offerId);
      window.sessionStorage.setItem('offerId', String(offerId));
      window.sessionStorage.setItem('showToast', String(true));
      window.sessionStorage.setItem('policyMTPL', policyMTPL);
      window.sessionStorage.setItem('policyCASCO', policyCASCO);
      sessionStorage.setItem('justMtplButtonPressed', 'false');

      this.store.pipe(select(selectOfferListData), take(1)).subscribe(offers => {
        if (offers && offers.length > 0) {
          const offer = offers.find(offerList => offerList.id === offerId);
          if (offer) {
            let type: any;
            if ((offer.offerForCasco === true && offer.offerForMtpl === true) && (policyMTPL === true && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === false) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === false && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else {
              type = POLICY_TYPES.casco;
            }
            this.getRenewalProductsService.saveProducts(offer.id);
            this.router.navigate(['/offer'], {queryParams: {type}});
          }
        }
      });
    }
  }

  get offerActive() {
    sessionStorage.setItem('activeOffers', String(this.notifications.length > 0));
    return this.notifications.length > 0;
  }

  private getNotificationsForOffers() {
    this.store.select(selectMyCars).subscribe(result => {
      if (result !== null) {
        this.customerVehicles = result;
        if (Object.keys(this.customerVehicles).length >= 1) 
        {
          this.customerVehicles.forEach(vehicle => {
            console.log("Vehicle "+JSON.stringify(vehicle['make']['name']));
            const vehicleChassis = vehicle[`chassis`];
            const vehicleRegistrationNumber = vehicle.registrationNumber;
            if( vehicle && vehicle[`make`][`name`].toLowerCase() != 'nissan')
            {
              if ( vehicle[`status`] && vehicle[`status`][`code`].toLowerCase() === 'used')
              {
                if (vehicleRegistrationNumber !== null) {
                  this.checkForRenewalOrNewOffer(vehicleChassis, vehicle[`status`][`code`].toLowerCase(), vehicleRegistrationNumber);
                } else {
                  this.checkForRenewalOrNewOffer(vehicleChassis, vehicle[`status`][`code`].toLowerCase(), '');
                }
              } else {
                if (vehicleRegistrationNumber !== null) {
                  this.checkForRenewalOrNewOffer(vehicleChassis, vehicle[`status`][`code`].toLowerCase(), vehicleRegistrationNumber);
                } else {
                  this.checkForRenewalOrNewOffer(vehicleChassis, vehicle[`status`][`code`].toLowerCase(), '');
                }
              }
            }
          });
        }
      }
    });
  }

  checkForRenewalOrNewOffer(vehicleChassis: string, vehicleStatus: string, vehicleRegistrationNumber: string) {
    if (this.allUnfilteredPolicies) {
      const allPolicyesByChassisNumber = this.allUnfilteredPolicies.filter(policy => policy.vehicle.chassis === vehicleChassis);
      if (allPolicyesByChassisNumber.length > 0) {
        this.getOfferOrRenewalNotifications(allPolicyesByChassisNumber, vehicleChassis, vehicleStatus, vehicleRegistrationNumber);
      } else {
        this.getOfferNotifications(vehicleRegistrationNumber, vehicleChassis);
      }
    } else {
      this.getOfferNotifications(vehicleRegistrationNumber, vehicleChassis);
    }
  }

  private getOfferOrRenewalNotifications(policies: any, vehicleChassis: string, vehicleStatus: string, vehicleRegistrationNumber: string) {
    if (policies) {
      const cascoPolicy = policies.filter(policy => policy.product.type.code === 'CASCO' && policy.vehicle.chassis === vehicleChassis);
      const mtplPolicy = policies.filter(policy => policy.product.type.code === 'MTPL' && policy.vehicle.chassis === vehicleChassis);

      this.store.select(selectOfferListData).subscribe(allOffers => {
        if (policies) {
          this.offerWithSamePolicyChassis =
            allOffers.find(offerList => offerList[`vehicle`][`chassis`] === cascoPolicy['0']?.vehicle.chassis);
          const policyDueDate = new Date(cascoPolicy['0']?.endDate);
          policyDueDate.setDate(policyDueDate.getUTCDate() + 1);
          if (this.offerWithSamePolicyChassis && vehicleStatus === 'used') {
            const cascoOfferStartDate = new Date(this.offerWithSamePolicyChassis.startCascoDate);
            const mtplOfferStartDate = new Date(this.offerWithSamePolicyChassis.startMtplDate);
            if (this.offerWithSamePolicyChassis.customer.sourceCustomer.id === cascoPolicy['0'].customer.sourceCustomer.id
              // Uncomment this to have the correct algorithm for renewal offers.
              && (this.offerWithSamePolicyChassis.offerForCasco && cascoOfferStartDate.getTime() === policyDueDate.getTime()) ||
              (this.offerWithSamePolicyChassis.offerForMtpl && mtplOfferStartDate.getTime() === policyDueDate.getTime())
            ) {
              const notification = {
                message: `Alege oferta de reînnoire ${this.offerWithSamePolicyChassis.id}`,
                offerId: this.offerWithSamePolicyChassis.id,
                policyMTPL: true,
                policyCASCO: true
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === this.offerWithSamePolicyChassis.id)
                && ((
                    this.offerWithSamePolicyChassis.offerForMtpl === true &&
                    cascoPolicy['0'].product.type.code === 'MTPL') ||
                  (this.offerWithSamePolicyChassis.offerForCasco === true &&
                    cascoPolicy['0'].product.type.code === 'CASCO'))) {
                this.notifications.push(notification);
                this.getRenewalProductsService.setRenewalData(this.offerWithSamePolicyChassis.id, cascoPolicy['0']);
              }
            } else {
              const byVehicleChassisOffer = allOffers.filter(offer => offer.vehicle.chassis === vehicleChassis);
              if (byVehicleChassisOffer['0']) {
                if (
                  (byVehicleChassisOffer['0'].offerForMtpl === true && byVehicleChassisOffer['0'].offerForCasco === true) &&
                  (!cascoPolicy['0'] && mtplPolicy['0'])
                ) {
                  if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                    const notification = {
                      message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                      offerId: byVehicleChassisOffer['0'].id,
                      policyMTPL: false,
                      policyCASCO: true
                    };
                    if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                      this.notifications.push(notification);
                    }
                  } else {
                    const notification = {
                      message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                      offerId: byVehicleChassisOffer['0'].id,
                      policyMTPL: false,
                      policyCASCO: true
                    };
                    if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                      this.notifications.push(notification);
                    }
                  }

                }
                if (
                  (byVehicleChassisOffer['0'].offerForMtpl === true && byVehicleChassisOffer['0'].offerForCasco === true) &&
                  (cascoPolicy['0'] && !mtplPolicy['0'])
                ) {
                  if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                    const notification = {
                      message: `Alege oferta RCA ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                      offerId: byVehicleChassisOffer['0'].id,
                      policyMTPL: true,
                      policyCASCO: false
                    };
                    if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                      this.notifications.push(notification);
                    }
                  } else {
                    const notification = {
                      message: `Alege oferta RCA ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                      offerId: byVehicleChassisOffer['0'].id,
                      policyMTPL: true,
                      policyCASCO: false
                    };
                    if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                      this.notifications.push(notification);
                    }
                  }
                }
              }
            }
          } else if (allOffers) {
            const byVehicleChassisOffer = allOffers.filter(offer => offer.vehicle.chassis === vehicleChassis);
            if (byVehicleChassisOffer['0']) {
              if (
                (byVehicleChassisOffer['0'].offerForMtpl === true && byVehicleChassisOffer['0'].offerForCasco === true) &&
                (!cascoPolicy['0'] && mtplPolicy['0'])
              ) {
                if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: false,
                    policyCASCO: true
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                } else {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: false,
                    policyCASCO: true
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                }
              }
              if (
                (byVehicleChassisOffer['0'].offerForMtpl === true && byVehicleChassisOffer['0'].offerForCasco === true) &&
                (cascoPolicy['0'] && !mtplPolicy['0'])
              ) {
                if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                  const notification = {
                    message: `Alege oferta RCA ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: true,
                    policyCASCO: false
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                } else {
                  const notification = {
                    message: `Alege oferta RCA ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: true,
                    policyCASCO: false
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                }

              }
              if (
                (byVehicleChassisOffer['0'].offerForMtpl === false && byVehicleChassisOffer['0'].offerForCasco === true) &&
                (!cascoPolicy['0'] && mtplPolicy['0'])
              ) {
                if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: false,
                    policyCASCO: true
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                } else {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: false,
                    policyCASCO: true
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                }
              }
              if (
                (byVehicleChassisOffer['0'].offerForMtpl === true && byVehicleChassisOffer['0'].offerForCasco === false) &&
                (cascoPolicy['0'] && !mtplPolicy['0'])
              ) {
                if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].id} ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: true,
                    policyCASCO: false
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                } else {
                  const notification = {
                    message: `Alege oferta CASCO ${byVehicleChassisOffer['0'].vehicle.model.name} ${vehicleRegistrationNumber}`,
                    offerId: byVehicleChassisOffer['0'].id,
                    policyMTPL: true,
                    policyCASCO: false
                  };
                  if (!this.notifications.find(notificationList => notificationList.offerId === byVehicleChassisOffer['0'].id)) {
                    this.notifications.push(notification);
                  }
                }
              }
            }
          }
        }
      });
    }
  }

  private getOfferNotifications(vehicleRegistrationNumber: string, vehicleChassis) {
    this.store.select(selectOfferListData).subscribe(allOffers => {
      allOffers.forEach(offerNew => {
        if (offerNew.vehicle.chassis === vehicleChassis) {
          if (
            offerNew.offerForMtpl === true && offerNew.offerForCasco === true
          ) {
            if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
              const notification = {
                message: `Alege ofertele CASCO si RCA ${offerNew.id} ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: true,
                policyCASCO: true
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            } else {
              const notification = {
                message: `Alege ofertele CASCO si RCA ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: true,
                policyCASCO: true
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            }

          } else if (
            (offerNew.offerForMtpl === false && offerNew.offerForCasco === true)
          ) {
            if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
              const notification = {
                message: `Alege oferta CASCO ${offerNew.id} ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: false,
                policyCASCO: true
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            } else {
              const notification = {
                message: `Alege oferta CASCO ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: false,
                policyCASCO: true
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            }

          } else if (
            (offerNew.offerForMtpl === true && offerNew.offerForCasco === false)
          ) {
            if (this.env === 'dev' || this.env === 'uat' || this.env === '') {
              const notification = {
                message: `Alege oferta RCA ${offerNew.id} ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: true,
                policyCASCO: false
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            } else {
              const notification = {
                message: `Alege oferta RCA ${offerNew.vehicle.model.name} ${vehicleRegistrationNumber}`,
                offerId: offerNew.id,
                policyMTPL: true,
                policyCASCO: false
              };
              if (!this.notifications.find(notificationList => notificationList.offerId === offerNew.id)) {
                this.notifications.push(notification);
              }
            }
          }
        }
      });
    });
  }

}
