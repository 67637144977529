import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserCanCommunicateRegister, UserNotificationSubscriptions} from '../../../../../models/user.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-marketing-agreements-modal',
  templateUrl: './marketing-agreements-modal.component.html',
  styleUrls: ['./marketing-agreements-modal.component.scss']
})
export class MarketingAgreementsModalComponent implements OnInit {
  userSubscriptions: UserCanCommunicateRegister;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  backToMarketingAgreementsModal;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    if (!this.backToMarketingAgreementsModal) {
      this.userSubscriptions = new UserCanCommunicateRegister();
    } else {
      this.userSubscriptions = new UserCanCommunicateRegister();
      this.userSubscriptions.canCommunicateByEmail = this.backToMarketingAgreementsModal.canCommunicateByEmail;
      this.userSubscriptions.canCommunicateByPhone = this.backToMarketingAgreementsModal.canCommunicateByPhone;
      this.userSubscriptions.canCommunicateByPostalService = this.backToMarketingAgreementsModal.canCommunicateByPostalService;
      this.userSubscriptions.canCommunicateBySms = this.backToMarketingAgreementsModal.canCommunicateBySms;
    }
  }

  save(): void {
    if (!this.isAllOptionMarketingCheckboxStatusFalse) {
      this.passEntry.emit(this.userSubscriptions);
    } else {
      this.passEntry.emit(null);
    }
    this.activeModal.close();
  }

  get isAllOptionMarketingCheckboxStatusFalse() {
    return !this.userSubscriptions.canCommunicateByEmail &&
      !this.userSubscriptions.canCommunicateByPhone &&
      !this.userSubscriptions.canCommunicateBySms &&
      !this.userSubscriptions.canCommunicateByPostalService;
  }

  closeModal() {
    this.activeModal.close();
  }

}
