import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateNotificationsService {

  constructor() { }

  private subjectNotifications = new Subject<any>();

  sendUpdate(update: any) {
    this.subjectNotifications.next(update);
  }

  getUpdate(): Observable<any> {
    return this.subjectNotifications.asObservable();
  }
}
