import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {AppStorageService} from './app-storage.service';
import {AbstractControl} from '@angular/forms';

@Injectable()
export class FunctionsService {
  constructor(private localStorageService: AppStorageService, private apiService: ApiService) {
  }
}
