import { Injectable } from '@angular/core';
import {UserPreviewCarDetails} from '../models/user-preview-car-details.model';
import {UserPreviewData} from '../models/user-preview-data.model';

@Injectable({
  providedIn: 'root'
})
export class OfferPreviewDataService {
  private readonly userPreviewConfig: UserPreviewData;
  private readonly carPreviewConfig: UserPreviewCarDetails;
  constructor() {
    this.userPreviewConfig = {
      firstName: '',
      lastName: '',
      city: '',
      county: ''
    };
    this.carPreviewConfig = {
      make: '',
      model: '',
      use: '',
      chassis: '',
      registrationNumber: '',
      status: ''
    };
  }
  public getConfigUserPreview() {
    return this.userPreviewConfig;
  }
  public getConfigCarPreview() {
    return this.carPreviewConfig;
  }
}
