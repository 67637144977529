import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {
  mergeMap,
  catchError, tap
} from 'rxjs/operators';
import {GetDataService} from '../../shared/services/get-data.service';
import {
  fetchOfferData,
  fetchOfferDataCASCO,
  fetchOfferDataCustomer,
  fetchOfferDataMTPL,
  fetchOfferDataVehicle, fetchOfferListData,
  OfferActionTypes
} from '../actions/offer.actions';

@Injectable()
export class OfferEffects {
  fetchOffer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferData),
        mergeMap(_ => [
          // this.getDataService.getOfferWithMTPLAndCasco(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  fetchOfferList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferListData),
        mergeMap(_ => [
          this.getDataService.getOffers(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  fetchOfferCASCO$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferDataCASCO),
        mergeMap(_ => [
          this.getDataService.getAllProductsCASCO(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  fetchOfferMTPL$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferDataMTPL),
        mergeMap(_ => [
          this.getDataService.getAllProductsMTPL(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  fetchOfferCustomerData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferDataCustomer),
        mergeMap(_ => [
          this.getDataService.getCustomerData(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  fetchOfferVehicleData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchOfferDataVehicle),
        mergeMap(_ => [
          this.getDataService.getVehicleData(),
          // this.getDataService.getVehicleDataByChassis(), //TODO: save in new object "sourceVehicleData"
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  constructor(
    private actions$: Actions,
    private getDataService: GetDataService,
  ) {
  }
}
