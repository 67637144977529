import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {ApiService} from '../../../services/api.service';
import {WarningService} from '../../../core/services/warning.service';
import {Router} from '@angular/router';
import { RouteEventsService } from '../../services/route-events.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-marsh-report-data',
  templateUrl: './report-wrong-data.component.html',
  styleUrls: ['./report-wrong-data.component.scss']
})
export class ReportWrongDataComponent implements OnInit {
  faClose = faWindowClose;
  faExc = faExclamationCircle;
  reportData;
  noTextInput = false;
  textFromTextarea: string;
  constructor(
    private modal: NgbModal,
    private toastrService: ToastrService,
    private warningService: WarningService,
    private router: Router,
    private apiService: ApiService,
    private routeEventsService: RouteEventsService
  ) { }

  ngOnInit() {
    // dau click din modal si pagina apare in background, iar modalul nu se inchide.
    this.closeModal();
    this.warningService.fromWhatWarningComponent.subscribe(dataToReport => {
      if (dataToReport.length > 0) {
        this.reportData = dataToReport[0];
      } else {
        this.router.navigate(['/home']);
      }
    });
  }

  reportWrongData() {
    if (this.reportData.from === 'wrongVehicleData') {
      if (this.textFromTextarea !== undefined && this.textFromTextarea.length > 0) {
        this.noTextInput = false;
        const reportObj =
          {
            objectId: this.reportData.dataOfComponent.carDetails.id,
            objectType: this.reportData.from,
            text: this.textFromTextarea,
            objectEnvironment: environment.app
          };
        this.apiService.reportIncorrectObjectData(reportObj).subscribe( rez => {
          this.toastrService.success('Mesajul tău a fost transmis către echipa Marsh care urmează să te contacteze în cel mai scurt timp. Mulțumim pentru sesizare. Îți dorim o zi frumoasă!', '', {
            timeOut: 5000,
          });
          sessionStorage.setItem('showToast', 'false');
          this.closeModal();
          this.router.navigate(['/home']);
        });
      } else {
        this.noTextInput = true;
      }

    } else {
      if (this.textFromTextarea !== undefined && this.textFromTextarea.length > 0) {
        this.noTextInput = false;
        const reportObj =
          {
            objectId: this.reportData.dataOfComponent.id,
            objectType: this.reportData.from,
            text: this.textFromTextarea,
            objectEnvironment: environment.app
          };
        this.apiService.reportIncorrectObjectData(reportObj).subscribe( rez => {
          this.toastrService.success('Mesajul tău a fost transmis către echipa Marsh care urmează să te contacteze în cel mai scurt timp. Mulțumim pentru sesizare. Îți dorim o zi frumoasă!', '', {
            timeOut: 5000,
          });
          sessionStorage.setItem('showToast', 'false');
          this.closeModal();
          this.router.navigate(['/home']);
        });
      } else {
        this.noTextInput = true;
      }
    }
  }

  goBack() {
    const previousURL = this.routeEventsService.previousRoutePath.value;
    if (previousURL.includes('offer')) {
      this.router.navigate(['/offer']);
    } else if (this.reportData.from === 'wrongCustomerData'){
      this.router.navigate(['/user-details']);
    }  else if (this.reportData.from === 'wrongVehicleData'){
      this.router.navigate(['/car-select']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  closeModal(){
    this.modal.dismissAll();
  }
}
