import { createAction, props } from '@ngrx/store';

export enum CarsActionTypes {
  FETCH_CARS_DATA = '[Cars Data] Fetch cars data',
  FETCH_CARS_DATA_SUCCESS = '[Cars Data] Fetch cars data successfully'
}

export const fetchCarsData = createAction(
  CarsActionTypes.FETCH_CARS_DATA
);

export const fetchCarsDataSuccess = createAction(
  CarsActionTypes.FETCH_CARS_DATA_SUCCESS,
  props<{myCars: any}>()
);
