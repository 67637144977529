import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import { ApigeeService } from './apigee.service';


@Injectable({
  providedIn: 'root'
})

export class UserSubscriptionsService {
  baseUri = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getSubscriptions(){
    const url = `${this.baseUri}/online/subscriptions`;
    return this.http.get(
      url, {headers: {'Content-Type': 'application/json'}});
  }

  unsubscribe(data, token){
    const url = `${this.baseUri}/online/unsubscribe`;
    return this.http.post(
      url, data, {headers: {Recaptcha: token, 'Content-Type': 'application/json'}});
  }

  updateSubscriptions(data){
    const url = `${this.baseUri}/online/update-subscriptions`;
    return this.http.post(
      url, data, {headers: {'Content-Type': 'application/json'}});
  }

  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
