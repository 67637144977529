import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SessionDataGuard implements CanActivate {

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private ngbModal: NgbModal,
    private localStorageService: LocalStorageService,
  ) {
  }

  canActivate(): boolean {
    const oktaTokenStorage = window.sessionStorage.getItem('okta-token-storage');
    const apigeeTokenStorage = window.sessionStorage.getItem('apigee');
    const userId = window.sessionStorage.getItem('customerId');
    if (oktaTokenStorage && apigeeTokenStorage && userId) {
      this.ngbModal.dismissAll();
      return true;
    } else {
      this.router.navigate([''], {replaceUrl: true});
      return false;
    }
  }
}
