import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromStore from '../index';
import {GetDataService} from '../../shared/services/get-data.service';

@Injectable()
export class SelectedOfferEffects {

  // fetchSelectedOfferCASCO$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fetchSelectedOfferCASCO),
  //       mergeMap(_ => [
  //         this.getDataService.setSelectedOfferCASCO(),
  //         catchError((err) => EMPTY)
  //       ])
  //     );
  //   }, {dispatch: false});
  //
  // fetchSelectedOfferMTPL$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fetchSelectedOfferMTPL),
  //       mergeMap(_ => [
  //         this.getDataService.setSelectedOfferMTPL(),
  //         catchError((err) => EMPTY)
  //       ])
  //     );
  //   }, {dispatch: false});

  constructor(
    private store: Store<fromStore.AppState>,
    private actions$: Actions,
    private getDataService: GetDataService,
  ) {
  }
}
