import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {AppStorageService} from './app-storage.service';
import {SessionDataService} from './session-data.service';

@Injectable()
export class FieldsService {

  private fields;

  constructor(
    private http: HttpClient,
    private localStorageService: AppStorageService,
    private sessionDataService: SessionDataService,
  ) {
    // let token, sessionData;
    // token = this.localStorageService.getOnSessionStorage('okta-token');
  }

  private static generateGridColumnRecord(
    field = 'dummy', title = 'Dummy', width = 100, dataType = 'string',
    resizable = true, colFormat = '', template = '', hidden = false, fieldType = ''
  ) {
    return {
      field,
      title,
      width,
      dataType,
      resizable,
      colFormat,
      template,
      hidden,
      fieldType
    };
  }

  getGridColumns(screen) {
    const columns = [];
    this.sessionDataService.getSessionData()
      .then(sessionData => {
        if (sessionData) {
          this.fields = sessionData['fields'];

          if (this.fields && this.fields[screen]) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.fields[screen].length; i++) {
              if (!this.fields[screen][i]['gcih']) {
                columns.push(
                  FieldsService.generateGridColumnRecord(
                    this.fields[screen][i]['gf'],
                    this.fields[screen][i]['gcti'],
                    this.fields[screen][i]['gcw'],
                    this.fields[screen][i]['gdt'] === 'string' ? 'text' : this.fields[screen][i]['gdt'],
                    true,
                    (!this.fields[screen][i]['gcf'] || this.fields[screen][i]['gcf'] === 'NULL') ? '' : this.fields[screen][i]['gcf'],
                    (!this.fields[screen][i]['gcte'] || this.fields[screen][i]['gcte'] === 'NULL') ? '' : this.fields[screen][i]['gcte'],
                    this.fields[screen][i]['gcih'],
                    this.fields[screen][i]['ft']
                  )
                );
              }
            }
          }
        }
      });
    return columns;
  }

  public getFormFields(screen) {
    const result = [];

    if (this.fields && this.fields[screen]) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.fields[screen].length; i++) {
        // && this.fields[screen][i]['name'] == "name"
        if (!this.fields[screen][i]['fih'] && this.fields[screen][i]['n'] !== 'id') {
          result.push({
            key: this.fields[screen][i]['n'],
            type: this.fields[screen][i]['ft'] === 'number' ? 'input' : this.fields[screen][i]['ft'],
            templateOptions: {
              label: this.fields[screen][i]['fl'],
              path: 'tools',
              type: this.fields[screen][i]['ft'] === 'number' ? 'number' : null,
              placeholder: this.fields[screen][i]['fph'],
              required: this.fields[screen][i]['fir'],
            },
            fieldParent: (!this.fields[screen][i]['fp'] || this.fields[screen][i]['fp'] == null) ? null : this.fields[screen][i]['fp'],
            kendoDropDownSource: (!this.fields[screen][i]['fds'] || this.fields[screen][i]['fds'] === 'NULL') ? '' : this.fields[screen][i]['fds']
          });
        }
      }
    }

    return result;
  }
}
