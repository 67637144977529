import { Component, NgZone, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AppStorageService } from '../services/app-storage.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { TranslationService } from '../services/translation.service';
// import { NotificationService } from '@progress/kendo-angular-notification';
import { ApigeeService } from '../services/apigee.service';
import { SessionDataService } from '../services/session-data.service';
import { CustomCallbackNotify } from './customCallback.notify';

import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import { LoginSuccess } from '../store/actions/auth.actions';
import {
  fetchOfferData,
  fetchOfferDataCASCO,
  fetchOfferDataCustomer,
  fetchOfferDataMTPL,
  fetchOfferDataVehicle
} from '../store/actions/offer.actions';

@Component({
  template: `
    <div class="k-i-loading">
    </div>
  `,
  styles: [`
    .k-i-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 100px;
      color: #252830;
    }
  `]
})
export class CustomCallbackComponent implements OnInit {
  baseUri = environment.apiUrl;

  constructor(
    private oktaAuth: OktaAuthService,
    private router: Router,
    private apiService: ApiService,
    private appStorageService: AppStorageService,
    private translationService: TranslationService,
    // private notificationService: NotificationService,
    private sessionDataService: SessionDataService,
    private apigeeService: ApigeeService,
    private customCallbackNotify: CustomCallbackNotify,
    private store: Store<fromStore.AppState>
  ) {
  }

  async ngOnInit() {

    // const isAuth = await this.oktaAuth.isAuthenticated()
    // console.log('isAuth: ', isAuth);
    // let accessToken;
    // if (isAuth) {
    //   accessToken = this.oktaAuth.getAccessToken();
    //   console.log('isAuth=true::accessToken: ', accessToken);
    // }

    return await this.oktaAuth.handleAuthentication()
      .then(async (a) => {
        const oktaConfig = this.oktaAuth.getOktaConfig();

        const oktaToken = await this.oktaAuth.getAccessToken();
        // console.log('oktaAuth.handleAuthentication::getAccessToken: ', oktaToken);
        this.store.dispatch(LoginSuccess({ payload: oktaToken }));

        const tokenManager = this.oktaAuth.getTokenManager();
        const tokenManagerValue = await tokenManager.get('accessToken');
        this.store.dispatch({ type: 'startSpinner' });
        this.store.dispatch(fetchOfferData());
        this.store.dispatch(fetchOfferDataCASCO());
        this.store.dispatch(fetchOfferDataMTPL());
        this.store.dispatch(fetchOfferDataCustomer());
        this.store.dispatch(fetchOfferDataVehicle());
        await this.router.navigate(['/home'], {replaceUrl: true});

        // await this.apigeeService.getToken('callback');
        // this.sessionDataService.getSessionData('callback')
        //   .then( (resp) => {
        //     console.log('1. this.customCallbackNotify.getSessionDataReady',resp);
        //     this.customCallbackNotify.getSessionDataReady.next(true);
        //   } );
        // this.translationService.getTranslatedData()
        //   .then( () => {
        //     console.log('2. this.customCallbackNotify.getTranslatedDataReady');
        //     this.customCallbackNotify.getTranslatedDataReady.next(true);
        //   } );
      })
      .catch(e => {
        // this.notificationService.show({
        //   content: e.message,
        //   hideAfter: 30000,
        //   position: { horizontal: 'center', vertical: 'bottom' },
        //   animation: { type: 'fade', duration: 400 },
        //   type: { style: 'error', icon: true }
        // });
        // this.oktaAuth.loginRedirect();
      });
  }
}
