import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import {ChangeEmailModel} from '../../../../models/change-email.model';
import {Router} from '@angular/router';
import {AppState, selectUserDetails} from '../../../../store';
import {Store} from '@ngrx/store';
import {
  UserConfirmEmailModel,
  UserPreValidateModel, UserRegisterPasswordModel,
  UserSendPinFromEmailModel
} from '../../../../models/user-register.model';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ApiService} from '../../../../services/api.service';
import {CustomValidators} from '../register/validation';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  warningTypesEnums = WarningTypeEnum;
  changeEmail: ChangeEmailModel;
  ChangeEmailForm: FormGroup;
  pinFromEmail = false;
  changeEmailForm = true;
  setPass = false;
  userName: string;
  firstName: string;
  lastName: string;
  public ConfirmEmailForm: FormGroup;
  public ActivateCodeForm: FormGroup;
  public PreValidateForm: FormGroup;
  public SetPasswordForm: FormGroup;
  preValidateNotFound: boolean;
  errors;
  errorBackend;
  pinFromEmailError = false;
  confirmEmailError = false;
  responsePreValidate;
  customerId: number;
  offerId: number;
  userEmail;
  userPreValidate: UserPreValidateModel;
  userConfirmEmail: UserConfirmEmailModel;
  userSendPinFromEmail: UserSendPinFromEmailModel;
  userRegister: UserRegisterPasswordModel;
  submitted = false;
  firstNameValidation: boolean;
  lastNameValidation: boolean;
  userNameValidation: boolean;

  constructor(
    private form: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private recaptchaV3Service: ReCaptchaV3Service,
    public apiService: ApiService,
    public localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.customerId = JSON.parse(window.sessionStorage.getItem('customerId'));
    this.userEmail = this.currentEmailAddress;
    this.PreValidateForm = this.form.group({
      phoneNr: ['', [
        Validators.required,
        Validators.pattern(`^(\\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\\s|\\.|\\-)?([0-9]{3}(\\s|\\.|\\-|)){2}$`)
      ]],
      offerNr: ['', [Validators.required]],
    });
    this.ConfirmEmailForm = this.form.group({
      emailRegister: ['', [Validators.required, Validators.email]],
      termsRegister: [false, Validators.requiredTrue],
      gdprRegister: [false, Validators.requiredTrue],
    });
    this.ActivateCodeForm = this.form.group({
      activationPin: ['', [Validators.required]],
    });
    this.SetPasswordForm = this.form.group({
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
    this.ChangeEmailForm = this.form.group({
      newEmail: ['', [Validators.required, Validators.email]],
    });
  }

  get currentEmailAddress() {
    return window.sessionStorage.getItem('customerEmail');
  }

  submitChangeEmailForm() {
    this.recaptchaV3Service.execute('confirmEmailSubmit').subscribe(
      (token) => {
        this.userConfirmEmail = new UserConfirmEmailModel();
        this.userConfirmEmail.offerToken = null;
        this.userConfirmEmail.email = this.ChangeEmailForm.controls.newEmail.value;
        this.userConfirmEmail.offerNumber = 39;
        this.userConfirmEmail.terms = true;
        this.userConfirmEmail.gdpr = true;
        this.userConfirmEmail.objectEnvironment = environment.app ?? '';
        this.apiService.validateUserEmail(this.userConfirmEmail, token).then(result => {
          const validateUserResult: any = result;
          if (validateUserResult.error === false) {
            this.setPass = false;
            this.changeEmailForm = false;
            this.pinFromEmail = true;
            this.userName = this.ChangeEmailForm.controls.newEmail.value;
          } else {
            this.confirmEmailError = true;
          }
        });

      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

  sendActivationPin() {
    this.recaptchaV3Service.execute('sendPinSubmit').subscribe(
      (token) => {
        this.userSendPinFromEmail = new UserSendPinFromEmailModel();
        this.userSendPinFromEmail.offerNumber = 39;
        this.userSendPinFromEmail.pin = this.ActivateCodeForm.controls.activationPin.value;

        this.apiService.validatePinEmail(this.userSendPinFromEmail, token).then(result => {
          const pinRequestRez: any = result;
          if (pinRequestRez.error === false) {
            this.setPass = true;
            this.changeEmailForm = false;
            this.pinFromEmail = false;
          } else {
            this.pinFromEmailError = true;
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

  public setPassForNewAccount(): void {
    this.recaptchaV3Service.execute('sendPinSubmit').subscribe(
      (token) => {
        this.userRegister = new UserRegisterPasswordModel();
        this.userRegister.email = this.ChangeEmailForm.controls.newEmail.value;
        this.userRegister.gdpr = true;
        this.userRegister.terms = true;
        this.userRegister.password = this.SetPasswordForm.controls.password.value;
        this.userRegister.customerId = this.customerId;

        this.apiService.registerUser(this.userRegister, token).then(result => {
          const setPassRequestRez: any = result;
          if (setPassRequestRez.error === false) {
            this.router.navigate(['/login']);
            this.toastrService.info('Adresa de e-mail a fost modificată cu succes. Pe viitor te rugăm să folosești această nouă adresă pentru a putea accesa contul tău.', '', {
              timeOut: 5000,
            });
          } else {
            this.errorBackend = setPassRequestRez.message;
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

  validatePass(input) {
    const valueOfInput = input.target.value;
    this.userNameValidation = !!valueOfInput.includes(this.userName);
    this.lastNameValidation = !!valueOfInput.includes(this.firstName);
    this.firstNameValidation = !!valueOfInput.includes(this.lastName);
  }

  get activateCodeFormControls() {
    return this.ActivateCodeForm.controls;
  }

  get setPassFormControls() {
    return this.SetPasswordForm.controls;
  }


}
// 3737193900
