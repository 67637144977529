import {MonthMapping} from '../../../models/mapping.model';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserCarDetailsService} from '../../../shared/services/user-car-details.service';
import {UserCarDetailsConfig} from '../../../models/user-car-details.model';
import {WarningTypeEnum} from '../../../models/mapping.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DropDownDataService} from 'src/app/services/dropDown.data.service';
import {Store} from '@ngrx/store';
import {AppState, selectOfferDataVehicle, selectOfferListData} from '../../../store';
import {ActivatedRoute} from '@angular/router';
import {MyCarsService} from 'src/app/shared/services/my-cars.service';
import {RouteEventsService} from 'src/app/shared/services/route-events.service';
import {Location} from '@angular/common';
import {CarDataSaveModalComponent} from '../../../shared/components/car-data-save-modal/car-data-save-modal.component';
import {ToastrService} from 'ngx-toastr';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-car-details',
  templateUrl: './user-car-details.component.html',
  styleUrls: ['./user-car-details.component.scss'],
})
export class UserCarDetailsComponent implements OnInit, OnDestroy {
  @Input() fromCarDetail = false;
  @Input() vehicleItem;
  @Input() modal: true;
  warningTypeEnum: any;
  carDetailsForm!: FormGroup;
  car;
  vehicle: any = {registrationNumber: ''};
  userCarDetails: UserCarDetailsConfig;
  selectedVehicleUseMode: any;
  getAllPoliciesByCustomerIdSub: Subscription;
  originalSelectedVehicleUseMode: any;
  originalSelectedRegistrationNr: any;
  oldVehicleUseModeId: number;
  activePolicies: boolean;
  vehicleStatus = [];
  vehicleUse = [];
  vehicleCategory = [];
  vehicleFuel = [];
  checkPoliciesError: string;
  offer: any;
  vehicleOffer: any;
  vehiclePolicies: any;
  loadingVehicleData: boolean;
  vehicleInit: any;
  policyType: string;
  activeOffers = false;
  activeOffersForVehicleChassis = false;
  canShowUserDataModificationWarning = false;

  constructor(private myCarsService: MyCarsService,
              private modalService: NgbModal,
              private toastrService: ToastrService,
              private store: Store<AppState>,
              private myPoliciesService: MyPoliciesService,
              public dataSource: DropDownDataService,
              private formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              private route: ActivatedRoute,
              private userCarDetailsService: UserCarDetailsService,
              private routeEventsService: RouteEventsService,
              private location: Location) {
    this.warningTypeEnum = WarningTypeEnum;
    this.userCarDetails = this.userCarDetailsService.getConfig();
  }

  ngOnDestroy(): void {
    if (this.getAllPoliciesByCustomerIdSub) {
      this.getAllPoliciesByCustomerIdSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.loadingVehicleData = true;
    this.route
      .queryParams
      .subscribe(params => {
        if (params.type) {
          this.policyType = params.type;
        }
      });
    if (this.routeEventsService.previousRoutePath.value.includes('offer') || this.location.path().includes('offer')) {
      this.canShowUserDataModificationWarning = true;
    }
    if (this.fromCarDetail === true) {
      this.vehicle = this.vehicleItem;
      this.vehicleInit = this.vehicleItem;
      this.vehicleInit.vehicleUse.name = this.translatedVehicleUseReverse(this.vehicleInit.vehicleUse.name);
      this.vehicleInit.vehicleUse.registrationNumber = this.vehicleItem.registrationNumber;
      this.originalSelectedRegistrationNr = this.vehicleItem.registrationNumber;
    } else {
      this.store.select(selectOfferDataVehicle).subscribe(
        dataVehicle => {
          this.vehicle = dataVehicle;
          this.vehicleInit = dataVehicle;
          this.vehicleInit.vehicleUse.name = this.translatedVehicleUseReverse(dataVehicle.vehicleUse.name);
          this.vehicleInit.vehicleUse.registrationNumber = dataVehicle.registrationNumber;
          this.originalSelectedRegistrationNr = dataVehicle.registrationNumber;
        }
      );
    }

    this.getIfThereAreActiveOffers();

    this.selectedVehicleUseMode = this.vehicle.vehicleUse.id;

    this.dataSource
      .fetchData('vehicleCategory', '', null, 50, null)
      .subscribe((data) => {
        this.vehicleCategory = data;
        this.userCarDetails.carDetails.categorie = this.getCategoryById(this.vehicle.model.category.id);
      });

    this.dataSource
      .fetchData('fuels', '', null, 50, null)
      .subscribe((data) => {
        this.vehicleFuel = data;
        this.userCarDetails.carDetails.combustibil = this.getFuelById(this.vehicle.fuel.id);
      });

    this.dataSource
      .fetchData('vehicleStatus', '', null, 50, null)
      .subscribe((data) => {
        this.vehicleStatus = data;
        this.userCarDetails.carDetails.statusVehicul = this.getStatusById(this.vehicle.status.id);
      });

    this.dataSource
      .fetchData('vehicleUse', '', null, 50, null)
      .subscribe((data) => {
        this.vehicleUse = data;
        this.originalSelectedVehicleUseMode = this.getUseById(this.vehicle.vehicleUse.id);
        this.userCarDetails.modDeUtilizare = this.getUseById(this.vehicle.vehicleUse.id);

      });

    this.carDetailsForm = this.formBuilder.group({
      useMode:
        [[this.userCarDetails.modDeUtilizare],
          Validators.compose([Validators.required])],
      inputLicencePlate:
        [[this.userCarDetails.nrInmatriculare],
          Validators.compose([Validators.required,
            Validators.pattern(
              '^[A-Z]{2}[0-9]{2}[A-Z]{3}$|^[A-Z]{1}[0-9]{2}[A-Z]{3}$|^[A-Z]{1}[0-9]{3}[A-Z]{3}$|^[A-Z]{2}[0-9]{3}[A-Z]{3}$|^""$')])],
    });

    this.getCarDetails();
    this.oldVehicleUseModeId = this.vehicle.vehicleUse.id;
  }

  changeModUtilizare(data) {
    const vehicleUse = {...this.vehicle.vehicleUse};
    vehicleUse.name = this.vehicleUse.filter(a => a.value === Number(data))[0].text;
    vehicleUse.id = Number(data);
    const vehicle = {...this.vehicle};
    vehicle.vehicleUse = vehicleUse;
    this.vehicle = vehicle;
  }

  getCategoryById(id: number) {
    if (this.vehicleCategory.filter(a => a.value === id)[0]) {
      return this.vehicleCategory.filter(a => a.value === id)[0].text;
    }
  }

  getFuelById(id: number) {
    if (this.vehicleFuel.filter(a => a.value === id)[0]) {
      return this.vehicleFuel.filter(a => a.value === id)[0].text;
    }
  }

  getStatusById(id: number) {
    if (this.vehicleStatus.filter(a => a.value === id)[0]) {
      return this.vehicleStatus.filter(a => a.value === id)[0].text;
    }
  }

  getUseById(id: number) {
    if (this.vehicleUse.filter(a => a.value === id)[0]) {
      return this.vehicleUse.filter(a => a.value === id)[0].text;
    }
  }

  getUseByName(name: string) {
    if (this.vehicleUse.filter(a => a.value === name)[0]) {
      return this.vehicleUse.filter(a => a.value === name)[0].text;
    }
  }

  getPurchaseDate(date: Date): string {
    return date.getUTCDate() + '-' + MonthMapping[(date.getUTCMonth())] + '-' + date.getUTCFullYear();
  }

  getIfThereAreActiveOffers() {
    const customerId = window.sessionStorage.getItem('customerId');
    this.getAllPoliciesByCustomerIdSub = this.myPoliciesService.getAllPoliciesByCustomerId(customerId).subscribe(allUnfilteredPolicies => {
      this.store.select(selectOfferListData).subscribe(allOffers => {
        if (allOffers) {
          if (allOffers.length !== undefined) {
            const hasVehicleOffers = allOffers.filter(a => a.vehicle.chassis === this.vehicle.chassis);
            this.vehicleOffer = hasVehicleOffers;
            this.activeOffersForVehicleChassis = hasVehicleOffers.length > 0;
          } else {
            this.activeOffersForVehicleChassis = false;
          }
        } else {
          this.activeOffersForVehicleChassis = false;
        }
      });
      this.vehiclePolicies =
        allUnfilteredPolicies.data.filter(a => a.vehicle.chassis === this.vehicle.chassis && a.status.code === 'ACTIVE');
      // console.log(this.vehiclePolicies, 'this.vehiclePolicies');
      // console.log(this.vehicleOffer, 'this.vehicleOffer');
      if (this.vehiclePolicies.length > 0 && this.vehicleOffer.length > 0) {
          const sourceOfferHasCASCO = this.vehicleOffer[0]?.offerForCasco;
          const sourceOfferHasMTPL = this.vehicleOffer[0]?.offerForMtpl;
          if (sourceOfferHasCASCO && sourceOfferHasMTPL) {
            this.activeOffers = this.vehiclePolicies.length < 2 && this.activeOffersForVehicleChassis;
            this.activePolicies = this.vehiclePolicies.length > 0;
            this.loadingVehicleData = false;
          } else if (!sourceOfferHasCASCO && sourceOfferHasMTPL) {
            this.activeOffers = !(this.vehiclePolicies.length === 1 && this.activeOffersForVehicleChassis);
            this.activePolicies = this.vehiclePolicies.length > 0;
            this.loadingVehicleData = false;
          } else if (sourceOfferHasCASCO && !sourceOfferHasMTPL) {
            this.activeOffers = !(this.vehiclePolicies.length === 1 && this.activeOffersForVehicleChassis);
            this.activePolicies = this.vehiclePolicies.length > 0;
            this.loadingVehicleData = false;
          }
      } else {
        this.activePolicies = this.vehiclePolicies.length > 0;
        this.activeOffers = this.activeOffersForVehicleChassis;
        this.loadingVehicleData = false;
      }
    });
  }

  save() {
    const selectedVehicleUseName = this.getUseById(this.vehicle.vehicleUse.id);
    const selectedVehicleRegistrationNr = this.vehicle.registrationNumber;
    if (this.activeOffers &&
      (
        selectedVehicleUseName !== this.originalSelectedVehicleUseMode &&
        selectedVehicleRegistrationNr === this.originalSelectedRegistrationNr
      )) {
      this.saveVehicle('changeUseName', {
        vehicleUse:
          {
            id: this.vehicle.vehicleUse.id,
            name: this.translatedVehicleUse(this.vehicle.vehicleUse.name)
          }
      });
      const modalRef = this.modalService.open(CarDataSaveModalComponent as Component, {
        size: 'lg', centered: true, backdrop: 'static'
      });
      modalRef.componentInstance.fromCarDetail = this.fromCarDetail;
    }
    if (this.activeOffers &&
      (
        selectedVehicleUseName === this.originalSelectedVehicleUseMode &&
        selectedVehicleRegistrationNr !== this.originalSelectedRegistrationNr
      )) {
      this.saveVehicle('changeRegistrationNr', selectedVehicleRegistrationNr);
      const modalRef = this.modalService.open(CarDataSaveModalComponent as Component, {
        size: 'lg', centered: true, backdrop: 'static'
      });
      modalRef.componentInstance.fromCarDetail = this.fromCarDetail;
    }
    if (this.activeOffers && (selectedVehicleRegistrationNr !== this.originalSelectedRegistrationNr) &&
      (selectedVehicleUseName !== this.originalSelectedVehicleUseMode)) {
      this.saveVehicle('changeUseAndRegistration', {
        registrationNumber: selectedVehicleRegistrationNr,
        vehicleUse:
          {
            id: this.vehicle.vehicleUse.id,
            name: this.translatedVehicleUse(this.vehicle.vehicleUse.name)
          }
      });
      const modalRef = this.modalService.open(CarDataSaveModalComponent as Component, {
        size: 'lg', centered: true, backdrop: 'static'
      });
      modalRef.componentInstance.fromCarDetail = this.fromCarDetail;
    }
    // !active offers
    if (!this.activeOffers && (
      selectedVehicleUseName !== this.originalSelectedVehicleUseMode &&
      selectedVehicleRegistrationNr === this.originalSelectedRegistrationNr
    )) {
      this.saveVehicle('changeUseName',
        {
          vehicleUse:
            {
              id: this.vehicle.vehicleUse.id,
              name: this.translatedVehicleUse(this.vehicle.vehicleUse.name)
            }
        });
    }
    if (!this.activeOffers && (
      selectedVehicleUseName === this.originalSelectedVehicleUseMode &&
      selectedVehicleRegistrationNr !== this.originalSelectedRegistrationNr
    )) {
      this.saveVehicle('changeRegistrationNr', selectedVehicleRegistrationNr);
    }
    if (!this.activeOffers && (selectedVehicleRegistrationNr !== this.originalSelectedRegistrationNr) &&
      (selectedVehicleUseName !== this.originalSelectedVehicleUseMode)) {
      this.saveVehicle('changeUseAndRegistration',
        {
          registrationNumber: selectedVehicleRegistrationNr,
          vehicleUse:
            {
              id: this.vehicle.vehicleUse.id,
              name: this.translatedVehicleUse(this.vehicle.vehicleUse.name)
            }
        });
    }
  }

  saveVehicle(type: string, body) {
    if (type === 'changeUseName' && this.activeOffers) {
      this.myCarsService.addUpdateVehicle(body, this.vehicle).subscribe(() => {
      });
    }
    if (type === 'changeRegistrationNr' && this.activeOffers) {
      const regNumber = {registrationNumber: body};
      this.myCarsService.addUpdateVehicle(regNumber, this.vehicle).subscribe(() => {
      });
    }
    if (type === 'changeUseAndRegistration' && this.activeOffers) {
      this.myCarsService.addUpdateVehicle(body, this.vehicle).subscribe(() => {
      });
    }
    // !active offers
    if (type === 'changeUseName' && !this.activeOffers) {
      this.myCarsService.addUpdateVehicle(body, this.vehicle).subscribe(() => {
      });
      this.toastrService.success('Îți mulțumim pentru actualizarea datelor. Modificările au fost salvate cu succes', '', {
        timeOut: 5000,
      });
    }
    if (type === 'changeRegistrationNr' && !this.activeOffers) {
      const regNumber = {registrationNumber: body};
      this.myCarsService.addUpdateVehicle(regNumber, this.vehicle).subscribe(() => {
      });
      this.toastrService.success('Îți mulțumim pentru actualizarea datelor. Modificările au fost salvate cu succes', '', {
        timeOut: 5000,
      });
    }
    if (type === 'changeUseAndRegistration' && !this.activeOffers) {
      this.myCarsService.addUpdateVehicle(body, this.vehicle).subscribe(() => {
      });
      this.toastrService.success('Îți mulțumim pentru actualizarea datelor. Modificările au fost salvate cu succes', '', {
        timeOut: 5000,
      });
    }
  }

  translatedVehicleUse(name: string
  ) {
    if (name === 'Inchirieri') {
      return 'car_rent';
    }
    if (name === 'Constructii/agricultura') {
      return 'construction_agriculture';
    }
    if (name === 'Distributie') {
      return 'distribution_company';
    }
    if (name === 'Scola de soferi') {
      return 'drivers_school';
    }
    if (name === 'Instituții de stat') {
      return 'state_units';
    }
    if (name === 'Taxi') {
      return 'taxi';
    }
    if (name === 'Leasing operational') {
      return 'operational_leasing';
    }
    if (name === 'Companie securitate') {
      return 'security_company';
    }
    if (name === 'Utilizare personala') {
      return 'others';
    }
    if (name === 'Institutii financiare') {
      return 'financial_institutions';
    }
  }

  translatedVehicleUseReverse(name: string
  ) {
    if (name === 'car_rent') {
      return 'Inchirieri';
    }
    if (name === 'construction_agriculture') {
      return 'Constructii/agricultura';
    }
    if (name === 'distribution_company') {
      return 'Distributie';
    }
    if (name === 'drivers_school') {
      return 'Scola de soferi';
    }
    if (name === 'state_units') {
      return 'Instituții de stat';
    }
    if (name === 'taxi') {
      return 'Taxi';
    }
    if (name === 'operational_leasing') {
      return 'Leasing operational';
    }
    if (name === 'security_company') {
      return 'Companie securitate';
    }
    if (name === 'others') {
      return 'Utilizare personala';
    }
    if (name === 'financial_institutions') {
      return 'Institutii financiare';
    }
  }

  get registrationNumberErrors() {
   if (this.originalSelectedRegistrationNr === null && this.vehicle.registrationNumber === null) {
      return false;
    } else if (this.vehicle.registrationNumber === '' && this.originalSelectedRegistrationNr === '') {
      return false;
    } else if (this.originalSelectedRegistrationNr === null && this.vehicle.registrationNumber !== null) {     
      return this.carDetailsForm.controls.inputLicencePlate.status === 'INVALID';
    }  else {     
      return this.carDetailsForm.controls.inputLicencePlate.status === 'INVALID';
    }
  }

  get checkErrors() {
    let disableSaveButton = false;
    const vehicleAfterChange = this.vehicle;
    const vehicleInit = this.vehicleInit;
    const newVehicleValues = [];
    Object.keys(vehicleInit).forEach(key => {
      Object.keys(vehicleAfterChange).forEach(key2 => {
        if (
          key === 'vehicleUse' && key2 === 'vehicleUse' &&
          vehicleInit[key].id !== vehicleAfterChange[key2].id
        ) {
          const name = key2;
          const value = vehicleAfterChange[key2];
          newVehicleValues.push({name, value});
        } else if (
          key2 === 'registrationNumber' && key === 'registrationNumber' &&
          vehicleAfterChange[key] !== this.originalSelectedRegistrationNr) {
          const name = key2;
          const value = vehicleAfterChange[key2];
          newVehicleValues.push({name, value});
        }
      });
    });

    if (newVehicleValues.length > 0) {
      disableSaveButton = newVehicleValues.length > 0;
    } else {
      disableSaveButton = false;
    }
    return disableSaveButton;
  }

  closeModal() {
    if (this.vehicle.registrationNumber !== this.originalSelectedRegistrationNr) {
      this.vehicle.registrationNumber = this.originalSelectedRegistrationNr;
    }
    this.myCarsService.getVehiclesList();
    this.activeModal.close();
  }

  getCarDetails() {
    // todo: use the model class, translate
    if (this.vehicle) {
      this.userCarDetails.carDetails.sasiu = this.vehicle.chassis;
      this.userCarDetails.carDetails.marca = this.vehicle.make.name;
      this.userCarDetails.carDetails.model = this.vehicle.model.name;
      this.userCarDetails.carDetails.moneda = this.vehicle.purchaseCurrency.name;
      this.userCarDetails.carDetails.valuareAchizitie =
        this.vehicle.purchaseValue;
      this.userCarDetails.carDetails.valuareAsigurata =
        this.vehicle.insuredValue;
      this.userCarDetails.carDetails.nrLocuri = this.vehicle.seats;
      this.userCarDetails.carDetails.putereKw = this.vehicle.engineKw;
      this.userCarDetails.carDetails.combustibil = this.vehicle.fuel.name;
      this.userCarDetails.carDetails.masaMaxima = this.vehicle.authorizedWeight;
      this.userCarDetails.nrInmatriculare = this.vehicle.registrationNumber;
      this.userCarDetails.kilometraj = this.vehicle.engineKw;
      this.userCarDetails.carDetails.putereKw = this.vehicle.engineKw;
      this.userCarDetails.carDetails.capacitateMotor = this.vehicle.engineCapacity.name;
      this.userCarDetails.carDetails.anConstructie = this.vehicle.buildYear;
      this.userCarDetails.carDetails.id = this.vehicle.id;
      this.userCarDetails.carDetails.dataAchizite = this.getPurchaseDate(new Date(this.vehicle.purchaseDate));
    }
  }

}
