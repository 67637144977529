import { Component, OnInit } from '@angular/core';
import {
  InsuranceConditionsModalComponent
} from '../../../../pages/offer/offer/insurance-conditions-modal/insurance-conditions-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {
  showMoreMessages: boolean;

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
  }

  openInsuranceConditionsModal(cascoProviderName){
    const modalRef = this.modal.open(InsuranceConditionsModalComponent as Component, {
      centered: true, size: 'lg'
    });
    modalRef.componentInstance.showAccept = false;
    modalRef.componentInstance.notFromOffer = true;
    modalRef.componentInstance.inputInsurer = cascoProviderName;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  showMore() {
    this.showMoreMessages = !this.showMoreMessages;
  }
}
