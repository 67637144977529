import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserDetailsComponent} from '../pages/user/user-details/user-details.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {StepperComponent} from '../core/components/stepper/stepper.component';
import {NbCardModule, NbLayoutModule, NbStepperModule} from '@nebular/theme';
import {NotificationsComponent} from '../core/components/notifications/notifications.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WarningComponent} from '../core/components/warning/warning.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomCallbackComponent} from './customCallback.component';
import {CascoProductsFilterPipe} from './pipes/cascoProductsFilter.pipe';
import {ProgressModalComponent} from './components/progress-modal/progress-modal.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ConfirmationComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {PolicyPaymentsFilterPipe} from './pipes/policy-payments-filter.pipe';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { PersonalDataSaveModalComponent } from './components/personal-data-save-modal/personal-data-save-modal.component';
import { CarDataSaveModalComponent } from './components/car-data-save-modal/car-data-save-modal.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        NbStepperModule,
        NbCardModule,
        NbLayoutModule,
        NgbModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgCircleProgressModule,
        PdfViewerModule,
    ],
  exports: [
    UserDetailsComponent,
    StepperComponent,
    NotificationsComponent,
    WarningComponent,
    CustomCallbackComponent,
    CascoProductsFilterPipe,
    PolicyPaymentsFilterPipe,
    CustomCallbackComponent,
    ProgressModalComponent,
    ConfirmationComponent
  ],
  declarations: [
    UserDetailsComponent,
    StepperComponent,
    NotificationsComponent,
    WarningComponent,
    CustomCallbackComponent,
    CascoProductsFilterPipe,
    PolicyPaymentsFilterPipe,
    CustomCallbackComponent,
    ProgressModalComponent,
    ConfirmationComponent,
    PersonalDataSaveModalComponent,
    CarDataSaveModalComponent,
    CookieBannerComponent,
  ]
})
export class SharedModule {
}
