import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise, take } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class RouteEventsService {

    // save the previous route
  public previousRoutePath = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private location: Location
  ) {

    // ..initial prvious route will be the current path for now
    this.previousRoutePath.next(this.location.path());


    // on every route change take the two events of two routes changed(using pairwise)
    // and save the old one in a behavious subject to access it in another component
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise())
      .subscribe((event: any[]) => {
        this.previousRoutePath.next(event[0].urlAfterRedirects);
    });

  }

  trimURL(url: string): any{
    if(url.includes('?')){
      const retUrl = url.slice(0,url.indexOf('?'));
      const retParam = url.slice(url.indexOf('=')+1, url.length);
      return { url: retUrl, param: retParam }
    } else {
      return { url: url }
    }
  }
}
