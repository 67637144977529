export interface InsuranceTableConfig {
  [formType: string]: InsuranceTableFields;
}

export class InsuranceTableFields {
  cascoProviderName: string;
  goldOffers: GoldPriceOffers;
  silverOffers: SilverPriceOffers;
  package?: PackageInsuranceItems;
  termsAndCondText?: TermsAndConditions;
}

export interface PackageInsuranceItems {
  cascoProviderName: string;
  nameFirstPackage: string;
  savingsFirstSum: number;
  nameSecondPackage: string;
  savingsSecondSum: number;
  withPackSum12Months: number;
  withoutPackSum12Months: number;
  withPackSum6Months: number;
  withoutPackSum6Months: number;
}

export interface TermsAndConditions {
  conditionText: string;
}

export interface GoldPriceOffers {
  offerBracket: string;
  goldOfferOne: string;
  goldRateOne: string;
  goldOfferTwo: string;
  goldRateTwo: string;
}

export interface SilverPriceOffers {
  offerBracket: string;
  silverOfferOne: string;
  silverRateOne: string;
  silverOfferTwo: string;
  silverRateTwo: string;
}

export const POLICY_TYPES = {
  casco: 'casco',
  rca: 'rca'
};

export class RenewalOfferProduct {
  productId: number;
  offerId: number;
  packageId: number;
  installmentNumberId: number;
  renewalDueDate: Date;
  type: string;
  cascoType: string;
  cascoProviderName: string;
  constructor(){
    this.productId = 0;
    this.offerId = 0;
    this.packageId = 0;
    this.installmentNumberId = 0;
    this.renewalDueDate = new Date();
    this.type = "";
    this.cascoType = "";
    this.cascoProviderName = "";
  }
}

export class CascoData {
  installment1: InstallmentData[];
  installment4: InstallmentData[];
  installment12: InstallmentData[];
  constructor(){
    this.installment1 = [];
    this.installment4 = [];
    this.installment12 = [];
  }
}

export class InstallmentData {
  insurer: string;
  packageDiscount: number;
  packageDiscountSting?: string;
  cascoGold?: any;
  products: any[];
}
