import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Utils from "../../../shared/utils/utils";

@Component({
  selector: 'app-marsh-consecinte',
  templateUrl: './unpaid-rate-outcome.component.html',
  styleUrls: ['./unpaid-rate-outcome.component.scss']
})
export class UnpaidRateOutcomeComponent implements OnInit {
  faClose = faWindowClose;
  isMobile: boolean;
  constructor(private modal: NgbModal) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
  }

  closeModal(){
    this.modal.dismissAll();
  }
}
