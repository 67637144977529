import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PetitionsComponent } from './petitions/petitions.component';
import { PIDComponent } from './pid/pid.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import {RouterModule} from '@angular/router';
import { GdprComponent } from './gdpr/gdpr.component';
import { FrequentQuestionsComponent } from './frequent-questions/frequent-questions.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        FontAwesomeModule
    ],
  declarations: [AboutUsComponent, ContactComponent, TermsConditionsComponent, PIDComponent, PetitionsComponent, CookiePolicyComponent, FeedbackFormComponent, GdprComponent, FrequentQuestionsComponent],
  exports: [AboutUsComponent, ContactComponent, TermsConditionsComponent, PIDComponent, PetitionsComponent, CookiePolicyComponent, FeedbackFormComponent]
})
export class FooterLinksModule { }
