import { MonthMapping } from '../../../../models/mapping.model';
import {Component, Input, OnInit} from '@angular/core';
import {NgbCalendar, NgbModal, NgbActiveModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {faArrowLeft, faCircle, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import Utils from '../../../../shared/utils/utils';
import { OfferService } from 'src/app/services/offers.service';
import {ConfirmationComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ChangeBothOfferDatesComponent} from './change-both-offer-dates/change-both-offer-dates.component';

@Component({
  selector: 'app-marsh-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit{
  model: NgbDate;
  originalModel: NgbDate;
  today: NgbDate;
  maxDate: NgbDate;
  vehicleChassis: string;
  componentName: string;
  vehicleStatus: string;
  offer: any;
  fromPage;
  faArrowLeft = faArrowLeft;
  faCircle = faCircle;
  date: {year: number, month: number};
  faExclamationCircle = faExclamationCircle;
  isMobile: any; faClose = faWindowClose;
  checkPoliciesError = '';
  checkPoliciesHasError = false;

  constructor(private calendar: NgbCalendar,
              private modal: NgbModal,
              public activeModal: NgbActiveModal,
              private offerService: OfferService) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
    this.originalModel = this.model;
    this.selectToday();
    this.getMaxDate();
  }

  openProgress() {
    this.activeModal.close(this.model);
  }

  displayMonth(month: number){
    return MonthMapping[month];
  }

  get dataTouched() {
    if (this.model && (this.model != this.originalModel || this.checkPoliciesHasError === true)) {
      return false;
    } else {return true; }
  }

  selectToday() {
    const today = new Date();
    if (this.vehicleStatus === 'used'){
      today.setDate(today.getUTCDate() + 1);
    }
    this.today = new NgbDate(today.getFullYear(), today.getUTCMonth() + 1, today.getUTCDate());
  }

  getMaxDate() {
    let maxDaysToAdd = 30;
    if (this.vehicleStatus === 'used'){
      maxDaysToAdd = 29;
    }
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getUTCDate() + maxDaysToAdd);

    this.maxDate = new NgbDate(maxDate.getUTCFullYear(), maxDate.getUTCMonth() + 1, maxDate.getUTCDate());
  }

  onDateSelect(date: NgbDate){
    if (this.model !== date){
      this.model = date;
      this.checkPolicies();
    }
  }

  closeModal(){
    this.activeModal.close();
  }

  checkPolicies() {
    if (this.model){
      const startDate = (this.model?.year + '-' + this.model?.month + '-' + this.model?.day).toString();
      const checkPolicies = this.offerService.checkPolicies({
        startMtplDate: startDate,
        startCascoDate: startDate,
        chassis: this.vehicleChassis
      });

      if (checkPolicies[`error`]) {
        if (checkPolicies[`newStartMtplDate`]) {
          this.checkPoliciesError += 'Data de inceput a politei RCA va fi inlocuita cu ' + checkPolicies[`newStartMtplDate`] + '<br>';
        }

        if (checkPolicies[`newStartCascoDate`]) {
          this.checkPoliciesError +=
            'Data de inceput a politei CASCO va fi inlocuita cu ' + checkPolicies[`newStartCascoDate`] + '<br><br>';
        }

        this.checkPoliciesError += '\nPentru a aplica modificarile apasati \'Confirma\'.<br> Pentru continuare cu datele introduse apasati \'Înapoi\'. <br>';
        this.checkPoliciesHasError = true;

        const modalRef = this.modal.open(ConfirmationComponent as Component, {
          size: 'sm', centered: true, backdrop: 'static'
        });
        modalRef.componentInstance.text = this.checkPoliciesError;
        modalRef.result.then((result) => {
          if (result) {
            const offer = {...this.offer};
            if (this.offer[`offerForMtpl`] && checkPolicies[`newStartMtplDate`] !== null){
              offer[`startMtplDate`] = checkPolicies[`newStartMtplDate`];
            }
            else if (this.offer[`offerForCasco`] && checkPolicies[`newStartCascoDate`] !== null){
              offer[`startCascoDate`] = checkPolicies[`newStartCascoDate`];
            }
            this.offer = offer;
            const newDate = new Date(checkPolicies[`newStartCascoDate`]);
            this.model = new NgbDate(newDate.getUTCFullYear(), newDate.getUTCMonth() + 1, newDate.getUTCDate());
          }
          else {
            this.model = this.originalModel;
          }
        });
        this.checkPoliciesError = '';
      }
      else {
        this.checkPoliciesHasError = false;
      }
    }
  }
}
