import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marsh-user-agreements',
  templateUrl: './user-agreements.component.html',
  styleUrls: ['./user-agreements.component.css']
})

export class UserAgreementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
