import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayRateObserverService {
  private subject = new BehaviorSubject<boolean>({} as boolean);

  /**
   * Receive and store unpayed rate check
   * @param rateUnpayed
   * Returns true if there are unpayed rates, false if rates are payed
   */
  sendUnpayedRateCheck(rateUnpayed: boolean) {
    this.subject.next(rateUnpayed);
  }

  /**
   * Returning true if there are unpayed rates, false if rates are payed
   */
  getUnpayedRateCheck(): Observable<boolean> {
    return this.subject.asObservable();
  }

}
