import { createAction, props } from '@ngrx/store';

export enum OfferActionTypes {
  FETCH_OFFER_DATA_CASCO = '[Offer Data] Getting CASCO offer data',
  FETCH_OFFER_DATA_CASCO_SUCCESS = '[Offer Data] CASCO Loaded SUCCESS',
  FETCH_OFFER_DATA_CASCO_FAILURE = '[Offer Data] CASCO Loaded FAILURE',
  FETCH_OFFER_DATA_MTPL = '[Offer Data] Getting MTPL offer data',
  FETCH_OFFER_DATA_MTPL_SUCCESS = '[Offer Data] MTPL Loaded SUCCESS',
  FETCH_OFFER_DATA_MTPL_FAILURE = '[Offer Data] MTPL Loaded FAILURE',
  FETCH_OFFER_DATA = '[Offer Data] Getting offer data',
  FETCH_OFFER_DATA_SUCCESS = '[Offer Data] Offer data Loaded SUCCESS',
  FETCH_OFFER_LIST_DATA = '[Offers Data] Getting offers data',
  FETCH_OFFER_LIST_DATA_SUCCESS = '[Offers Data] Offers data Loaded SUCCESS',
  FETCH_OFFER_DATA_FAILURE = '[Offer Data] Offer data Loaded FAILURE',
  FETCH_OFFER_DATA_CUSTOMER = '[Offer Data] Getting offer customer data',
  FETCH_OFFER_DATA_CUSTOMER_ID = '[Offer Data] Getting offer customerId number',
  FETCH_OFFER_DATA_CUSTOMER_SUCCESS = '[Offer Data] Offer data customer Loaded SUCCESS',
  FETCH_OFFER_DATA_VEHICLE = '[Offer Data] Getting offer vehicle data',
  FETCH_OFFER_DATA_VEHICLE_ID = '[Offer Data] Getting offer vehicleId number',
  FETCH_OFFER_DATA_VEHICLE_SUCCESS = '[Offer Data] Offer data vehicle Loaded SUCCESS',
  FETCH_IS_OFFER_EXPIRED = '[Offer Data] Is offer expired',
  FETCH_OFFER_EXPIRED_WARNING_STATUS = '[Offer Data] Show Offer Expired Warning',

}

export const fetchOfferDataCASCO = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CASCO
);

export const fetchOfferDataCASCOSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CASCO_SUCCESS,
  props<{offerCASCO: any}>()
);

export const fetchOfferDataCASCOFailure = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CASCO_FAILURE
);

export const fetchOfferDataMTPL = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_MTPL
);

export const fetchOfferDataMTPLSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_MTPL_SUCCESS,
  props<{offerMTPL: any}>()
);

export const fetchOfferDataMTPLFailure = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_MTPL_FAILURE
);

export const fetchOfferData = createAction(
  OfferActionTypes.FETCH_OFFER_DATA
);

export const fetchOfferDataSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_SUCCESS,
  props<{offer: any}>()
);

export const fetchOfferExpiredWarningStatus = createAction(
  OfferActionTypes.FETCH_OFFER_EXPIRED_WARNING_STATUS,
  props<{warningStatus: any}>()
);

export const fetchOfferListData = createAction(
  OfferActionTypes.FETCH_OFFER_LIST_DATA
);

export const fetchOfferListDataSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_LIST_DATA_SUCCESS,
  props<{offerList: any[]}>()
);

export const fetchOfferDataFailure = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_FAILURE
);

export const fetchOfferDataCustomerId = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER_ID,
  props<{offerCustomerId: number}>()
);

export const fetchOfferDataCustomer = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER
);

export const fetchOfferDataCustomerSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER_SUCCESS,
  props<{offerCustomerData: any}>()
);

export const fetchOfferDataVehicleId = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_VEHICLE_ID,
  props<{offerVehicleId: any[]}>()
);

export const fetchOfferDataVehicle = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_VEHICLE
);

export const fetchOfferDataVehicleSuccess = createAction(
  OfferActionTypes.FETCH_OFFER_DATA_VEHICLE_SUCCESS,
  props<{offerVehicleData: any}>()
);

export const fetchOfferExpired = createAction(
  OfferActionTypes.FETCH_IS_OFFER_EXPIRED,
  props<{offerExpired: any}>()
);
