import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CustomCallbackNotify {
  public getSessionDataReady = new Subject();
  public getTranslatedDataReady = new Subject();

  constructor() {
  }

}
