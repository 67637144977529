import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-personal-data-save-modal',
  templateUrl: './personal-data-save-modal.component.html',
  styleUrls: ['./personal-data-save-modal.component.scss']
})
export class PersonalDataSaveModalComponent implements OnInit {

  animationDuration = environment.recalculateOfferTime;
  fromUserDetails;
  constructor(
    private ngbModal: NgbModal,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit() {
    if (!this.fromUserDetails) { // from offers true, accounts false
      setTimeout( () => {
        this.ngbModal.dismissAll();
        this.toastrService.success('Modificările au fost salvate cu succes și ofertele active au fost actualizate. Poți accesa ofertele din Meniul principal pentru a face selecția dorită și activa polița de asigurare.', '', {
          timeOut: 7000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } , this.animationDuration);
    } else {
      setTimeout( () => {
        this.ngbModal.dismissAll();
        this.toastrService.success('Modificările au fost salvate cu succes și ofertele au fost actualizate. Poți face selecția dorită și activa polița de asigurare.', '', {
          timeOut: 7000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } , this.animationDuration);
    }

  }

}
