import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DecontareComponent } from 'src/app/shared/components/decontare/decontare.component';
import {RCATableConfig} from '../../../../models/rca-table.model';
import Utils from '../../../../shared/utils/utils';
import {ProgressModalComponent} from '../../../../shared/components/progress-modal/progress-modal.component';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import {AppState, selectOfferDataMTPL, selectSelectedOfferCASCO, selectSelectedOfferMTPL} from '../../../../store';
import {Store} from '@ngrx/store';
import {setSelectedOfferCASCO, setSelectedOfferMTPL} from '../../../../store/actions/selectedOffer.actions';
import {
  toggleIfSelectedOfferCASCO,
  toggleIfSelectedOfferMTPL, toggleJustCASCO,
  toggleJustMTPL
} from '../../../../store/actions/offerToggle.actions';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import { RenewalOfferProduct } from 'src/app/models/casco-table.model';
import {take} from 'rxjs/operators';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';
import {Subscription} from 'rxjs';
import { OfferDetailsPopupComponent } from '../offer-details-popup/offer-details-popup.component';

@Component({
  selector: 'app-marsh-tables-offer-rca',
  templateUrl: './tables-offer-MTPL.component.html',
  styleUrls: ['./tables-offer-MTPL.component.css']
})
export class TablesOfferMTPLComponent implements OnInit {
  RCAList: RCATableConfig;
  MTPLData: any;
  insurers: string[] = [];
  @ViewChild('goHereMTPL') MyProp: ElementRef;
  isMobile: boolean;
  validForm: boolean;
  warningTypeEnum: any;
  activeTab: any = 2;
  packAllianz = false;
  packGroupama = false;
  packOmniasig = false;
  packType: string;
  selectedItem;
  sessionStorageMTPLObj: any = [];
  hideRow1 = false;
  hideAZT = [];
  hideRow2 = false;
  triggerCheckSelectedMTPLOfferFromAnotherCompSubscription: Subscription;
  hideGAM = [];
  hideRow3 = false;
  hideOMN = [];
  justRca: boolean;
  selectedCount: number;
  isMTPLSelectedError: boolean;
  @Input() renewalProduct: RenewalOfferProduct;
  selectedProduct: any;
  productToDeselect: any;
  constructor(
    private modal: NgbModal,
    private offerErrorsService: OfferErrorsService,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private store: Store<AppState>,
    public activeModal: NgbActiveModal) {
    this.validForm = null;
    this.isMobile = Utils.checkIfMobile();
    this.warningTypeEnum = WarningTypeEnum;
    this.triggerCheckSelectedMTPLOfferFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckSelectedMTPLOffer().subscribe(() => {
        this.isMTPLSelected();
      });
    this.selectedCount = 0;
  }

  ngOnInit() {
    this.checkForSelectedMTPLOffer();
    this.checkForSelectedCASCOfferInSessionStorage();
    this.store.select(selectSelectedOfferCASCO).subscribe(rez => {
      if (rez === null || (rez && rez[0][`packType`] === 'individual')) {
        this.packType = 'individual';
      }
      else {
        const selectedCascoProduct = rez[0];
        this.packType = rez[`packType`];
        if (selectedCascoProduct[`packType`] === 'package' && selectedCascoProduct[`cascoProviderName`] === 'Allianz') {
          this.packAllianz = true;
        }
        if (selectedCascoProduct[`packType`] === 'package' && selectedCascoProduct[`cascoProviderName`] === 'Groupama') {
          this.packGroupama = true;
        }
        if (selectedCascoProduct[`packType`] === 'package' && selectedCascoProduct[`cascoProviderName`] === 'Omniasig') {
          this.packOmniasig = true;
        }
      }
    });
    this.store.select(selectOfferDataMTPL).subscribe(
      MTPLData => {
        if (MTPLData && MTPLData[`MTPL`]){
          this.MTPLData = MTPLData;
          this.getProductForRenewal();
          setTimeout(() => {
            this.hideTable(this.MTPLData);
          }, );
          let insurerNamesSource: string;
          if (MTPLData[`MTPL`][1][`withSettlement`].length > 0) {
            insurerNamesSource = 'withSettlement';
          } else {
            insurerNamesSource = 'withoutSettlement';
          }
          MTPLData[`MTPL`][1][insurerNamesSource].forEach(mtpl => {
            this.insurers.push(mtpl[`product`][`insurer`][`name`] as string);
          });
        }
      }
    );
  }

 hideTable(MTPLData) {
    if (MTPLData) {
      MTPLData[`MTPL`][`1`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });
      MTPLData[`MTPL`][`1`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'AZT' && a.premium === null) {
          this.hideAZT.push(a);
        }
      });

      MTPLData[`MTPL`][`1`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });
      MTPLData[`MTPL`][`1`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'GAM' && a.premium === null) {
          this.hideGAM.push(a);
        }
      });

      MTPLData[`MTPL`][`1`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
      MTPLData[`MTPL`][`1`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
      MTPLData[`MTPL`][`6`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
      MTPLData[`MTPL`][`12`][`withoutSettlement`].forEach(a => {
        if (a.product.insurer.code === 'OMN' && a.premium === null) {
          this.hideOMN.push(a);
        }
      });
    }
  }

  showHideRow1() {
    this.hideRow1 = !this.hideRow1;
  }

  showHideRow2() {
    this.hideRow2 = !this.hideRow2;
  }

  showHideRow3() {
    this.hideRow3 = !this.hideRow3;
  }

  isMTPLSelected() {
    const isMTPLSelected = JSON.parse(sessionStorage.getItem('selectedMTPLOffer'));
    const offerId = JSON.parse(sessionStorage.getItem('offerId'));
    if (isMTPLSelected !== null) {
      this.isMTPLSelectedError = isMTPLSelected?.offerId === offerId;
    } else {
      this.isMTPLSelectedError = false;
    }
  }

  openDecontare(){
    this.modal.open(DecontareComponent as Component, {
      size: 'lg', centered: true
    });
  }

  getProductForRenewal(){
    if (this.renewalProduct){
      // Installment 1
      const installment1ProductWithSettlement = this.MTPLData[`MTPL`][1][`withSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment1ProductWithSettlement) { this.selectedProduct = installment1ProductWithSettlement; }
      const installment1ProductWithoutSettlement = this.MTPLData[`MTPL`][1][`withoutSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment1ProductWithoutSettlement) { this.selectedProduct = installment1ProductWithoutSettlement; }

      // Installment 6
      const installment6ProductWithSettlement = this.MTPLData[`MTPL`][6][`withSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment6ProductWithSettlement) { this.selectedProduct = installment6ProductWithSettlement; }
      const installment6ProductWithoutSettlement = this.MTPLData[`MTPL`][6][`withoutSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment6ProductWithoutSettlement) { this.selectedProduct = installment6ProductWithoutSettlement; }

      // Installment 12
      const installment12ProductWithSettlement = this.MTPLData[`MTPL`][12][`withSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment12ProductWithSettlement) { this.selectedProduct = installment12ProductWithSettlement; }
      const installment12ProductWithoutSettlement = this.MTPLData[`MTPL`][12][`withoutSettlement`].find
      (x => x.id === this.renewalProduct.productId);
      if (installment12ProductWithoutSettlement) { this.selectedProduct = installment12ProductWithoutSettlement; }

      if (this.selectedProduct){
        const selectedMTPL = this.toModel(this.selectedProduct);
        this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: selectedMTPL}));
        this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: true}));
      }
    }
  }

  checkForSelectedCASCOfferInSessionStorage() {
    const selectedCASCOOffer = JSON.parse(sessionStorage.getItem('selectedCASCOOffer'));
    const selectedOfferId = JSON.parse(sessionStorage.getItem('offerId'));
    const policyCASCO = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const policyMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));

    if (selectedCASCOOffer && selectedCASCOOffer['0'].offerId === selectedOfferId) {
      this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: selectedCASCOOffer}));
      this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: true}));
      this.store.dispatch(toggleJustMTPL({toggleJustMTPL: !policyMTPL}));
      this.store.dispatch(toggleJustCASCO({toggleJustCASCO: !policyCASCO}));
    } else {
      this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: null}));
      this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: null}));
    }
  }

  checkForSelectedMTPLOffer() {
    this.sessionStorageMTPLObj = [];
    const selectedMTPLOffer = JSON.parse(sessionStorage.getItem('selectedMTPLOffer'));
    const selectedOfferId = JSON.parse(sessionStorage.getItem('offerId'));
    const policyCASCO = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const policyMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));

    if (selectedMTPLOffer && selectedMTPLOffer.offerId === selectedOfferId) {
      this.sessionStorageMTPLObj.push(selectedMTPLOffer);
      this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: this.sessionStorageMTPLObj}));
      this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: true}));
      this.store.dispatch(toggleJustMTPL({toggleJustMTPL: !policyMTPL}));
      this.store.dispatch(toggleJustCASCO({toggleJustCASCO: !policyCASCO}));
    } else {
      this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: null}));
      this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: null}));
    }
  }

  isSelected(product){
    const selectedMTPLOffer = JSON.parse(sessionStorage.getItem('selectedMTPLOffer'));
    if (selectedMTPLOffer && product) {
      return product &&
        (product.offer.id === selectedMTPLOffer.offerId && product.id === selectedMTPLOffer.productId);
    } else {
      return false;
    }
  }

  setMTPLOfferToSessionStorage(product) {
    sessionStorage.setItem('selectedMTPLOffer', JSON.stringify(product['0']));
  }

  onItemChange(itemId, item, tdId, hasSettlement) {
    const selectedMTPL = this.toModel(item, tdId, hasSettlement);
    this.selectedItem = selectedMTPL;
    this.setMTPLOfferToSessionStorage(this.selectedItem);
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckSelectedMTPLOffer();
    this.store.select(selectSelectedOfferMTPL).pipe(take(1)).subscribe( rez => {
      if (rez){
        this.productToDeselect = rez;
        const inputTdRe = document.getElementById(rez[0].tdId) as HTMLInputElement;
        inputTdRe.classList.remove('tdSelectedOffer');
      }
    });
    if (this.productToDeselect) {
      if (JSON.stringify(this.productToDeselect[0]?.productId) === JSON.stringify(selectedMTPL[0]?.productId) && this.selectedCount === 0) {
        const input = document.getElementById(tdId) as HTMLInputElement;
        input.checked = false;
        this.selectedCount = this.selectedCount + 1;
        // console.log(this.selectedCount);
        sessionStorage.removeItem('selectedMTPLOffer');
        this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: null}));
        this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: false}));
      } else  {
        const inputTd = document.getElementById(tdId) as HTMLInputElement;
        inputTd.classList.add('tdSelectedOffer');
        this.selectedCount = 0;
        this.setMTPLOfferToSessionStorage(this.selectedItem);
        this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: selectedMTPL}));
        this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: true}));
      }
    } else  {
      const inputTd = document.getElementById(tdId) as HTMLInputElement;
      inputTd.classList.add('tdSelectedOffer');
      this.selectedCount = 0;
      this.setMTPLOfferToSessionStorage(this.selectedItem);
      this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: selectedMTPL}));
      this.store.dispatch(toggleIfSelectedOfferMTPL({toggleIfSelectedOfferMTPL: true}));
    }

  }

  private toModel(item, tdId?, hasSettlement?){
    return [{
      price: item.premium,
      providerName: item.product.insurer.name,
      monthNr: item.product.period,
      rcaType: item.product.type.name,
      offerId: item.offer.id,
      productId: item.id,
      selectedMtpl: true,
      tdId,
      hasSettlement
    }];
  }

  OfferDetails(MTPLData,insurer:string) { 
    //  ALLIANZ  [0] GROUPAMA[1] OMNIASIG[2]
    let insurerId;
    //console.log("Insurer "+ insurer);
    let content = {
      totalPremiumWithout12:'',
      totalPremiumWithout6:'',
      totalPremiumWithoutDS12:'',
      totalPremiumWithoutDS6:'',
      netPremium12:'',
      netPremium6:'',
      brokerageCommission12:'',
      brokerageCommission6:'',
      brokerageCommissionAmount12:'',
      brokerageCommissionAmount6:'',
      brokerageCommissionAmountDS12:'',
      brokerageCommissionAmountDS6:'',
      totalPremiumWith12:'',
      totalPremiumWith6:'',
      referencePremium12:'',
      referencePremium6:'',
      bonusMalus12:'',
      bonusMalus6:''
    };

    const modalRef = this.modal.open(OfferDetailsPopupComponent as Component, {
      size: 'lg', centered: true
    });
    if(insurer.toUpperCase() ==='ALLIANZ')
    {
      insurerId = 0;
    }
    else if(insurer.toUpperCase() ==='GROUPAMA')
    {
      insurerId = 1;
    }
    else if(insurer.toUpperCase() ==='OMNIASIG')
    {
      insurerId = 2;
    }    
    
     content.totalPremiumWithout12 = MTPLData[12]['withSettlement'][insurerId]['premiumMtpl'];
     content.totalPremiumWithout6 = MTPLData[6]['withSettlement'][insurerId]['premiumMtpl'];

     content.totalPremiumWithoutDS12 = MTPLData[12]['withSettlement'][insurerId]['premiumMtplWithDS'];
     content.totalPremiumWithoutDS6 = MTPLData[6]['withSettlement'][insurerId]['premiumMtplWithDS'];

      content.netPremium12 = MTPLData[12]['withSettlement'][insurerId]['premiumMtplNet'];
      content.netPremium6 = MTPLData[6]['withSettlement'][insurerId]['premiumMtplNet'];      
      
      content.brokerageCommissionAmount12 = MTPLData[12]['withSettlement'][insurerId]['commissionMtplAmount'];
      content.brokerageCommissionAmount6 = MTPLData[6]['withSettlement'][insurerId]['commissionMtplAmount'];
      
      content.brokerageCommissionAmountDS12 = MTPLData[12]['withSettlement'][insurerId]['commissionMtplDSAmount'];
      content.brokerageCommissionAmountDS6 = MTPLData[6]['withSettlement'][insurerId]['commissionMtplDSAmount'];

      content.brokerageCommission12 = MTPLData[12]['withSettlement'][insurerId]['commissionMtplPercent'];
      content.brokerageCommission6 = MTPLData[6]['withSettlement'][insurerId]['commissionMtplPercent'];

      content.totalPremiumWith12 = MTPLData[12]['withSettlement'][insurerId]['premiumMtplDSAmount'];
      content.totalPremiumWith6 = MTPLData[6]['withSettlement'][insurerId]['premiumMtplDSAmount'];
      
      content.referencePremium12 = MTPLData[12]['withSettlement'][insurerId]['referencePremium'];
      content.referencePremium6 = MTPLData[6]['withSettlement'][insurerId]['referencePremium'];
      
      content.bonusMalus12 = MTPLData[12]['withSettlement'][insurerId]['bonusMalusClass'];
      content.bonusMalus6 = MTPLData[6]['withSettlement'][insurerId]['bonusMalusClass'];
      
      modalRef.componentInstance.content = content;
  }
}
