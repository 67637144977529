import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (
      !req.url.includes('data-sources') &&
      !req.url.includes('quotations') &&
      !req.url.includes('/offer-errors/') &&
      !req.url.includes('/pull/')
    ) {
    }
    return next.handle(req).pipe(
      finalize(() => {
        // console.log('error-interceptor, finalize: ', req.url);
      }),
      catchError((error) => {
        return this.errorManagement(error);
      })
    );
  }


  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        // this.router.navigate(['logout']);
      }
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // this.notifyServiceService.setMessage(errorMessage);
    return throwError(errorMessage);
  }
}
