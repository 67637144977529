import { Injectable } from '@angular/core';
import {UserCarDetails, UserCarDetailsConfig} from '../../models/user-car-details.model';

@Injectable({
  providedIn: 'root'
})
export class UserCarDetailsService {

  private readonly config: UserCarDetailsConfig;
  constructor() {
    this.config = {
      modDeUtilizare: '',
      nrInmatriculare: '',
      kilometraj: '',
      carDetails: {
        sasiu: '',
        combustibil: '',
        marca: '',
        model: '',
        categorie: '',
        moneda: '',
        valuareAchizitie: '',
        valuareAsigurata: '',
        masaMaxima: '',
        nrLocuri: 0,
        putereKw: 0,
        capacitateMotor: 0,
        anConstructie: 0,
        statusVehicul: '',
        dataAchizite: ''
      }
    };
  }

  public getConfig() {
    return this.config;
  }
}
