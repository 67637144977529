import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marsh-petitions',
  templateUrl: './petitions.component.html',
  styleUrls: ['./petitions.component.css']
})
export class PetitionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
