import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Utils from '../../shared/utils/utils';
import {LocalStorageService} from '../../services/local-storage.service';
import {UserSubscriptionsComponent} from '../user/user-subscriptions/user-subscriptions.component';
import {GetDataService} from '../../shared/services/get-data.service';
import {
  InsuranceConditionsModalComponent
} from '../offer/offer/insurance-conditions-modal/insurance-conditions-modal.component';
import {AppState, selectMyPolicies} from '../../store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-marsh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  messages = [];
  changeIcoBoolCt = false;
  changeIcoBoolInfo = false;
  isMobile: boolean;
  selectMyPoliciesSub: Subscription;
  showMoreMessages: boolean;
  activePoliciesCount: 0;
  showOverlay = true;

  constructor(
    private getDataService: GetDataService,
    private localStorageService: LocalStorageService,
    private modal: NgbModal,
    private store: Store<AppState>,
  ) {
    this.activePoliciesCount = 0;
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
    this.selectMyPoliciesSub = this.store.select(selectMyPolicies).subscribe(x => {
      if (x) {
        if (x.allActivePoliciesLength !== undefined) {
          this.activePoliciesCount = x.allActivePoliciesLength.activeLength;
        } else {
          this.activePoliciesCount = 0;
        }
      } else {
        this.activePoliciesCount = 0;
      }
    });
    setTimeout(() => {
      this.showOverlay = false;
    }, 4000);
  }


  showMore() {
    this.showMoreMessages = !this.showMoreMessages;
  }

  changeIconCt() {
    this.changeIcoBoolCt = !this.changeIcoBoolCt;
  }

  changeIconInfo() {
    this.changeIcoBoolInfo = !this.changeIcoBoolInfo;
  }

  openUserSubscriptionsModal() {
    const modalRef = this.modal.open(UserSubscriptionsComponent as Component, {
      size: 'lg', centered: true, backdrop: 'static'
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.selectMyPoliciesSub.unsubscribe();
  }

  openInsuranceConditionsModal(cascoProviderName) {
    const modalRef = this.modal.open(InsuranceConditionsModalComponent as Component, {
      centered: true, size: 'lg'
    });
    modalRef.componentInstance.showAccept = false;
    modalRef.componentInstance.notFromOffer = true;
    modalRef.componentInstance.inputInsurer = cascoProviderName;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
}
