import {Action, ActionReducer} from '@ngrx/store';
import { AuthActionTypes } from '../actions/auth.actions';

export interface StateAuth {
  isOktaAuthenticated: boolean;
  oktaToken: any;
  apigeeToken: any;
  userDetails: any;
  preValidateUserData: any;
}

export const initialState: StateAuth = {
  isOktaAuthenticated: false,
  oktaToken: null,
  apigeeToken: null,
  userDetails: null,
  preValidateUserData: null
};

export function reducer(stateAuth = initialState, action: Action): StateAuth {
  switch (action.type) {

    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...stateAuth,
        isOktaAuthenticated: true,
        oktaToken: action[`payload`][`0`][`tokenOkta`],
        apigeeToken: action[`payload`][`0`][`responseApigee`],
      };

    case AuthActionTypes.SET_USER_DETAILS:
      return {
        ...stateAuth,
        userDetails: action[`payload`]['`0']
      };

    case AuthActionTypes.PRE_VALIDATE_USER_DETAILS:
      return {
        ...stateAuth,
        preValidateUserData: action[`payload`]
      };

    default:
      return stateAuth;
  }
}

export const selectIsOktaAuthenticated = (stateAuth: StateAuth) => stateAuth.isOktaAuthenticated;
export const selectOktaToken = (stateAuth: StateAuth) => stateAuth.oktaToken;
export const selectApigeeToken = (stateAuth: StateAuth) => stateAuth.apigeeToken;
export const selectUserDetails = (stateAuth: StateAuth) => stateAuth.userDetails;
export const selectPreValidateUserDetails = (stateAuth: StateAuth) => stateAuth.preValidateUserData;
