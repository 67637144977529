import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storageSub = new Subject<any>();

  constructor() {}

  public setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public getData(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }


  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItemForWatch(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next({key, data});
  }

  removeItemForWatch(key) {
    localStorage.removeItem(key);
    this.storageSub.next(key);
  }

}
