import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OfferService } from 'src/app/services/offers.service';
import {WarningTypeEnum} from '../../../models/mapping.model';
import Utils from '../../utils/utils';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-marsh-request-consultant',
  templateUrl: './request-consultant.component.html',
  styleUrls: ['./request-consultant.component.scss']
})
export class RequestConsultantComponent implements OnInit {
  warningType: WarningTypeEnum;
  @Input() product;
  isMobile: boolean;
  insurer: string;
  customerPhone: string;
  customerId: number;
  offerId: number;
  errors: string;
  constructor(public activeModal: NgbActiveModal, private offerService: OfferService, private localStorageService: LocalStorageService) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
    this.getCustomerId();
    this.getOfferId();
  }

  sendSolicitation() {
    if (this.customerPhone && this.customerPhone.length > 0){
      this.updateCustomerPhoneNumber();
    }
    this.reportData();
    if (!this.errors || this.errors.length === 0){
      this.closeModal();
    }
  }

  updateCustomerPhoneNumber(){
    const data = {
      phone: this.customerPhone
    };
    if (this.customerId){
      this.offerService.patchCustomer(this.customerId, data).subscribe((result) => {
        if (!result){
          this.errors = 'Nu s-a putut salva numărul de telefon';
        }
        else {
          this.errors = '';
        }
      });
    }
  }

  reportData(){
    if (this.offerId){
      const data = {
        objectId: this.offerId,
        objectType: 'risk inspection',
        text: 'test',
        objectEnvironment: environment.app ?? ''

      };
      this.offerService.reportIncorrectData(data).subscribe(result => {
        if (result){
          if (result.error === true){
            this.errors = result.message;
          }
          else{
            this.errors = '';
          }
        }
      });
    }
  }

  getCustomerId(){
    this.customerId = JSON.parse(window.sessionStorage.getItem('customerId'));
  }

  getOfferId(){
    this.offerId = JSON.parse(window.sessionStorage.getItem('offerId'));
  }

  closeModal(data?: any) {
    this.activeModal.close(data);
  }
}
