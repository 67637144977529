import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { delay, repeat, switchMap, takeWhile, tap, finalize, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PullDataService {

    // private responseFileUrl = 'http://localhost:3333/api/offers/pull/response';
    private responsesMap = new Map<string, object>();

    constructor(
        private http: HttpClient,
    ) { }

    postAsyncRequest(url: string, body: object = {}, options?: object): Observable<any> {
        let pullResponseUrl = `${url.split('/pull/')[0]}/pull/response`;
        let pullResponse$ = new Subject<any>();
        let finalResponse$ = new Subject<any>();

        // console.log('postAsyncRequestpostAsyncRequestpostAsyncRequest');
        this.http.post<any>(url, body, options)
            .pipe(
            ).subscribe(data => {
                // console.log('A venit raspuns imediat cu locatia unde trebuie sa verifice clientul raspunsul');
                // console.log('Informatii imediate de pe server (id unic unde gasim datele): ', data);
                let responseItem = {
                    initialResponse: data,
                    pullResponse$,
                    finalResponse$,
                    retryOn: true,
                    delayTime: 5000,
                };
                this.responsesMap.set(data.responseDataKey, responseItem);

                startPullingResponseData.bind(this, responseItem)();
            });

        function startPullingResponseData(responseItem) {
            // console.log('startPullingResponseData', responseItem);
            responseItem.pullResponse$.pipe(
                switchMap(responseDataKey =>
                    this.http.get(`${pullResponseUrl}/${responseDataKey}`)),
            ).subscribe(data => {
                // console.log('Reincearca pana cand dataReady este true: ');
                // console.log('In timpul asta serverul asteapta raspuns de la furnizori: ');
                // console.log(data);
                if (data.dataReady) {
                    // console.log('Stop trying... dataReady value: ', data.dataReady);
                    responseItem.retryOn = false;
                    this.notify ? this.notify.hideLoading() : null;
                    finalResponse$.next(data);
                }
            });

            of(null)
                .pipe(
                    takeWhile(() => responseItem.retryOn),
                    switchMap(data => of(responseItem.pullResponse$.next(responseItem.initialResponse.responseDataKey))),
                    delay(responseItem.delayTime),
                    repeat(50),  // maxim 50 if retryOn doesn't become false
                    finalize(() => {
                        this.notify.hideLoading();
                    }),
                    catchError((error) => {
                        this.notify.hideLoading();
                        return this.errorManagement(error);
                    })
                )
                .subscribe(response => { });
        }

        return finalResponse$;
    }

}
