import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {
  @Input() public fromModal;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.fromModal === true) {
        const top = document.getElementById('top');
        top.scrollIntoView();
      }
    }, 800);
  }

  closeModal() {
    this.activeModal.close();
  }

}
