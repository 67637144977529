import { createAction, props } from '@ngrx/store';

export enum PoliciesActionTypes {
  FETCH_POLICIES_DATA = '[Policies Data] Fetch policies data',
  FETCH_POLICIES_DATA_SUCCESS = '[Policies Data] Fetch policies data successfully',
  FETCH_POLICY_INSTALLMENTS = '[Policies Data] Fetch policy data',
  FETCH_POLICY_HISTORY = '[Policies Data] Fetch policy history data',
  FETCH_POLICIES_TO_DOWNLOAD = '[Policies Data] Fetch policies to download after purchase',
  FETCH_POLICY_CONFIRMATION_DATA = '[Payment Confirmation] Set payment confirmation data',
  FETCH_INSTALLMENT_TO_PAY_AND_CAR = '[Policies Data] Fetch installment to pay and car',
  FETCH_UN_PAYED_INSTALLMENT= '[Policies Data] Fetch un-payed installment',
}

export const fetchPoliciesData = createAction(
  PoliciesActionTypes.FETCH_POLICIES_DATA
);

export const fetchPoliciesDataSuccess = createAction(
  PoliciesActionTypes.FETCH_POLICIES_DATA_SUCCESS,
  props<{myPolicies: any}>()
);

export const fetchUnPayedInstallment = createAction(
  PoliciesActionTypes.FETCH_UN_PAYED_INSTALLMENT,
  props<{unPayedInstallment: any}>()
);

export const fetchPolicyInstallmentsSuccess = createAction(
  PoliciesActionTypes.FETCH_POLICY_INSTALLMENTS,
  props<{selectedPolicyInstallments: any}>()
);

export const fetchPolicyHistorySuccess = createAction(
  PoliciesActionTypes.FETCH_POLICY_HISTORY,
  props<{selectedPolicyHistory: any}>()
);

export const fetchPolicyConfirmationData = createAction(
  PoliciesActionTypes.FETCH_POLICY_CONFIRMATION_DATA,
  props<{policyPaymentConfirmationData: any}>()
);

export const fetchPoliciesToDownload = createAction(
  PoliciesActionTypes.FETCH_POLICIES_TO_DOWNLOAD,
  props<{policesToDownload: any}>()
);

export const fetchInstallmentToPayAndCar = createAction(
  PoliciesActionTypes.FETCH_INSTALLMENT_TO_PAY_AND_CAR,
  props<{installmentToPayAndCar: any}>()
);
