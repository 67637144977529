import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {
  mergeMap,
  catchError,
} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromStore from '../';

import {fetchPoliciesData} from '../actions/policies.actions';
import {MyPoliciesService} from '../../shared/services/myPoliciesService/my-policies.service';

@Injectable()
export class PoliciesEffects {
  fetchPolicies$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchPoliciesData),
        mergeMap(_ => [
          this.myPoliciesService.getPoliciesList(),
          catchError((err) => EMPTY)
        ])
      );
    }, {dispatch: false});

  constructor(
    private store: Store<fromStore.AppState>,
    private actions$: Actions,
    private myPoliciesService: MyPoliciesService
  ) {
  }
}
