import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MFACheckModel, UserLoginModel} from '../../../../models/user-login.model';
import {ApiService} from '../../../../services/api.service';
import {CookieService} from 'ngx-cookie-service';
import {
  LoginSuccess, SetPreValidateUserDetails, SetUserDetails
} from '../../../../store/actions/auth.actions';
import {ApigeeService} from '../../../../services/apigee.service';
import {Store} from '@ngrx/store';
import {SessionDataService} from '../../../../services/session-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {UserPreValidateModel} from '../../../../models/user-register.model';
import {selectPreValidateUserDetails} from '../../../../store';
import {GetDataService} from '../../../../shared/services/get-data.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-marsh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  hide = true;
  cookiesSet;
  hasCookiesSet;
  errors;
  pinMFAFromEmailError;
  userEmailCookie;
  userPasswordCookie;
  rememberPassToggleCookie;
  userLogin: UserLoginModel;
  submitted = false;
  customerId: number;
  loading = false;
  loadingMFACode = false;
  loginMFAFormTemplate = false;
  loginFormTemplate = true;
  loginWithMFA;
  MFACheckData: MFACheckModel;
  sub: Subscription;
  subParams: Subscription;
  responsePreValidate;
  userPreValidate: UserPreValidateModel;
  preValidateNotFound;
  userHasAccount: boolean;
  offerToken;
  hasWrongData = false;
  offerisActive;
  public ActivateMFACodeForm: FormGroup;
  public LoginForm: FormGroup;
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private form: FormBuilder,
    private store: Store,
    private apiService: ApiService,
    private router: Router,
    private getDataService: GetDataService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private sessionStorage: SessionDataService,
    private cookieService: CookieService,
    private apigeeService: ApigeeService,
  ) {
  }

  get LoginFormValidateControls() { return this.LoginForm.controls; }

  get offerStatus() {return JSON.parse(window.sessionStorage.getItem('offerStatus')); }

  ngOnInit() {
    this.loginWithMFA = environment.withMFA;
    this.subParams = this.route
      .queryParams
      .subscribe(params => {
        if (params['offer-token']) {
          this.checkForAccount(params['offer-token']);
        }
      });
    this.LoginForm = this.form.group({
      emailLogin: ['', [Validators.required, Validators.email]],
      passwordLogin: ['', [Validators.required]],
      // rememberPasswordLogin: [false],
    });
    this.ActivateMFACodeForm = this.form.group({
      activationMFAPin: ['', [Validators.required]],
    });
    this.store.select(selectPreValidateUserDetails).subscribe( resPreValidateUserData => {
      if (resPreValidateUserData !== null) {
        this.offerToken = resPreValidateUserData.offerToken;
        this.offerisActive = resPreValidateUserData.preValidateUserDetails.isActive;
      }
    });

  }

  checkForNotification(){
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        if (params['offer-token'] && !this.offerToken) {
          this.getDataService.refreshAllTheData();
          // this.router.navigate(['/offer/'], {queryParams: params});
          this.router.navigate(['/home'], {replaceUrl: true});
        } else if (params['credit-note-token']){
          this.getDataService.refreshAllTheData();
          this.router.navigate(['/pay-installment/'], {queryParams: params}
          );
        } else if (this.offerToken && this.offerisActive && !this.hasWrongData){
          this.getDataService.refreshAllTheData();        
          sessionStorage.setItem('activeOffers', String(true));
          // this.router.navigateByUrl(`/offer?offer-token=${this.offerToken}`);
          this.router.navigate(['/home'], {replaceUrl: true});
        } else if (this.offerToken && !this.offerisActive && !this.hasWrongData){
          this.getDataService.refreshAllTheData();
          this.router.navigate(['/home'], {replaceUrl: true});
          this.toastrService.info('Oferta a expirat', '', {
            timeOut: 5000,
          });
        } else if (this.offerToken && this.hasWrongData && this.offerisActive){
          this.getDataService.refreshAllTheData();
          sessionStorage.setItem('activeOffers', String(true));
          this.router.navigate(['/home'], {replaceUrl: true});
        }
        else {
          this.getDataService.refreshAllTheData();
          this.router.navigate(['/home'], {replaceUrl: true});
        }
      });
    if (this.offerStatus){
      // if (this.offerStatus.offerUuid && this.offerStatus.status){
      if (this.offerStatus.offerUuid) {
        sessionStorage.setItem('activeOffers', String(true));
        // this.router.navigateByUrl(`/offer?offer-token=${this.offerStatus.offerUuid}`);
        this.router.navigate(['/home'], {replaceUrl: true});
      } else if (this.offerStatus.offerUuid && !this.offerStatus.status) {
        this.getDataService.refreshAllTheData();
        this.router.navigate(['/home'], {replaceUrl: true});
        this.toastrService.info('Oferta a expirat', '', {
          timeOut: 5000,
        });
      }
    }
  }

  get activateCodeMFAFormControls() {
    return this.ActivateMFACodeForm.controls;
  }

  checkForAccount(offerToken) {
    this.recaptchaV3Service.execute('preValidateSubmit').subscribe(
      (token) => {
        this.userPreValidate = new UserPreValidateModel();
        this.userPreValidate.offerToken = offerToken;
        this.apiService.preValidateUser(this.userPreValidate, token).then(r => {
          this.responsePreValidate = r;
          if (
            this.responsePreValidate.message === 'NotFound' ||
            this.responsePreValidate.statusCode === 500 ||
            this.responsePreValidate.statusCode === 403) {
            this.preValidateNotFound = true;
            return;
          } else {
            if (this.responsePreValidate.hasAccount === false) {
              this.userHasAccount = false;
              this.store.dispatch(SetPreValidateUserDetails({payload: {preValidateUserDetails: this.responsePreValidate, offerToken}}));
              this.router.navigate(['/register']);
            } else {
              this.userHasAccount = true;
              this.store.dispatch(SetPreValidateUserDetails({payload: {preValidateUserDetails: this.responsePreValidate, offerToken}}));
              this.toastrService.info('Pentru acest user ai creat deja cont. Te rugăm să te autentifici. Îți mulțumim!', '', {
                timeOut: 5000,
              });
            }
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

  confirmMFAPinFromEmail() {
    this.recaptchaV3Service.execute('preValidateSubmit').subscribe(
      (token) => {
        this.loadingMFACode = true;
        this.MFACheckData.mfaCode = this.ActivateMFACodeForm.controls.activationMFAPin.value;
        this.apiService.MFACheck(this.MFACheckData, token).then(response => {
          const responseMFACheck: any = response;
          if (responseMFACheck.accessToken === null) {
            this.pinMFAFromEmailError = true;
            this.loadingMFACode = false;
          } else {
            this.apigeeService.requestTokenApiGee(responseMFACheck.accessToken).then(rApigee => {
              if (rApigee !== null || rApigee !== '') {
                const responseApigee: any = rApigee;
                const tokenOkta = responseMFACheck.accessToken;
                const loginSuccess = [{responseApigee, tokenOkta}];
                sessionStorage.setItem('okta-token-storage', tokenOkta);
                sessionStorage.setItem('apigee', responseApigee.accessToken);
                this.store.dispatch(LoginSuccess({payload: loginSuccess}));
                this.apiService.sessionData().subscribe(res => {
                  if (res.statusCode === 403 || res.statusCode === 404) {
                    this.errors = true;
                    this.loading = false;
                    this.loadingMFACode = false;
                    return;
                  }
                  if(res.lists!=null)
                  {
                    sessionStorage.setItem('insurers', res.lists.insurers);
                  }
                  if (res.customerId !== null) {
                    const customerId = res.customerId;
                    const userEmail = this.userLogin.email;
                    const userName = res.userName;
                    sessionStorage.setItem('customerId', customerId);
                    sessionStorage.setItem('customerEmail', userEmail);
                    sessionStorage.setItem('fullName', userName);
                    const userDetails = [{customerId, userEmail, userName}];
                    this.store.dispatch(SetUserDetails({payload: userDetails}));
                    this.loading = false;
                    this.loadingMFACode = false;
                    this.hasWrongData = res.hasWrongData;
                    this.checkForNotification();
                  } else {
                    this.errors = true;
                    this.loading = false;
                    this.loadingMFACode = false;
                    return;
                  }
                });
              } else {
                this.errors = true;
                this.loading = false;
                this.loadingMFACode = false;
              }
            });
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

  // cookieValues() {
  //   this.hasCookiesSet = false;
  //   this.userEmailCookie = this.cookieService.get('email');
  //   this.userPasswordCookie = this.cookieService.get('password');
  //   this.rememberPassToggleCookie = this.cookieService.get('rememberPassToggle');
  //
  //   if (this.userEmailCookie.length > 0) {
  //     this.LoginForm.patchValue({
  //       emailLogin: this.userEmailCookie,
  //       passwordLogin: this.userPasswordCookie,
  //       rememberPasswordLogin: this.rememberPassToggleCookie
  //     });
  //   }
  // }

  submitLoginForm(tokenCaptcha) {
    this.loading = true;
    this.userLogin = new UserLoginModel();
    this.userLogin.email = this.LoginForm.controls.emailLogin.value;
    this.userLogin.password = this.LoginForm.controls.passwordLogin.value;
    // if (this.LoginForm.controls.rememberPasswordLogin.value === true) {
    //   this.cookieService.set('email', this.LoginForm.controls.emailLogin.value, 365, '', '', false , 'Lax');
    //   this.cookieService.set('password', this.LoginForm.controls.passwordLogin.value, 365, '', '', false , 'Lax');
    //   this.cookieService.set('rememberPassToggle', this.LoginForm.controls.rememberPasswordLogin.value, 365, '', '', false , 'Lax');
    // } else {
    //   this.cookieService.delete('email');
    //   this.cookieService.delete('password');
    //   this.cookieService.delete('rememberPassToggle');
    // }
    if (this.loginWithMFA === true) {
      this.apiService.LoginUser(this.userLogin, tokenCaptcha).then(r => {
        const responseLoginUser: any = r;
        if (responseLoginUser.error === false) {
          this.MFACheckData = new MFACheckModel();
          this.MFACheckData.stateToken = responseLoginUser.stateToken;
          this.MFACheckData.factorId = responseLoginUser.factorId;
          this.loginMFAFormTemplate = true;
          this.loginFormTemplate = false;
        }else {
          this.loading = false;
          this.errors = true;
          console.log('Error while Login');
        }
      });
    } else {
      this.apiService.LoginUser(this.userLogin, tokenCaptcha).then(r => {
        const responseOktaToken: any = r;
        if (responseOktaToken.error === false && responseOktaToken.accessToken !== null) {
          this.apigeeService.requestTokenApiGee(responseOktaToken.accessToken).then(rApigee => {
            if (rApigee !== null || rApigee !== ''){
              const responseApigee: any = rApigee;
              const tokenOkta = responseOktaToken.accessToken;
              const loginSuccess = [{responseApigee, tokenOkta}];
              sessionStorage.setItem('okta-token-storage', tokenOkta);
              sessionStorage.setItem('apigee', responseApigee.accessToken);
              this.store.dispatch(LoginSuccess({ payload: loginSuccess }));
              this.apiService.sessionData().subscribe(res => {
                if (res.statusCode === 403 || res.statusCode === 404){
                  this.errors = true;
                  this.loading = false;
                  console.log("Error on session data call"+ JSON.stringify(res));
                  return;
                }
                if(res.lists!=null)
                {
                  sessionStorage.setItem('insurers', JSON.stringify(res.lists.insurers));
                }
                if (res.customerId !== null){
                  const customerId = res.customerId;
                  const userEmail = this.userLogin.email;
                  const userName = res.userName;
                  sessionStorage.setItem('customerId', customerId);
                  sessionStorage.setItem('customerEmail', userEmail);
                  sessionStorage.setItem('fullName', userName);
                  const userDetails = [{customerId, userEmail, userName}];
                  this.store.dispatch(SetUserDetails({payload: userDetails}));
                  this.loading = false;
                  this.hasWrongData = res.hasWrongData;
                  this.checkForNotification();
                } else {
                  this.errors = true;
                  this.loading = false;
                  console.log("Customer id not present");                  
                  return;
                }
              });
            } else {
              this.errors = true;
              this.loading = false;
              console.log("error while ApiGee" + JSON.stringify(rApigee));
            }
          });
        }else {
          this.loading = false;
          this.errors = true;
          console.log("error while login");
        }
      });
    }


  }

  public loginForm(): void {
    this.recaptchaV3Service.execute('registerSubmit').subscribe(
      (tokenCaptcha) => {
        this.submitLoginForm(tokenCaptcha);
      },
      (error) => {
        this.errors = true;
        console.log(JSON.stringify(error));
      });
  }

}

// 0768632210
// 71
