export class UserLoginModel{
  email: string;
  password: string;
  rememberPass: boolean;
}
export class MFACheckModel {
  mfaCode: string;
  stateToken: string;
  factorId: string;
}
