import {Component, Input, OnInit} from '@angular/core';

type CompanyNames = 'omniasig' | 'allianz' | 'groupama';

@Component({
  selector: 'terms-with-chapters',
  templateUrl: './terms-with-chapters.component.html',
  styleUrls: ['./terms-with-chapters.component.scss']
})
export class TermsWithChaptersComponent implements OnInit {

  @Input() company: CompanyNames;
  @Input() chapter: number;

  ngOnInit() {
    //console.log(`WHOS THAT POKEMON??? ${this.company}`)
  }
  get companyName(){
    return this.company.toLocaleLowerCase();
  }
}

