import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {RezCheckForErrorsWithRCA} from '../Interfaces/rez-check-for-errors-with-rca';
import {
  AppState,
  selectIsCASCOSelected,
  selectIsMTPLSelected,
  selectIsTerms,
  selectWantsConsultancyCASCO,
  selectWantsConsultancyMTPL
} from '../store';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {POLICY_TYPES} from '../models/casco-table.model';

@Injectable({
  providedIn: 'root'
})
export class OfferErrorsService implements OnDestroy {
  public errorsWithRCAInterface: RezCheckForErrorsWithRCA[];
  isMTPLSelect: boolean;
  selectIsCASCOSelectedSubscription: Subscription;
  isCASCOOfferSelected: BehaviorSubject<boolean>;
  isMTPLOfferSelected: BehaviorSubject<boolean>;
  wantConsultancyCASCO: BehaviorSubject<boolean>;
  wantConsultancyMTPL: BehaviorSubject<boolean>;
  acceptInsurerConditions: BehaviorSubject<boolean>;
  policyTypes = POLICY_TYPES;

  constructor(
    private store: Store<AppState>
  ) {
    this.isCASCOOfferSelected = new BehaviorSubject(null);
    this.isMTPLOfferSelected = new BehaviorSubject(null);
    this.wantConsultancyCASCO = new BehaviorSubject(false);
    this.wantConsultancyMTPL = new BehaviorSubject(false);
    this.acceptInsurerConditions = new BehaviorSubject(null);
  }

  resetAllToggles() {
    this.isCASCOOfferSelected.next(null);
    this.isMTPLOfferSelected.next(null);
    this.wantConsultancyCASCO.next(false);
    this.wantConsultancyMTPL.next(false);
    this.acceptInsurerConditions.next(null);
  }

  // statusOffTogglesFromOfferMTPL() {
  //   this.store.select(selectIsMTPLSelected).subscribe(isMTPLSelected => {
  //     if (isMTPLSelected === null || isMTPLSelected === false){
  //       this.isMTPLOfferSelected.next(false);
  //       this.store.select(selectWantsConsultancyMTPL).subscribe(wantsConsultancy => {
  //         this.wantConsultancyMTPL.next(wantsConsultancy);
  //       });
  //       this.isMTPLSelect = false;
  //     } else {
  //       this.store.select(selectWantsConsultancyMTPL).subscribe(wantsConsultancy => {
  //         this.wantConsultancyMTPL.next(wantsConsultancy);
  //       });
  //       this.isMTPLSelect = true;
  //     }
  //   });
  //   return this.isMTPLSelect;
  // }
  //
  // statusOffTogglesFromOfferMTPLWithConsultancy() {
  //
  //   this.store.select(selectWantsConsultancyCASCO).subscribe(wantsConsultancy => {
  //     this.wantConsultancyCASCO.next(wantsConsultancy);
  //   });
  //   if (this.wantConsultancyCASCO.value === true || this.wantConsultancyCASCO.value === false){
  //     return this.isMTPLSelect;
  //   }
  //   this.store.select(selectWantsConsultancyMTPL).subscribe(wantsConsultancy => {
  //     this.wantConsultancyMTPL.next(wantsConsultancy);
  //   });
  //   if (this.wantConsultancyMTPL.value === true || this.wantConsultancyMTPL.value === false){
  //     return this.isMTPLSelect;
  //   }
  // }

  ngOnDestroy() {
    this.selectIsCASCOSelectedSubscription.unsubscribe();
  }

  getStatusOffTogglesFormOffer(fromWhatButton?: number) {
    const isCASCOSelected = JSON.parse(sessionStorage.getItem('selectedCASCOOffer'));
    const policyMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));
    const policyCASCO = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const offerId = JSON.parse(sessionStorage.getItem('offerId'));
    if (isCASCOSelected !== null) {
      if (isCASCOSelected[0]?.offerId === offerId){
        this.isCASCOOfferSelected.next(true);
      } else {
        this.isCASCOOfferSelected.next(false);
      }
    } else {
      this.isCASCOOfferSelected.next(false);
    }

    const isMTPLSelected = JSON.parse(sessionStorage.getItem('selectedMTPLOffer'));
    if (isMTPLSelected !== null) {
      if (isMTPLSelected?.offerId === offerId){
        this.isMTPLOfferSelected.next(true);
      } else {
        this.isMTPLOfferSelected.next(false);
      }
    } else {
      this.isMTPLOfferSelected.next(false);
    }


    this.store.select(selectIsTerms).subscribe(isTerms => {
      if (!isTerms) {
        this.acceptInsurerConditions.next(false);
      } else {
        this.acceptInsurerConditions.next(isTerms);
      }
    });

    this.store.select(selectWantsConsultancyCASCO).subscribe(wantsConsultancy => {
      this.wantConsultancyCASCO.next(wantsConsultancy);
    });
    this.store.select(selectWantsConsultancyMTPL).subscribe(wantsConsultancy => {
      this.wantConsultancyMTPL.next(wantsConsultancy);
    });
    if (policyCASCO && policyMTPL && fromWhatButton === 2) {
      return this.isCASCOOfferSelected.value === true && this.isMTPLOfferSelected.value === true &&
        this.acceptInsurerConditions.value === true &&
        (this.wantConsultancyCASCO.value === false || this.wantConsultancyCASCO.value === true) &&
      (this.wantConsultancyMTPL.value === false || this.wantConsultancyMTPL.value === true);
    } else if (policyCASCO && !policyMTPL && fromWhatButton === 1) {
      return this.isCASCOOfferSelected.value === true &&
        this.acceptInsurerConditions.value === true &&
        (this.wantConsultancyCASCO.value === false || this.wantConsultancyCASCO.value === true);
    } else if (policyCASCO && policyMTPL && fromWhatButton === 1) {
      return this.isCASCOOfferSelected.value === true &&
        this.acceptInsurerConditions.value === true &&
        (this.wantConsultancyCASCO.value === false || this.wantConsultancyCASCO.value === true);
    } else if (!policyCASCO && policyMTPL && fromWhatButton === 2) {
      return this.isMTPLOfferSelected.value === true &&
        (this.wantConsultancyMTPL.value === false || this.wantConsultancyMTPL.value === true);
    } else if (fromWhatButton === 3) {
      return this.isCASCOOfferSelected.value === true &&
        this.acceptInsurerConditions.value === true &&
        (this.wantConsultancyCASCO.value === false || this.wantConsultancyCASCO.value === true);
    }


    // if ( this.isMTPLOfferSelected.value === true &&
    //   (this.wantConsultancyMTPL.value === false || this.wantConsultancyMTPL.value === true))
    // {
    //   return true;
    // }

  }
}
