import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {ApiService} from '../../../services/api.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-marsh-cancel-policy-confirm',
  templateUrl: './cancel-policy.component.html',
  styleUrls: ['./cancel-policy.component.scss']
})
export class CancelPolicyConfirmComponent implements OnInit {
  faClose = faWindowClose;
  cancelPolicyObj;
  constructor(
    private modal: NgbModal,
    private toastrService: ToastrService,
    private apiService: ApiService
  ) { }
  @Input() policyData: any;

  ngOnInit() {}

  cancelPolicy(policyItem) {
    this.cancelPolicyObj = {
      objectId: policyItem.policyData.id,
      objectType: 'cancelPolicy',
      text: 'User vrea sa anuleze polita',
      objectEnvironment: environment.app
    };
    this.toastrService.success('Mesajul tău a fost transmis către echipa Marsh care urmează să te contacteze în cel mai scurt timp. Mulțumim pentru sesizare. Îți dorim o zi frumoasă!', '', {
      timeOut: 5000,
    });
    this.apiService.reportIncorrectObjectData(this.cancelPolicyObj).subscribe( rez => {
      this.modal.dismissAll();
    });
  }

  closeModal(){
    this.modal.dismissAll();
  }

}
