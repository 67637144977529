import { Injectable } from '@angular/core';
import {UserDetailsConfig} from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  private readonly config: UserDetailsConfig;
  constructor() {
    this.config = {
      id: 0,
      CNP: '',
      prenume: '',
      nume: '',
      email: '',
      generalLocation: {
        judet: '',
        oras: '',
        datapermis: ''
      },
      exactLocation: {
        str: '',
        nr: '',
        bl: '',
        ap: '',
        phone: ''
      }
    };
  }

  public getConfig() {
    return this.config;
  }
}
