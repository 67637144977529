import {Action} from '@ngrx/store';
import {SelectedOfferActionTypes} from '../actions/selectedOffer.actions';

export interface StateSelectedOffer {
  selectedOfferCASCO: any;
  selectedOfferMTPL: any;
  selectedCascoInsurer: any;
  UUIDCreditNote: any;
}

export const initialState: StateSelectedOffer = {
  selectedOfferCASCO: null,
  selectedOfferMTPL: null,
  selectedCascoInsurer: null,
  UUIDCreditNote: null
};

export function reducer(stateSelectedOffer = initialState, action: Action): StateSelectedOffer {
  switch (action.type) {
    case SelectedOfferActionTypes.SET_SELECTED_OFFER_CASCO:
      return {
        ...stateSelectedOffer,
        selectedOfferCASCO: action[`offerSelectedCASCO`]
      };
    case SelectedOfferActionTypes.SET_SELECTED_OFFER_MTPL:
      return {
        ...stateSelectedOffer,
        selectedOfferMTPL: action[`offerSelectedMTPL`]
      };
    case SelectedOfferActionTypes.SET_SELECTED_CASCO_INSURER:
      return {
        ...stateSelectedOffer,
        selectedCascoInsurer: action[`selectedCascoInsurer`]
      };
    case SelectedOfferActionTypes.SET_CREDIT_NOTE_UUID:
      return {
        ...stateSelectedOffer,
        UUIDCreditNote: action[`setCreditNoteUUID`]
      };
    default:
      return stateSelectedOffer;
  }
}

export const selectOfferCASCOReducer = (stateSelectedOffer: StateSelectedOffer) => stateSelectedOffer.selectedOfferCASCO;
export const selectOfferMTPLReducer = (stateSelectedOffer: StateSelectedOffer) => stateSelectedOffer.selectedOfferMTPL;
export const selectCascoInsurerReducer = (stateSelectedOffer: StateSelectedOffer) => stateSelectedOffer.selectedCascoInsurer;
export const selectUUIDCreditNote = (stateSelectedOffer: StateSelectedOffer) => stateSelectedOffer.UUIDCreditNote;
