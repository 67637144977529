import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {POLICY_TYPES} from '../../../../models/casco-table.model';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import {
  AppState, selectAllTogglesState,
  selectMyPolicies,
  selectOfferData,
  selectSelectedOfferState
} from '../../../../store';
import {Store} from '@ngrx/store';
import {
  toggleForConsultancyCASCO,
  toggleIfSelectedOfferCASCO,
  toggleJustCASCO,
  toggleJustMTPL,
  toggleTermsAndConditions
} from '../../../../store/actions/offerToggle.actions';
import {setSelectedOfferCASCO, setSelectedOfferMTPL} from '../../../../store/actions/selectedOffer.actions';
import {PaymentService} from '../../../../services/payment.service';
import {fetchOfferExpired, fetchOfferExpiredWarningStatus} from '../../../../store/actions/offer.actions';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';
import {SessionDataService} from '../../../../services/session-data.service';
import {OfferButtonTypes} from './models/buttonsOffers.model';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-offer-buttons',
  templateUrl: './offer-buttons.component.html',
  styleUrls: ['./offer-buttons.component.scss']
})
export class OfferButtonsComponent implements OnInit, OnDestroy {
  sub: Subscription;
  @Input() onPagePlacement: string;
  policyTypes = POLICY_TYPES;
  type: any = POLICY_TYPES.casco; // casco or rca
  warningTypeEnum: any;
  packType: any;
  loading = false;
  loadingJustCASCO = false;
  triggerJustCASCOFromAnotherCompSubscription: Subscription;
  triggerJustMTPLFromAnotherCompSubscription: Subscription;
  triggerGoToMTPLFromAnotherCompSubscription: Subscription;
  triggerGoToSummeryFromAnotherCompSubscription: Subscription;
  selectAllTogglesStateSubscription: Subscription;
  selectOfferDataSubscription: Subscription;
  wantsJustCASCO: any;
  hasOfferForMtpl: boolean;
  hasOfferForCasco: boolean;
  hasActivePolicy: boolean;
  offerExpiredCASCO: boolean;
  offerExpiredMTPL: boolean;
  buttonTypes: OfferButtonTypes;
  showOfferExpiredWarning = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private offerErrors: OfferErrorsService,
    private paymentService: PaymentService) {
    this.triggerJustCASCOFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventJustCASCO().subscribe(() => {
      this.goToJustCASCO();
    });
    this.triggerJustMTPLFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventJustMTPL().subscribe(() => {
      this.validateJustMTPL('rca');
    });
    this.triggerGoToMTPLFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventGoToMTPL().subscribe(() => {
        this.validateWithRCA('rca');
      });
    this.triggerGoToSummeryFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventGoToSummery().subscribe(() => {
      this.goToSummary(this.buttonTypes.paymentAndSummary);
    });
    this.wantsJustCASCO = false;
    this.buttonTypes = new OfferButtonTypes();
  }

  ngOnInit(): void {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        if (params.type) {
          this.type = params.type;
        }
      });
    this.getOfferData();
    this.getActivePolicy();
  }

  getOfferData() {
    this.store.select(selectOfferData).subscribe(
      offerData => {
        if (offerData && Object.keys(offerData).length > 1) {
          this.hasOfferForCasco = offerData.offerForCasco;
          this.hasOfferForMtpl = offerData.offerForMtpl;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.selectOfferDataSubscription) {
      this.selectOfferDataSubscription.unsubscribe();
    }
  }

  get disableBackToCasco() {
    return (JSON.parse(window.sessionStorage.getItem('policyCASCO')) === false &&
        JSON.parse(window.sessionStorage.getItem('justMtplButtonPressed')) === true) ||
      (JSON.parse(window.sessionStorage.getItem('policyCASCO')) === true &&
        JSON.parse(window.sessionStorage.getItem('justMtplButtonPressed')) === false);
  }


  get disableBackJustMTPL() {
    return JSON.parse(window.sessionStorage.getItem('policyMTPL'));
  }

  dateInPast = (firstDate, secondDate) =>
    firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)

  isOfferStillAvailable(buttonPressed: number) {
    const today = new Date();
    // const today = new Date('2028-07-22');
    this.selectOfferDataSubscription = this.store.select(selectOfferData).pipe(take(1)).subscribe(
      offerData => {
        if (buttonPressed === this.buttonTypes.choseRCAAsWell ||
            buttonPressed === this.buttonTypes.justCASCO ||
            buttonPressed === this.buttonTypes.backToCASCO) {
            const cascoStatDate = new Date(offerData.startCascoDateSource);
            if ((this.dateInPast(cascoStatDate, today) === true)) {
              this.offerExpiredCASCO = true;
              this.offerExpiredMTPL = false;
              this.toastrService.error('Te rugăm să verifici mesajele de atenționare din cadrul ofertei care indică ce acțiuni sunt necesare pentru a putea avansa la pasul următor.', '', {
                timeOut: 5000,
              });
              this.store.dispatch(fetchOfferExpired({offerExpired: {casco: this.offerExpiredCASCO, mtpl: this.offerExpiredMTPL}}));
            } else if ((this.dateInPast(cascoStatDate, today) === false)) {
              this.offerExpiredCASCO = false;
              this.offerExpiredMTPL = false;
              this.store.dispatch(fetchOfferExpired({offerExpired: {casco: this.offerExpiredCASCO, mtpl: this.offerExpiredMTPL}}));
            }
        } else if (buttonPressed === this.buttonTypes.paymentAndSummary) {
          const rcaStatDate = new Date(offerData.startMtplDateSource);
          if ((this.dateInPast(rcaStatDate, today) === true)) {
            this.offerExpiredCASCO = false;
            this.offerExpiredMTPL = true;
            this.toastrService.error('Te rugăm să verifici mesajele de atenționare din cadrul ofertei care indică ce acțiuni sunt necesare pentru a putea avansa la pasul următor.', '', {
              timeOut: 5000,
            });
            this.store.dispatch(fetchOfferExpired({offerExpired: {casco: this.offerExpiredCASCO, mtpl: this.offerExpiredMTPL}}));
          } else if ((this.dateInPast(rcaStatDate, today) === false)) {
            this.offerExpiredCASCO = false;
            this.offerExpiredMTPL = false;
            this.store.dispatch(fetchOfferExpired({offerExpired: {casco: this.offerExpiredCASCO, mtpl: this.offerExpiredMTPL}}));
          }
        }
      }
    );
  }


  getActivePolicy() {
    this.store.select(selectMyPolicies).subscribe(rez => {
      if (rez && Object.keys(rez).length > 1) {
        if (rez[`MTPL`][`active`].length > 0) {
          this.hasActivePolicy = true;
        }
      }
    });
  }

  goToJustCASCO() {
    if (this.wantsJustCASCO === true) {
      this.triggerFunctionFromAnotherCompService.sendClickEventCheckIfTermsIsChecked();
      this.triggerFunctionFromAnotherCompService.sendClickEventCheckIfConsultancyCASCOIsChecked();
      this.store.dispatch(toggleJustCASCO({toggleJustCASCO: true}));
      this.store.dispatch(toggleJustMTPL({toggleJustMTPL: false}));
      this.store.dispatch(setSelectedOfferMTPL({offerSelectedMTPL: null}));
      sessionStorage.removeItem('selectedMTPLOffer');
      this.goToSummary(this.buttonTypes.justCASCO);
    }
  }

  validateJustMTPL(type) {
    sessionStorage.setItem('policyMTPL', 'true');
    sessionStorage.setItem('policyCASCO', 'false');
    sessionStorage.setItem('justMtplButtonPressed', 'true');
    this.store.dispatch(toggleJustMTPL({toggleJustMTPL: true}));
    this.store.dispatch(toggleJustCASCO({toggleJustCASCO: false}));
    this.store.dispatch(toggleTermsAndConditions({toggleTermsAndConditions: false}));
    this.store.dispatch(toggleForConsultancyCASCO({wantsConsultancyCASCO: null}));
    this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: null}));
    this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: false}));
    sessionStorage.removeItem('selectedCASCOOffer');
    this.router.navigate(['/offer'], {queryParams: {type}});
  }

  backToCASCO(type: any) {
    sessionStorage.setItem('policyMTPL', 'true');
    sessionStorage.setItem('policyCASCO', 'true');
    sessionStorage.setItem('justMtplButtonPressed', 'false');
    this.store.dispatch(toggleJustMTPL({toggleJustMTPL: false}));
    this.type = type;
    this.router.navigate(['/offer'], {queryParams: {type}});
  }

  validateWithRCA(type) {
    this.isOfferStillAvailable(this.buttonTypes.choseRCAAsWell);
    this.offerErrors.getStatusOffTogglesFormOffer(this.buttonTypes.choseRCAAsWell);
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckSelectedCASCOOffer();
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckIfTermsIsChecked();
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckIfConsultancyCASCOIsChecked();
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckExpiredOffer();
    if (this.offerExpiredCASCO === false && this.offerErrors.getStatusOffTogglesFormOffer(this.buttonTypes.choseRCAAsWell) === true) {
      sessionStorage.setItem('policyMTPL', 'true');
      sessionStorage.setItem('policyCASCO', 'true');
      this.store.dispatch(toggleJustMTPL({toggleJustMTPL: false}));
      this.router.navigate(['/offer'], {queryParams: {type}});
    } else {
      this.toastrService.error('Te rugăm să verifici mesajele de atenționare din cadrul ofertei care indică ce acțiuni sunt necesare pentru a putea avansa la pasul următor.', '', {
        timeOut: 5000,
      });
    }
  }

  goToSummary(fromWhatButton: number) {
    this.isOfferStillAvailable(fromWhatButton);
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckExpiredOffer();
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckSelectedCASCOOffer();
    if (fromWhatButton === 2) {
      this.triggerFunctionFromAnotherCompService.sendClickEventCheckIfConsultancyMTPLIsChecked();
      this.triggerFunctionFromAnotherCompService.sendClickEventCheckSelectedMTPLOffer();
    }
    this.getOfferData();
    const isFormFilled = this.offerErrors.getStatusOffTogglesFormOffer(fromWhatButton);
    if (isFormFilled) {
      this.navigateToOfferSummary();
    } else {
      this.toastrService.error('Te rugăm să verifici mesajele de atenționare din cadrul ofertei care indică ce acțiuni sunt necesare pentru a putea avansa la pasul următor.', '', {
      timeOut: 5000,
      });
      return;
    }
  }

  private navigateToOfferSummary(): void {
    this.loadingJustCASCO = false;
    if (this.offerExpiredMTPL === false && this.offerExpiredCASCO === false) {
      this.store.dispatch(fetchOfferExpiredWarningStatus({warningStatus: false}));
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.paymentService
        .validatePayment()
        .then( _ => {
          this.loading = false;
          this.router.navigate(['/offer-summary']);
        }).catch(error => {
        this.loading = false;
        this.toastrService.error('Ne pare rău, a intervenit o eroare. Te rugăm să ne contactezi la adresa de mail asigurare.cobrand@marsh.com sau la numărul de telefon 021.380 85 53 de luni până vineri în intervalul 9.00-18.00.', '', {
          timeOut: 8000,
        });
      });
    } else {
      if (this.loading) {
        return;
      }
      this.loading = false;
      this.store.dispatch(fetchOfferExpiredWarningStatus({warningStatus: true}));
    }
  }

  get validateJustCASCO() {
    const selectedCascoItem = JSON.parse(sessionStorage.getItem('selectedCASCOOffer'));
    if (selectedCascoItem !== null) {
      this.packType = selectedCascoItem['0'][`packType`];
      this.wantsJustCASCO = this.packType === 'individual';
    }
    return this.wantsJustCASCO;
  }

}
