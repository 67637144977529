import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ApiService} from '../../../../services/api.service';
import {UserRequestResetPasswordModel} from '../../../../models/request-reset-password.model';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-recovery-pass',
  templateUrl: './recovery-pass.component.html',
  styleUrls: ['./recovery-pass.component.scss']
})
export class RecoveryPassComponent implements OnInit {
  emailSent = false;
  public RecoverPasswordForm: FormGroup;
  userRecoverEmail: UserRequestResetPasswordModel;
  errors;
  error24h;
  submit;
  loading = false;

  constructor(
    private form: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.RecoverPasswordForm = this.form.group({
      recoverEmail: ['', [Validators.required, Validators.email]],
    });
  }

  get setPassFormControls() {
    return this.RecoverPasswordForm.controls;
  }

  submitRecoverPasswordForm(tokenRecaptcha) {
    this.loading = true;
    this.userRecoverEmail = new UserRequestResetPasswordModel();
    this.userRecoverEmail.email = this.RecoverPasswordForm.controls.recoverEmail.value;
    // TODO get app id from route
    this.userRecoverEmail.applicationId = environment.app;
    this.userRecoverEmail.objectEnvironment = environment.app ?? '';

    this.apiService.RequestResetPassword(this.userRecoverEmail, tokenRecaptcha).then(r => {
      const resetPassResponse: any = r;
      if (resetPassResponse.statusCode === 500 || resetPassResponse.statusCode === 404) {
        this.errors = true;
        this.loading = false;
        return;
      } else if (resetPassResponse.message === 'Cannot change password sooner than 24 hours'){
        this.error24h = true;
        this.loading = false;
        return;
      } else if (resetPassResponse.error === false){
        this.emailSent = true;
        this.errors = false;
        this.loading = false;
      }
    });
  }

  recoverPass() {
    this.recaptchaV3Service.execute('registerSubmit').subscribe(
      (token) => {
            this.submitRecoverPasswordForm(token);
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
      });
  }

}
