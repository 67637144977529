import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faExclamationCircle, faWindowClose} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-marsh-confirm-number',
  templateUrl: './confirm-user-phone-number.component.html',
  styleUrls: ['./confirm-user-phone-number.component.scss']
})
export class ConfirmUserPhoneNumberComponent implements OnInit {
  faClose = faWindowClose;
  constructor() { }

  ngOnInit() {
  }

  submit(){
  }
}
