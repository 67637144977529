import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalStorageService} from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserLoggedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private localStorageService: LocalStorageService,
  ) {
  }

  canActivate(): boolean {
    const oktaTokenStorage = window.sessionStorage.getItem('okta-token-storage');
    const apigeeTokenStorage = window.sessionStorage.getItem('apigee');
    const userId = window.sessionStorage.getItem('customerId');

    if (oktaTokenStorage && apigeeTokenStorage && userId) {
      this.router.navigate(['/home'], {replaceUrl: true});
      this.ngbModal.dismissAll();
      return false;
    } else {
      this.ngbModal.dismissAll();
      return true;
    }
  }
}
