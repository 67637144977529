import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OfferService} from './offers.service';
import {environment} from '../../environments/environment';
import {AppState, selectActiveCascoAndMTPL} from '../store';
import {select, Store} from '@ngrx/store';
import {catchError, map, take} from 'rxjs/operators';
import {ProductInstallment} from '../models/installment.model';
import {ApiService} from './api.service';
import {setCreditNoteUUID} from '../store/actions/selectedOffer.actions';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public creditNoteId: number = null;
  private productData: ProductInstallment;
  baseUri = environment.apiUrl;
  selectedCascoOffer: any;
  selectedMTPLOffer: any;
  private installment: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private offerService: OfferService,
    private apiService: ApiService
  ) {

  }


  get offerID(): number {
    const offerIdSessionStorage = parseInt(sessionStorage.getItem('offerId'), 10);
    if (offerIdSessionStorage) {
      return offerIdSessionStorage;
    }
    return null;
  }

  private checkAndRemoveP(input) {
    if (input.toString().includes('P')) {

    }
  }

  private get productIds(): number[] {
    const ids: number[] = [];
    if (typeof this.selectedCascoOffer?.gridItemId === 'string') {
      const gridItemId = this.selectedCascoOffer.gridItemId.replace(/P/g, '');
      ids.push(+gridItemId);
    } else {
      ids.push(this.selectedCascoOffer?.gridItemId);
    }
    if (this.selectedMTPLOffer) {
      ids.push(this.selectedMTPLOffer?.productId.toString());
    }
    return ids;
  }

  public getActiveCartItems() {
    return this.store.pipe(
      select(selectActiveCascoAndMTPL),
      take(1)
    );
  }

  preparePayment(uuIDCreditNote) {
    const url = `${this.baseUri}/online/prepare-payment/${uuIDCreditNote}`;
    return this.http.post(url, '').pipe();
  }

  validatePayment(): Promise<void> {
    // console.log('validate payment!');
    return new Promise((resolve, reject) => {
      this.getActiveCartItems().subscribe(cart => {
        // console.log(cart, 'cartcartcartcartcartcartcart');
        this.selectedCascoOffer = cart.casco ? cart.casco[0] : null;
        this.selectedMTPLOffer = cart.mtpl ? cart.mtpl[0] : null;
        if (this.offerID) {
          this.offerService
            .setProductsPull(`${this.offerID}`, {products: this.productIds})
            .subscribe(data => {
              this.store.dispatch(setCreditNoteUUID({setCreditNoteUUID: data.creditNote.uuid}));
              // console.log('data data data tda:', data);
              this.offerService.validateStep(this.offerID, 6).subscribe(dta => {
                // console.log('now it should work', dta);
                this.creditNoteId = data.creditNote.id;
                resolve();
              });
            });
        } else {
          reject({error: 'Offer id is not set'});
        }
      });
    });
  }

  setPaymentRateCreditNoteId(creditNoteId) {
    sessionStorage.setItem('creditNoteId', JSON.stringify(creditNoteId));
  }

  getPaymentRateCreditNoteId() {
    return JSON.parse(sessionStorage.getItem('creditNoteId'));
  }

  validateInstallment(installment) {
    // console.log('validate installment!');
    return new Promise((resolve, reject) => {
      this.installment = installment;
      if (installment.id) {
        this.apiService
          .payInstallment(installment.id)
          .subscribe(data => {
            if (data) {
              this.creditNoteId = data.creditNote;
              this.setPaymentRateCreditNoteId(this.creditNoteId);
              resolve(resolve);
            } else if (this.getPaymentRateCreditNoteId){
              this.creditNoteId = +this.getPaymentRateCreditNoteId;
            }
          });
      } else {
        reject({error: 'Installment not set'});
      }
    });
  }

  getCurrentInstallment() {
    return this.installment;
  }


  getCreditNoteData(IdCreditNoteToken?: number) {
    if (IdCreditNoteToken !== undefined) {
      return this.http.get(`${this.baseUri}/payments/${IdCreditNoteToken}`);
    }
    else if (this.creditNoteId !== null) {
      return this.http.get(`${this.baseUri}/payments/${this.creditNoteId}`);
    } else  {
      return this.http.get(`${this.baseUri}/payments/${this.getPaymentRateCreditNoteId()}`);
    }
  }

  checkNetopiaPaymentConfirmation(IdCreditNoteToken){
    if (IdCreditNoteToken) {
      const url = `${this.baseUri}/payments/${IdCreditNoteToken}`;
      return this.http.get(url).pipe(
        map((res: Response) => {
          return res || {};
        })
      );
    }
  }

  getCreditNotePayRateData(idCreditNote) {
    return this.http.get(`${this.baseUri}/credit_notes/${idCreditNote}`);
  }

  getCreditNote(idCreditNote) {
    return this.http.get(`${this.baseUri}/credit_notes?limit=10&offset=0&cache=0&filter[0]=uuid||$eq||${idCreditNote}`);
  }

  setProductData(productData) {
    this.productData = new ProductInstallment();
    this.productData.dueDate = productData.dueDate;
    this.productData.policyNumber = productData.policy.number;
  }

  getProductData() {
    return this.productData;
  }

  redirectByPost(UUIdPreparePaymentResponse, url): void {
    const form = document.createElement('form');
    // form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.id = 'reg-form';
    Object.keys(UUIdPreparePaymentResponse).forEach(param => {
      const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', UUIdPreparePaymentResponse[param]);
      form.appendChild(mapInput);
    });
    // const envKeyInput = document.createElement('input');
    // envKeyInput.type = 'hidden';
    // envKeyInput.name = 'env_key';
    // envKeyInput.setAttribute('value', UUIdPreparePaymentResponse[`envKey`]);
    // form.appendChild(envKeyInput);
    // document.body.appendChild(form);
    //
    // const dataInput = document.createElement('input');
    // dataInput.type = 'hidden';
    // dataInput.name = 'data';
    // dataInput.setAttribute('value', UUIdPreparePaymentResponse[`envData`]);
    // form.appendChild(dataInput);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
    return;
  }
}
