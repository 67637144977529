import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faExclamationCircle, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserCarDetailsConfig} from '../../../models/user-car-details.model';
import {MonthMapping} from '../../../models/mapping.model';
import {UserCarDetailsService} from '../../services/user-car-details.service';
import { MyCarsService } from 'src/app/shared/services/my-cars.service';
import {UpdateNotificationsService} from '../../../services/update-notifications.service';

@Component({
  selector: 'app-add-licence-plate-number',
  templateUrl: './add-licence-plate-number.component.html',
  styleUrls: ['./add-licence-plate-number.component.scss']
})
export class AddLicencePlateNumberComponent implements OnInit {
  faClose = faWindowClose;
  model: any;
  getNumber: boolean;
  confirmNumber: boolean;
  public SaveRegistrationNumberForm: FormGroup;
  faExclamationCircleIco = faExclamationCircle;
  @Input() public data;
  userCarDetails: UserCarDetailsConfig;
  constructor(
    private modal: NgbModal,
    private userCarDetailsService: UserCarDetailsService,
    private myCarsService: MyCarsService,
    private updateNotificationsService: UpdateNotificationsService,
    public activeModal: NgbActiveModal,
    public form: FormBuilder
  ) {
    this.confirmNumber = false;
    this.getNumber = true;
    this.userCarDetails = this.userCarDetailsService.getConfig();
  }

  ngOnInit() {
    this.model = {
      name: this.data.item.vehicle.make.name,
      model: this.data.item.vehicle.model.name,
      fuel: this.data.item.vehicle.fuel.name,
      policy: this.data.item.vehiclePolicy instanceof Array ?
        this.data.item.vehiclePolicy[0].product.name :
        this.data.item.vehiclePolicy.product.name,
      number: this.data.item.vehiclePolicy instanceof Array ?
        this.data.item.vehiclePolicy[0].number :
        this.data.item.vehiclePolicy.number
    };
    this.SaveRegistrationNumberForm = this.form.group({
      inputLicencePlate: [[this.userCarDetails.nrInmatriculare],
        Validators.compose(
          [Validators.required,
            Validators.pattern('^[A-Z]{2}[0-9]{2}[A-Z]{3}$|^[A-Z]{1}[0-9]{2}[A-Z]{3}$|^[A-Z]{1}[0-9]{3}[A-Z]{3}$|^[A-Z]{2}[0-9]{3}[A-Z]{3}$|^""$')])],
    });
  }

  checkErrors() {
    if (this.SaveRegistrationNumberForm) {
      if (!this.SaveRegistrationNumberForm.controls.inputLicencePlate.touched ||
        this.SaveRegistrationNumberForm.controls.inputLicencePlate.value === '') {
        return false;
      } else {
        return this.SaveRegistrationNumberForm.controls.inputLicencePlate.errors;
      }
    }
  }

  save() {
    const regNumber = {registrationNumber: this.data.item.vehicle.registrationNumber};
    this.myCarsService.addUpdateVehicle(regNumber, this.data.item.vehicle).subscribe(x => {
      if (x) {
        this.updateNotificationsService.sendUpdate(true);
      }
    });
    this.closeModal();
  }

  goToFoolProofNumber() {
    this.confirmNumber = true;
    this.getNumber = false;
  }

  back() {
    this.confirmNumber = false;
    this.getNumber = true;
  }

  closeModal() {
    this.activeModal.close();
  }

  translateFuel(type) {
    if (type === 'diesel') {
      return 'Motorină';
    } else if (type === 'electric') {
      return 'Electrc';
    } else if (type === 'electrichybrid') {
      return 'Hibrid';
    }else if (type === 'gasoline') {
      return 'Benzină';
    }else if (type === 'gpl') {
      return 'GPL';
    }else if (type === 'unknown') {
      return 'Nu avem informația';
    }
  }

  // 0: {value: 14, text: "Motorina", extraData: null, code: "DIESEL"}
  // 1: {value: 16, text: "Electrc", extraData: null, code: "ELECTRIC"}
  // 2: {value: 17, text: "Hibrid", extraData: null, code: "ELECTRICHYBRID"}
  // 3: {value: 13, text: "Benzina", extraData: null, code: "GASOLINE"}
  // 4: {value: 15, text: "GPL", extraData: null, code: "GPL"}
  // 5: {value: 18, text: "unknown", extraData: null, code: "UNKNOWN"}

  getPurchaseDate(date: Date): string {
    return date.getUTCDate() + '-' + MonthMapping[(date.getUTCMonth() + 1)] + '-' + date.getUTCFullYear();
  }

  getCarDetails() {
    // todo: use the model class, translate
    if (this.data) {
      this.userCarDetails.carDetails.sasiu = this.data.item.vehicle.chassis;
      this.userCarDetails.carDetails.marca = this.data.item.vehicle.make.name;
      this.userCarDetails.carDetails.model = this.data.item.vehicle.model.name;
      this.userCarDetails.carDetails.moneda = this.data.item.vehicle.purchaseCurrency.name;
      this.userCarDetails.carDetails.valuareAchizitie =
        this.data.item.vehicle.purchaseValue;
      this.userCarDetails.carDetails.valuareAsigurata =
        this.data.item.vehicle.purchaseValue + this.data.item.vehicle.equipmentValue;
      this.userCarDetails.carDetails.nrLocuri = this.data.item.vehicle.seats;
      this.userCarDetails.carDetails.putereKw = this.data.item.vehicle.engineKw;
      this.userCarDetails.carDetails.combustibil = this.data.item.vehicle.fuel.name;
      this.userCarDetails.carDetails.masaMaxima = this.data.item.vehicle.authorizedWeight;
      this.userCarDetails.nrInmatriculare = this.data.item.vehicle.registrationNumber;
      this.userCarDetails.kilometraj = this.data.item.vehicle.engineKw;
      this.userCarDetails.carDetails.putereKw = this.data.item.vehicle.engineKw;
      this.userCarDetails.carDetails.capacitateMotor = this.data.item.vehicle.engineCapacity.name;
      this.userCarDetails.carDetails.anConstructie = this.data.item.vehicle.buildYear;
      this.userCarDetails.carDetails.dataAchizite = this.getPurchaseDate(new Date(this.data.item.vehicle.purchaseDate));
    }
  }

}
