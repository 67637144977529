import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
// Components Imports
import {FooterComponent} from './components/footer/footer.component';
import {UserModule} from '../pages/user/user.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SidebarAccordionModule} from 'ng-sidebar-accordion';
import {NbCardModule, NbIconModule, NbLayoutModule, NbSpinnerModule} from '@nebular/theme';
import {RegisterComponent} from './components/auth/register/register.component';
import {RecoveryPassComponent} from './components/auth/recovery-pass/recovery-pass.component';
import {ChangePassComponent} from './components/auth/change-pass/change-pass.component';
import {LoginComponent} from './components/auth/login/login.component';
import {CascoMtplPackageModalComponent} from '../pages/offer/offer/casco-mtpl-package-modal/casco-mtpl-package-modal..component';
import {InsuranceConditionsModalComponent} from '../pages/offer/offer/insurance-conditions-modal/insurance-conditions-modal.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ConsultancyComponent} from '../pages/offer/offer/consultancy-info-modal/consultancy.component';
import {DecontareComponent} from '../shared/components/decontare/decontare.component';
import {UnpaidRateOutcomeComponent} from '../pages/payment/unpaid-rate-outcome/unpaid-rate-outcome.component';
import {CancelPolicyConfirmComponent} from '../shared/components/cancel-policy-confirm/cancel-policy.component';
import {ReportWrongDataComponent} from '../shared/components/report-wrong-data/report-wrong-data.component';
import {AddLicencePlateNumberComponent} from '../shared/components/add-licence-plate-number/add-licence-plate-number.component';
import {ConfirmUserPhoneNumberComponent} from '../pages/user/confirm-user-phone-number/confirm-user-phone-number.component';
import { ChangeEmailComponent } from './components/auth/change-email/change-email.component';
import {SharedModule} from '../shared/shared.module';
import {RequestConsultantComponent} from '../shared/components/request-consultant/request-consultant.component';
import {ReactivatePolicyComponent} from '../pages/user/user-policy/reactivate-policy/reactivate-policy.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from '../../environments/environment';
import {TermsWithChaptersComponent} from '../pages/offer/offer/insurance-conditions-modal/terms/terms-with-chapters.component';
import {ConditionsWithChaptersOmniasigComponent} from '../pages/offer/offer/insurance-conditions-modal/terms/conditions-with-chapters-omniasig/conditions-with-chapters-omniasig.component';
import {ConditionsWithChaptersAlianzComponent} from '../pages/offer/offer/insurance-conditions-modal/terms/conditions-with-chapters-alianz/conditions-with-chapters-alianz.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { ChangePassFromMyAccountComponent } from './components/auth/change-pass-from-my-account/change-pass-from-my-account.component';
import { UnsubscribeComponent } from './components/auth/unsubscribe/unsubscribe.component';
import { WarningCascoOfferDetailsComponent } from './components/warning-modals/warning-casco-offer-details/warning-casco-offer-details.component';
import { WarningCascoOfferRoadsideAssistanceComponent } from './components/warning-modals/warning-casco-offer-roadside-assistance/warning-casco-offer-roadside-assistance.component';
import { MarketingAgreementsModalComponent } from './components/auth/register/marketing-agreements-modal/marketing-agreements-modal.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorApiInterceptor} from './interceptors/error-api.interceptor';
import { WarningRcaOfferDetailsComponent } from './components/warning-modals/warning-rca-offer-details/warning-rca-offer-details.component';
import {
  ConditionsWithChaptersGroupamaComponent
} from '../pages/offer/offer/insurance-conditions-modal/terms/conditions-with-chapters-groupama/conditions-with-chapters-groupama.component';
import { WarningChangeConfigurationImplicationsComponent } from './components/warning-modals/warning-change-configuration-implications/warning-change-configuration-implications.component';
import { WarningMtplPreconfigurationDetailsComponent } from './components/warning-modals/warning-mtpl-preconfiguration-details/warning-mtpl-preconfiguration-details.component';
import { WarningChangeConfigurationImplicationsSro11Sro12Component } from './components/warning-modals/warning-change-configuration-implications-sro11-sro12/warning-change-configuration-implications-sro11-sro12.component';
import { WarningChangeConfigurationImplicationsSro26Component } from './components/warning-modals/warning-change-configuration-implications-sro26/warning-change-configuration-implications-sro26.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UserModule,
    NbIconModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    SidebarAccordionModule,
    NbCardModule,
    NbLayoutModule,
    FontAwesomeModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }),
    SharedModule,
    RecaptchaV3Module,
    PdfViewerModule,
    NbSpinnerModule,
  ],
  declarations: [
    FooterComponent,
    LoginComponent,
    CascoMtplPackageModalComponent,
    RegisterComponent,
    RecoveryPassComponent,
    ChangePassComponent,
    ConsultancyComponent,
    DecontareComponent,
    UnpaidRateOutcomeComponent,
    CancelPolicyConfirmComponent,
    RequestConsultantComponent,
    ReactivatePolicyComponent,
    ReportWrongDataComponent,
    AddLicencePlateNumberComponent,
    ConfirmUserPhoneNumberComponent,
    InsuranceConditionsModalComponent,
    ChangeEmailComponent,
    TermsWithChaptersComponent,
    ConditionsWithChaptersOmniasigComponent,
    ConditionsWithChaptersAlianzComponent,
    ConditionsWithChaptersGroupamaComponent,
    ChangePassFromMyAccountComponent,
    UnsubscribeComponent,
    WarningCascoOfferDetailsComponent,
    WarningCascoOfferRoadsideAssistanceComponent,
    MarketingAgreementsModalComponent,
    WarningRcaOfferDetailsComponent,
    WarningChangeConfigurationImplicationsComponent,
    WarningMtplPreconfigurationDetailsComponent,
    WarningChangeConfigurationImplicationsSro11Sro12Component,
    WarningChangeConfigurationImplicationsSro26Component
  ],
  exports: [
    FooterComponent,
    LoginComponent,
    CascoMtplPackageModalComponent,
    LoginComponent,
    RegisterComponent,
    ConsultancyComponent,
    DecontareComponent,
    CancelPolicyConfirmComponent,
    UnpaidRateOutcomeComponent,
    ReportWrongDataComponent,
    AddLicencePlateNumberComponent,
    ConfirmUserPhoneNumberComponent,
    InsuranceConditionsModalComponent,
    TermsWithChaptersComponent,
    ConditionsWithChaptersOmniasigComponent,
    ConditionsWithChaptersAlianzComponent,
    ConditionsWithChaptersGroupamaComponent
  ],
  providers: [
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleRecaptchaSiteKey}
  ],
})
export class CoreModule {
}
