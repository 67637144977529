import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {take} from 'rxjs/operators';
import {AppState, selectPolicyInstallments} from '../../../store';
import {Store} from '@ngrx/store';
import {LocalStorageService} from '../../../services/local-storage.service';
import {PlatformLocation} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-payments',
  templateUrl: './manage-payments.component.html',
  styleUrls: ['./manage-payments.component.scss']
})
export class ManagePaymentsComponent implements OnInit {
  icoToggle = false;
  activeTab: number;
  customerId;
  product = null;
  showPayButton = true;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private myPoliciesService: MyPoliciesService,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private platformLocation: PlatformLocation ,
    private modalService: NgbModal
  ) {
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }

  ngOnInit(): void {
    this.customerId =  window.sessionStorage.getItem('customerId');
    this.platformLocation.onPopState(() => this.modalService.dismissAll());
    this.store.select(selectPolicyInstallments).pipe(take(1)).subscribe(policyInstallments => {
      if (policyInstallments) {
        this.product = policyInstallments;
        this.showPayButton = this.product.seePaymentsCASCO['0']?.daysPassed <= 30;
        this.activeTab = this.product ? this.product[`activeTabSet`] : 0;
      }
      if (this.product === null) {
        this.router.navigate(['/my-policies']);
        return;
      }
    });
  }

  selectTab(tab: any) {
    this.activeTab = tab;
  }

  seePaymentHistory(chassisId, activeTab, costumerId) {
    this.myPoliciesService.seeHistoryPayments(chassisId, activeTab, costumerId);
  }

  changeIcon() {
    this.icoToggle = !this.icoToggle;
  }

  // getDaysElapsedSinceToday(dueDate) {
  //   console.log(moment().diff(dueDate, 'days'));
  //   return moment().diff(dueDate, 'days') !== 30;
  // }

  showRedColor(dueDate) {
    return moment().diff(dueDate, 'days') >= 30 || moment().diff(dueDate, 'days') >= 1;
  }

  setIsOverdueDate(date) {
    const currentDate = new Date();
    const policyDueDate = new Date(date);
    if (currentDate > policyDueDate) {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(true));
    } else {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(false));
    }
  }

  validateInstallment(installment) {
    this.setIsOverdueDate(installment.dueDate);
    this.paymentService
      .validateInstallment(installment)
      .then(_ => {
        this.router.navigate(['/pay-installment']);
      });
    // send policy installment data to service
    this.paymentService.setProductData(installment);
  }

  getPayedInstallmentsNumber(installments: any): number {
    return installments.filter(installment => installment.payment !== null).length;
  }

  getPayedInstallmentsToggleText(installments: any): string {
    let payedInstallmentsNumber: number;
    if (installments){
      payedInstallmentsNumber = this.getPayedInstallmentsNumber(installments);
    }
    const showHideText = this.icoToggle ? 'Ascunde' : 'Vezi';
    if (payedInstallmentsNumber > 1){
      return `${showHideText} ratele 1-${payedInstallmentsNumber}`;
    } else {
      return `${showHideText} rata 1`;
    }
  }

  showPayInstallmentButton(unpayedInstallment: any, installments: any): boolean {
    const lastPayedInstallment = installments.filter(installment => installment.payment !== null).slice(-1)[0];
    if (lastPayedInstallment){
      return lastPayedInstallment.number === unpayedInstallment.number - 1;
    }
  }

}
