import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Utils from "../../../../shared/utils/utils";
import {WantConsultancyCascoComponent} from '../want-consultancy-casco/want-consultancy-casco.component';
import {SendReciveConsultancyService} from '../../../../services/send-recive-consultancy.service';
import {toggleForConsultancyCASCO, toggleForConsultancyMTPL} from '../../../../store/actions/offerToggle.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store';

@Component({
  selector: 'app-marsh-consultancy',
  templateUrl: './consultancy.component.html',
  styleUrls: ['./consultancy.component.scss']
})
export class ConsultancyComponent implements OnInit {
  faClose = faWindowClose;

  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private activeModal: NgbActiveModal) {
  }

  @Input() fromPage;
  isMobile: any;

  setData(value) {
    if (this.fromPage === 'casco') {
      this.store.dispatch(toggleForConsultancyCASCO({wantsConsultancyCASCO: value}));
      this.activeModal.close();
    } else {
      this.store.dispatch(toggleForConsultancyMTPL({wantsConsultancyMTPL: value}));
      this.activeModal.close();
    }

  }

  ngOnInit() {
    this.isMobile = Utils.checkIfMobile();
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
