import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {logger} from 'codelyzer/util/logger';
import {UserPreValidateModel} from '../../../../models/user-register.model';
import {SetPreValidateUserDetails} from '../../../../store/actions/auth.actions';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  sub: Subscription;
  subParams: Subscription;
  email;
  type;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.email = atob(this.activatedRoute.snapshot.params.base64Email);
    this.type = atob(this.activatedRoute.snapshot.params.base64Type);
    if (!this.email && !this.type){
      this.router.navigate([`/login`]);
    }
    this.unsubscribeFromEmailNotifications(this.email, this.type);
  }

  unsubscribeFromEmailNotifications(email, type) {
    const data = {email, type};
    this.recaptchaV3Service.execute('preValidateSubmit').subscribe(
      (token) => {
        this.apiService.unsubscribeNotificationFromEmail(data, token).then( resUnsubscribeNotificationEmail => {
          // console.log(resUnsubscribeNotificationEmail, 'resUnsubscribeNotificationEmail');
        });
      },
      (error) => {
      });
  }

  get notificationTypeName() {
    if (this.type === 'offers'){
      return 'oferte';
    }
    if (this.type === 'installments'){
      return 'rate';
    }
    if (this.type === 'others') {
      return 'administrative';
    } else {
      return this.type;
    }
  }

}
