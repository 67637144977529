import {Component, OnInit} from '@angular/core';
import {AppState, selectPoliciesToDownload} from '../../../store';
import {Store} from '@ngrx/store';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {ApiService} from '../../../services/api.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-download-bought-offer',
  templateUrl: './download-bought-offer.component.html',
  styleUrls: ['./download-bought-offer.component.scss'],
})
export class DownloadBoughtOfferComponent implements OnInit {
  dataPoliciesToDownloadContent: any;
  policiesIds;
  customerId;
  loadingCASCO;
  loadingMTPL;
  error;
  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private router: Router,
    private policyService: MyPoliciesService,
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.customerId = window.sessionStorage.getItem('customerId');
    this.store.select(selectPoliciesToDownload).subscribe(policyIds => {
      if (policyIds) {
        this.policiesIds = policyIds;
        this.dataPoliciesToDownloadContent = this.policyService.getPoliciesToDownloadContent(this.policiesIds, this.customerId);
      } else {
       this.router.navigate(['/home']);
      }
    });
    this.apiService.resetOfferSessionStorageAndStore();
  }

  downloadBoughtPolicy(itemToDownload, type) {
    if (type === 'CASCO') {
      this.loadingCASCO = true;
      this.apiService.downloadCertificatePdf(itemToDownload.id).subscribe((data) => {
        if (data[`statusCode`] === 500) {
          this.loadingCASCO = false;
          this.error = true;
        } else {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = itemToDownload.vehicle.chassis + '_' + itemToDownload.product.type.code + '.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.loadingCASCO = false;
        }

      });
    } else {
      this.loadingMTPL = true;
      this.apiService.downloadCertificatePdf(itemToDownload.id).subscribe((data) => {
        if (data[`statusCode`] === 500) {
          this.loadingMTPL = false;
          this.error = true;
        } else {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = itemToDownload.vehicle.chassis + '_' + itemToDownload.product.type.code + '.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.loadingMTPL = false;
        }

      });
    }

  }

}
