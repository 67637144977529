import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {UserChangePasswordModel} from '../../../../models/change-password.model';
import {ApiService} from '../../../../services/api.service';
import {CustomValidators} from '../register/validation';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  public ChangePasswordForm: FormGroup;
  errors;
  loading = false;
  submitted = false;
  firstNameLowerValidation: boolean;
  firstNameValidation: boolean;
  lastNameValidation: boolean;
  lastNameLowerValidation: boolean;
  userNameValidation: boolean;
  userNameLowerValidation: boolean;
  userChangePassword: UserChangePasswordModel;
  userName;
  userNameLower;
  firstName;
  firstNameLower;
  lastName;
  lastNameLower;
  messageBackEnd;
  sub: Subscription;
  emailArray;
  emailArrayParse;

  constructor(
    private form: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.ChangePasswordForm = this.form.group({
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  public changePassForm(): void {
    this.loading = true;
    this.recaptchaV3Service.execute('sendChangePass').subscribe(
      (captchaToken) => {
        this.userChangePassword = new UserChangePasswordModel();
        this.userChangePassword.newPassword = this.ChangePasswordForm.controls.password.value;
        this.userChangePassword.email = atob(this.activatedRoute.snapshot.params.base64Email);
        this.userChangePassword.emailToken = this.activatedRoute.snapshot.params.token;
        this.userChangePassword.objectEnvironment = environment.app ?? '';

        this.apiService.ChangeUserPassword(this.userChangePassword, captchaToken).then(result => {
          const setPassRequestRez: any = result;
          if (setPassRequestRez.error === false) {
            this.loading = false;
            this.router.navigate(['/login']);
          } else if (setPassRequestRez.message === 'UserExist' || setPassRequestRez.status === 409){
            this.loading = false;
            this.messageBackEnd = 'Pentru acest user ai creat deja cont. Te rog sa accesezi acest link <a\n' +
              '            href="https://asigurari.dacia.ro/login">asigurari.dacia.ro/login</a> pentru a te loga in aplicatie';
          } else {
            this.loading = false;
            this.messageBackEnd = setPassRequestRez.error.message;
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
        this.loading = false;
      });
  }

  validateChangePass(inputChangePass) {
    this.emailArray = atob(this.activatedRoute.snapshot.params.array);
    this.emailArrayParse = JSON.parse(this.emailArray);
    this.userName = this.emailArrayParse[0];
    this.userNameLower = this.emailArrayParse[0];
    this.lastName = this.emailArrayParse[1];
    this.lastNameLower = this.emailArrayParse[1];
    this.firstName = this.emailArrayParse[2];
    this.firstNameLower = this.emailArrayParse[2];
    const valueOfInputChangePass = inputChangePass.target.value;
    this.userNameValidation = valueOfInputChangePass.includes(this.userName);
    this.userNameLowerValidation = valueOfInputChangePass.includes(this.userNameLower.toLowerCase());
    this.lastNameValidation = valueOfInputChangePass.includes(this.firstName);
    this.lastNameLowerValidation = valueOfInputChangePass.includes(this.firstNameLower.toLowerCase());
    this.firstNameValidation = valueOfInputChangePass.includes(this.lastName);
    this.firstNameLowerValidation = valueOfInputChangePass.includes(this.lastNameLower.toLowerCase());
  }

  get setPassFormControls() {
    return this.ChangePasswordForm.controls;
  }
}
