import { Injectable } from '@angular/core';
import {InsuranceTableConfig} from '../../models/casco-table.model';

@Injectable({
  providedIn: 'root'
})
export class CascoOfferService {
  private readonly config: InsuranceTableConfig;
  constructor() {
    this.config = {
      allianz: {
        cascoProviderName: 'Allianz',
        goldOffers: {
          offerBracket: 'gold',
          goldOfferOne: '100 EUR/an',
          goldRateOne: 'rata 1: 30 EUR',
          goldOfferTwo: '90 EUR/an',
          goldRateTwo: 'rata 1: 20 EUR',
        },
        silverOffers: {
          offerBracket: 'silver',
          silverOfferOne: '90 EUR/an',
          silverRateOne: 'rata 1: 20 EUR',
          silverOfferTwo: '80 EUR/an',
          silverRateTwo: 'rata 1: 18 EUR',
        },
        package: {
          cascoProviderName: 'Allianz',
          nameFirstPackage: 'Pachet CASCO Gold + RCA',
          savingsFirstSum: 1210,
          nameSecondPackage: 'Pachet CASCO Silver + RCA',
          savingsSecondSum: 1020,
          withoutPackSum12Months: 700,
          withPackSum12Months: 8050,
          withoutPackSum6Months: 4200,
          withPackSum6Months: 4520,
        },
        termsAndCondText: {
          conditionText: 'i agree'
        }
      },
      groupama: {
        cascoProviderName: 'Groupama',
        goldOffers: {
          offerBracket: 'gold',
          goldOfferOne: '100 EUR/an',
          goldRateOne: 'rata 1: 30 EUR',
          goldOfferTwo: '90 EUR/an',
          goldRateTwo: 'rata 1: 20 EUR',
        },
        silverOffers: {
          offerBracket: 'silver',
          silverOfferOne: '90 EUR/an',
          silverRateOne: 'rata 1: 20 EUR',
          silverOfferTwo: '80 EUR/an',
          silverRateTwo: 'rata 1: 18 EUR',
        },
        package: {
          cascoProviderName: 'Groupama',
          nameFirstPackage: 'Pachet CASCO Gold + RCA',
          savingsFirstSum: 120,
          nameSecondPackage: 'Pachet CASCO Silver + RCA',
          savingsSecondSum: 20,
          withoutPackSum12Months: 7050,
          withPackSum12Months: 800,
          withoutPackSum6Months: 420,
          withPackSum6Months: 452,
        },
        termsAndCondText: {
          conditionText: 'i agree t'
        }
      },
      omniasig: {
        cascoProviderName: 'Omniasig',
        goldOffers: {
          offerBracket: 'gold',
          goldOfferOne: '100 EUR/an',
          goldRateOne: 'rata 1: 30 EUR',
          goldOfferTwo: '90 EUR/an',
          goldRateTwo: 'rata 1: 20 EUR',
        },
        silverOffers: {
          offerBracket: 'silver',
          silverOfferOne: '90 EUR/an',
          silverRateOne: 'rata 1: 20 EUR',
          silverOfferTwo: '80 EUR/an',
          silverRateTwo: 'rata 1: 18 EUR',
        },
        package: {
          cascoProviderName: 'Omniasig',
          nameFirstPackage: 'Pachet CASCO Gold + RCA',
          savingsFirstSum: 1210,
          nameSecondPackage: 'Pachet CASCO Silver + RCA',
          savingsSecondSum: 100,
          withoutPackSum12Months: 750,
          withPackSum12Months: 800,
          withoutPackSum6Months: 400,
          withPackSum6Months: 45,
        },
        termsAndCondText: {
          conditionText: 'i agree '
        }
      },
    };
  }

  public getConfig() {
    return this.config;
  }
}
