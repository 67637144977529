export interface StateSpinner {
  isOn: boolean;
}

export const initialState: StateSpinner = {
  isOn: null,
};

export function reducer(stateSpinner = { isOn: false }, action): StateSpinner {
  switch (action.type) {
    case 'startSpinner': {
      return {
        isOn: true
      };
    }

    case 'stopSpinner': {
      return {
        isOn: false
      };
    }

    default:
      return stateSpinner;
  }
}
