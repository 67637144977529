import {CONSTRUCTOR} from '@angular/compiler-cli/ngcc/src/host/esm2015_host';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {FormBuilder} from '@angular/forms';

export class UserPreValidateModel{
  offerToken?: string;
  offerNumber: number;
  phoneNumber: string;
}

export class UserConfirmEmailModel{
  offerToken?: string;
  email: string;
  offerNumber: number;
  terms: boolean;
  gdpr: boolean;
  objectEnvironment: string;
}

export class UserSendPinFromEmailModel{
  offerToken?: string;
  offerNumber: number;
  pin: number;
}

export class UserRegisterPasswordModel{
  email: string;
  password: string;
  terms: boolean;
  gdpr: boolean;
  customerId: number;
  marketingCheckbox: boolean;
  canCommunicateByEmail?: boolean;
  canCommunicateByPhone?: boolean;
  canCommunicateByPostalService?: boolean;
  canCommunicateBySms?: boolean;
  termMarketing?: boolean;
}

export class UserChangePasswordModel{
  token: string;
  password: string;
}
