import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { OfferService } from 'src/app/services/offers.service';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import Utils from '../../../../shared/utils/utils';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-marsh-reactivate-policy',
  templateUrl: './reactivate-policy.component.html',
  styleUrls: ['./reactivate-policy.component.scss']
})
export class ReactivatePolicyComponent implements OnInit {
  warningTypesEnums = WarningTypeEnum;
  isMobile: boolean;
  policyType: string;
  policyNumber: string;
  customerFullName: string;
  customerId: number;
  errors: string;

  constructor(private modal: NgbModal, private offerService: OfferService) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
  }

  reportData(){
    if (this.policyNumber){
      const data = {
        objectId: this.policyNumber,
        objectType: 'policy reactivation',
        text: `User with id ${this.customerId} wants to reactivate policy number ${this.policyNumber}`,
        objectEnvironment: environment.app
      };
      this.offerService.reportIncorrectData(data).subscribe(result => {
        if (result){
          if (result.error === true){
            this.errors = result.message;
          }
          else{
            this.errors = '';
            this.closeModal();
          }
        }
      });
    }
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
