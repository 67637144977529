import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './core/components/auth/login/login.component';
import {AboutUsComponent} from './shared/components/footer-links/about-us/about-us.component';
import {ContactComponent} from './shared/components/footer-links/contact/contact.component';
import {PetitionsComponent} from './shared/components/footer-links/petitions/petitions.component';
import {PIDComponent} from './shared/components/footer-links/pid/pid.component';
import {ProductsComponent} from './shared/components/footer-links/products/products.component';
import {TermsConditionsComponent} from './shared/components/footer-links/terms-conditions/terms-conditions.component';
import {PoliciesListComponent} from './pages/user/user-policy/policies-list/policies-list.component';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {OfferComponent} from './pages/offer/offer/offer.component';
import {RegisterComponent} from './core/components/auth/register/register.component';
import {RecoveryPassComponent} from './core/components/auth/recovery-pass/recovery-pass.component';
import {ChangePassComponent} from './core/components/auth/change-pass/change-pass.component';
import {OfferSummaryComponent} from './pages/offer/offer-summary/offer-summary.component';
import {DownloadBoughtOfferComponent} from './pages/offer/download-bought-offer/download-bought-offer.component';
import {PaymentConfirmedComponent} from './pages/payment/payment-confirmed/payment-confirmed.component';
import {ProgressModalComponent} from './shared/components/progress-modal/progress-modal.component';
import {ManagePaymentsComponent} from './pages/payment/manage-payments/manage-payments.component';
import {ReportWrongDataComponent} from './shared/components/report-wrong-data/report-wrong-data.component';
import {UserDetailsComponent} from './pages/user/user-details/user-details.component';
import {ChangeEmailComponent} from './core/components/auth/change-email/change-email.component';
import {environment} from 'src/environments/environment';
import {OktaAuthGuard, OktaAuthModule, OKTA_CONFIG} from '@okta/okta-angular';
import {SessionDataGuard} from './shared/guard/session-data.guard';
import {PaymentHistoryComponent} from './pages/payment/manage-payments/payment-history/payment-history.component';
import {UserCarSelectorComponent} from './pages/user/user-car-selector/user-car-selector.component';
import {CookiePolicyComponent} from './shared/components/footer-links/cookie-policy/cookie-policy.component';
import {UnsubscribeComponent} from './core/components/auth/unsubscribe/unsubscribe.component';
import {ChangePassFromMyAccountComponent} from './core/components/auth/change-pass-from-my-account/change-pass-from-my-account.component';
import {PaymentConfirmationComponent} from './pages/offer/payment-confirmation/payment-confirmation.component';
import {PayInstallmentComponent} from './pages/payment/manage-payments/pay-installment/pay-installment.component';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {UserLoggedInGuard} from './shared/guard/user-logged-in.guard';
import {GdprComponent} from './shared/components/footer-links/gdpr/gdpr.component';
import {
  FrequentQuestionsComponent
} from './shared/components/footer-links/frequent-questions/frequent-questions.component';

const oktaConfig = {
  issuer: `${environment.oktaOrgURL}`,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: environment.oktaClientId,
  pkce: true,
  storage: `sessionStorage`,
};

const tabName = {
  name: `${environment.name}`
};
export function onAuthRequired(oktaAuth, injector) {

  injector.navigate(['/loginOkta']);
}

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/home',
  //   pathMatch: 'full',
  //   data: {
  //     title: environment.name
  //   }
  // },
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [UserLoggedInGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'login',
    canActivate: [UserLoggedInGuard],
    component: LoginComponent,
    data: {
      title: environment.name
    }
  },
  // SessionDataGuard Looks for this.localStorage.getData('okta-cache-storage') if ! then loginOkta
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [UserLoggedInGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'recover-pass',
    component: RecoveryPassComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'reset-password/:token/:base64Email/:array',
    component: ChangePassComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'change-pass-my-account',
    component: ChangePassFromMyAccountComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'change-email',
    component: ChangeEmailComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'unsubscribe/:base64Email/:base64Type',
    component: UnsubscribeComponent,
    data: {
      title: environment.name
    }
  },

  // Footer links
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'gdpr',
    component: GdprComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'petitions',
    component: PetitionsComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'pid',
    component: PIDComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: {
      title: environment.name
    }
  },

  // Offer
  {
    path: 'offer',
    component: OfferComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'offer-summary',
    component: OfferSummaryComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'offer-summary/:id',
    component: OfferSummaryComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'payment-confirmation/:orderId',
    component: PaymentConfirmationComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'offer-download',
    component: DownloadBoughtOfferComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'frequent-questions',
    component: FrequentQuestionsComponent,
    data: {
      title: environment.name
    }
  },
  {
    path: 'progress',
    component: ProgressModalComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },

  // Payment
  {
    path: 'payment-confirmed',
    component: PaymentConfirmedComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },

  // Policy
  {
    path: 'my-policies',
    component: PoliciesListComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'report-wrong-data',
    component: ReportWrongDataComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },

  // Manage Payments
  {
    path: 'payments',
    component: ManagePaymentsComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'pay-installment',
    component: PayInstallmentComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'payment-history',
    component: PaymentHistoryComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'user-details',
    component: UserDetailsComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },
  {
    path: 'car-select',
    component: UserCarSelectorComponent,
    canActivate: [SessionDataGuard],
    data: {
      title: environment.name
    }
  },

  // Handler Pages
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: environment.name
    }
  }
];

@NgModule({
  imports:
    [RouterModule.forRoot(routes,
      {
        onSameUrlNavigation: 'reload',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
      }),
      OktaAuthModule],
  exports: [RouterModule],
  providers: [
    {provide: OKTA_CONFIG, useValue: oktaConfig},
    OktaAuthGuard
  ]
})
export class AppRoutingModule {
}
