import { Component, Input, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../../environments/environment';
import Utils from '../../../../shared/utils/utils';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-offer-details-popup',
  templateUrl: './offer-details-popup.component.html',
  styleUrls: ['./offer-details-popup.component.scss']
})
export class OfferDetailsPopupComponent implements OnInit {

  appType = environment.app;
  faClose = faWindowClose;
  isMobile: boolean;
  constructor(
    private modal: NgbModal,) {
    this.isMobile = Utils.checkIfMobile();
  }
  @Input() public content = {
    totalPremiumWithout12:'',
      totalPremiumWithout6:'',
      totalPremiumWithoutDS12:'',
      totalPremiumWithoutDS6:'',
      netPremium12:'',
      netPremium6:'',
      brokerageCommission12:'',
      brokerageCommission6:'',
      brokerageCommissionAmount12:'',
      brokerageCommissionAmount6:'',
      brokerageCommissionAmountDS12:'',
      brokerageCommissionAmountDS6:'',
      totalPremiumWith12:'',
      totalPremiumWith6:'',
      referencePremium12:'',
      referencePremium6:'',
      bonusMalus12:'',
      bonusMalus6:''
  };
  ngOnInit(): void {   
  } 
 

  closeModal(){
    this.modal.dismissAll();
  }
}
