import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserChangePasswordFromMyAccountModel} from '../../../../models/change-password.model';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {CustomValidators} from '../register/validation';
import {AppState} from '../../../../store';
import {Store} from '@ngrx/store';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-change-pass-from-my-account',
  templateUrl: './change-pass-from-my-account.component.html',
  styleUrls: ['./change-pass-from-my-account.component.scss']
})
export class ChangePassFromMyAccountComponent implements OnInit, OnDestroy {
  public ChangePasswordForm: FormGroup;
  errors;
  loading = false;
  recaptchaV3ServiceSubscription: Subscription;
  changeUserPasswordMyAccountSubscription: Subscription;
  logoutSessionSubscription: Subscription;
  firstNameLowerValidation: boolean;
  firstNameValidation: boolean;
  lastNameValidation: boolean;
  lastNameLowerValidation: boolean;
  userNameValidation: boolean;
  userNameLowerValidation: boolean;
  userName;
  userNameLower;
  firstName;
  firstNameLower;
  lastName;
  lastNameLower;
  userChangePassword: UserChangePasswordFromMyAccountModel;
  messageBackEnd;
  dataCustomer;
  dataCustomerLocal;
  constructor(
    private form: FormBuilder,
    private store: Store<AppState>,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.dataCustomerLocal = window.sessionStorage.getItem('fullName');

    this.ChangePasswordForm = this.form.group({
        oldPassword: [null, Validators.required],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            })
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  ngOnDestroy() {
    if (this.recaptchaV3ServiceSubscription) {
      this.recaptchaV3ServiceSubscription.unsubscribe();
    }
    if (this.changeUserPasswordMyAccountSubscription) {
      this.changeUserPasswordMyAccountSubscription.unsubscribe();
    }
    if (this.logoutSessionSubscription) {
      this.logoutSessionSubscription.unsubscribe();
    }
  }

  public changePassFromMyAccount(): void {
    this.loading = true;
    this.recaptchaV3ServiceSubscription = this.recaptchaV3Service.execute('changePassFromMyAccount').subscribe(
      (captchaToken) => {
        this.userChangePassword = new UserChangePasswordFromMyAccountModel();
        this.userChangePassword.newPassword = this.ChangePasswordForm.controls.password.value;
        this.userChangePassword.oldPassword = this.ChangePasswordForm.controls.oldPassword.value;
        this.userChangePassword.objectEnvironment = environment.app ?? '';

        this.changeUserPasswordMyAccountSubscription =
          this.apiService.ChangeUserPasswordMyAccount(this.userChangePassword).subscribe(result => {
          const setPassRequestRez: any = result;
          if (setPassRequestRez.error === false) {
            this.loading = false;
            this.logOut();
          } else {
            if (setPassRequestRez.message === 'Forbidden: Password change not allowed'){
              this.messageBackEnd = 'Parola nu a putut fi schimbata!';
              this.loading = false;
            } else if (setPassRequestRez.message === 'Forbidden: Does not meet password rules.') {
              this.messageBackEnd = 'Parola nu indeplineste toate regulile!';
              this.loading = false;
            } else {
              this.messageBackEnd = setPassRequestRez.message;
              this.loading = false;
            }
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3)'];
        this.loading = false;
      });
  }


  validateChangePass(inputChangePass) {
    this.dataCustomer = this.dataCustomerLocal.slice(1, -1);
    this.dataCustomer = this.listToArray(this.dataCustomerLocal, ' ');
    this.userName = this.dataCustomer[0].slice(1);
    this.userNameLower = this.dataCustomer[0].slice(1);
    this.firstName = this.dataCustomer[2];
    this.firstNameLower = this.dataCustomer[2];
    this.lastName = this.dataCustomer[1];
    this.lastNameLower = this.dataCustomer[1];
    const valueOfInputChangePass = inputChangePass.target.value;
    this.userNameValidation = valueOfInputChangePass.includes(this.userName);
    this.userNameLowerValidation = valueOfInputChangePass.includes(this.userNameLower.toLowerCase());
    this.lastNameValidation = valueOfInputChangePass.includes(this.firstName);
    this.lastNameLowerValidation = valueOfInputChangePass.includes(this.firstNameLower.toLowerCase());
    this.firstNameValidation = valueOfInputChangePass.includes(this.lastName);
    this.firstNameLowerValidation = valueOfInputChangePass.includes(this.lastNameLower.toLowerCase());
  }

  listToArray(fullString, separator) {
    let fullArray = [];

    if (fullString !== undefined) {
      if (fullString.indexOf(separator) == -1) {
        fullArray.push(fullString);
      } else {
        fullArray = fullString.split(separator);
      }
    }

    return fullArray;
  }

  logOut() {
    this.logoutSessionSubscription = this.apiService.logoutSession().subscribe(() => {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
      location.reload();
    });
  }
}
