import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {AppStorageService} from './app-storage.service';
import {map} from 'rxjs/operators';
import {ApigeeService} from './apigee.service';

@Injectable()
export class DropDownDataService {
  private BASE_URL = environment.apiUrl + '/data-sources/';

  constructor(
    private http: HttpClient,
  ) {
  }

  fetchData(action: string = '', data: string = '', parent?: number, take?: number, selectedValue?: number) {
    return this.http.post<any[]>(
      this.BASE_URL, {area: action, searchString: data, parent, take, selectedValue}
    ).pipe(
      map((res) => {
        return res || [];
      })
    );
  }
}

