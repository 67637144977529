import {Component, OnInit} from '@angular/core';
import {UserCarDetailsComponent} from '../user-car-details/user-car-details.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState, selectMyCars } from 'src/app/store';

@Component({
  selector: 'app-user-car-selector',
  templateUrl: './user-car-selector.component.html',
  styleUrls: ['./user-car-selector.component.scss']
})
export class UserCarSelectorComponent implements OnInit {
  allCustomerCars;
  gotAllVehicles = false;
  noVehicleFound = false;
  constructor(
    private modal: NgbModal,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.gotAllVehicles = false;
    this.noVehicleFound = false;
    this.getCustomerVehicles();
  }

  private getCustomerVehicles(){
    this.store.select(selectMyCars).subscribe(result => {
      if (result && result.length > 0){
        this.allCustomerCars = result;
        this.gotAllVehicles = true;
        this.noVehicleFound = false;
      } else if (result && result.length === 0){
        this.gotAllVehicles = true;
        this.noVehicleFound = true;
      } else {
        this.gotAllVehicles = false;
        this.noVehicleFound = false;
      }
    });
  }

  detailPressed(carItem) {
    const modalRef = this.modal.open(UserCarDetailsComponent as Component, {
      size: 'lg', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.modal = false;
    modalRef.componentInstance.fromCarDetail = true;
    modalRef.componentInstance.offer = null;
    modalRef.componentInstance.vehicleItem = carItem;
  }

}
