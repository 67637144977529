import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-progress',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss']
})
export class ProgressModalComponent implements OnInit{
  animationDuration = 5000;
  continuousLoading = false;

  constructor(
    private ngbModal: NgbModal,
  ) {
  }

  ngOnInit() {
    if (this.continuousLoading === true){
      setTimeout( () => {
        location.reload();
        this.ngbModal.dismissAll();
      } , this.animationDuration);
    }
  }

}
