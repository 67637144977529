import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import { environment } from '../../environments/environment';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable()
export class ApigeeService {

  apigeeToken;

  constructor(
    private appStorageService: AppStorageService,
    private oktaAuthService: OktaAuthService,
  ) {
  }

  private hasValidAccessToken(): boolean {
    if (!this.apigeeToken) {
      return false;
    }
    const expires = (this.apigeeToken.issuedAt + this.apigeeToken.expiresIn) - 60000; // 60 seconds before it expires
    return Date.now() < expires;
  }

  async getToken(who = null) {
    if (!this.apigeeToken || !this.hasValidAccessToken()) {
      this.apigeeToken = await this.requestToken();
    }
    return (this.apigeeToken ? this.apigeeToken.accessToken : null);

  }

  async getTokenObject() {
    if (!this.apigeeToken || !this.hasValidAccessToken()) {
      this.apigeeToken = await this.requestToken();
    }
    return (this.apigeeToken ? this.apigeeToken : null);
  }

  public async requestToken() {
    const oktaToken = await this.oktaAuthService.getAccessToken();

    let apigeeToken = null;
    const options = {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + oktaToken },
    };
    const url = environment.apigeeUrl + `/api-gateway-access-token`;

    if (oktaToken) {
      return new Promise((res, rej) => {
        fetch(url, options)
          .then(response => response.json())
          .then((data) => {
            if (data.statusCode && data.statusCode !== 200) {

            } else {
              apigeeToken = data['data']['apiGatewayAccessToken'];
              this.apigeeToken = apigeeToken;
            }
            res(apigeeToken);
          });
        // .catch((e) => {
        //   console.log('get apigee token = ' + e);
        // });
      });
    } else {
      return null;
    }
  }

  public async requestTokenApiGee(oktaToken) {

    let apigeeToken = null;
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + oktaToken
      },
    };
    const url = environment.apigeeUrl + `/api-gateway-access-token`;

    if (oktaToken) {
      return new Promise((res, rej) => {
        fetch(url, options)
          .then(response => response.json())
          .then((data) => {
            if (data.statusCode && data.statusCode !== 200) {

            } else {
              apigeeToken = data[`data`][`apiGatewayAccessToken`];
              this.apigeeToken = apigeeToken;
            }
            res(apigeeToken);
          });
        // .catch((e) => {
        //   console.log('get apigee token = ' + e);
        // });
      });
    } else {
      return null;
    }
  }
}
