import {Component, OnDestroy, OnInit} from '@angular/core';
import { PaymentConfirmed } from '../../../models/payment-confirmed.model';
import {Store} from '@ngrx/store';
import {AppState, selectMyPolicies, selectPolicyPaymentConfirmationData} from '../../../store';
import {ToastrService} from 'ngx-toastr';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {ActivatedRoute, Router} from '@angular/router';
import {fetchPolicyInstallmentsSuccess} from '../../../store/actions/policies.actions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-payment-confirmed',
  templateUrl: './payment-confirmed.component.html',
  styleUrls: ['./payment-confirmed.component.scss']
})
export class PaymentConfirmedComponent implements OnInit, OnDestroy{
  selectMyPoliciesSub: Subscription;
  getPoliciesInstallmentSub: Subscription;
  MTPLPoliciesAndInstallment: any;
  MTPLPolicies: any;
  CASCOPolicies: any;
  CASCOPoliciesAndInstallment: any;
  customerId;
  activeTab;
  chassisId;
  paymentConfirmed = new PaymentConfirmed();
  firstNull = false;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private myPolicyService: MyPoliciesService,
  ) {
    this.CASCOPolicies = [];
    this.MTPLPolicies = [];
    this.MTPLPoliciesAndInstallment = [];
    this.CASCOPoliciesAndInstallment = [];
  }

  ngOnInit(): void {
    this.customerId = JSON.parse(sessionStorage.getItem('customerId'));
    this.activatedRoute.params.subscribe(params => {
      // sessionStorage.setItem('paymentRateId', params);
    });

    this.selectMyPoliciesSub = this.store.select(selectMyPolicies).subscribe(rez => {
      if (rez && Object.keys(rez).length > 1) {
        if (rez[`MTPL`][`active`].length > 0) {
          this.MTPLPolicies.push(rez[`MTPL`][`active`]);
        }

        if (rez[`MTPL`][`other`].length > 0) {
          this.MTPLPolicies.push(rez[`MTPL`][`other`]);
        }

        if (rez[`CASCO`][`active`].length > 0) {
          this.CASCOPolicies.push(rez[`CASCO`][`active`]);
        }

        if (rez[`CASCO`][`other`].length > 0) {
          this.CASCOPolicies.push(rez[`CASCO`][`other`]);
        }

        if (this.checkForPolicies) {
          setTimeout(() => {
            this.policyInstallmentMTPL();
            this.policyInstallmentCASCO();
          });
        }

      }
    });

    // http://localhost:5000/payment-confirmation/2a4604f9-2f6a-4628-8b98-4992b4673c20?&orderId=2a4604f9-2f6a-4628-8b98-4992b4673c20
    this.store.select(selectPolicyPaymentConfirmationData).subscribe( x => {
      if (x) {
        this.myPolicyService.getPoliciesInstallment(x.policy.id).subscribe( installments => {
          if (installments) {
            installments.forEach( item => {
              if (item.payment === null && this.firstNull === false) {
                this.paymentConfirmed.dueDate = item.dueDate;
                if (item.policy.product.type.code === 'CASCO') {
                  this.activeTab = 0;
                } else {
                  this.activeTab = 1;
                }
                this.firstNull = true;
              }
            });
          }
        });
        this.myPolicyService.getPolicies(this.customerId).subscribe(allPolicies => {
          if (allPolicies) {
            const currentPolicy = allPolicies.data.filter(policy => policy.number === this.paymentConfirmed.nrPolita);
            if (currentPolicy) {
              this.chassisId = currentPolicy['0'].vehicle.chassis;
            }
          }
        });
        this.paymentConfirmed.rata = `Rata ${x.installmentNumber} pentru ${x.offerProduct.product.name} ${x.offerProduct.product.insurer.name}`;
        this.paymentConfirmed.nrPolita = `${x.policy.number}`;
        this.paymentConfirmed.EUR = x.value;
        this.paymentConfirmed.lei = x.dueValue;
        this.paymentConfirmed.date = x.createdOn;
      }
    });
  }

  get checkForPolicies() {
    return !!this.CASCOPolicies;
  }

  ngOnDestroy() {
    if (this.getPoliciesInstallmentSub) {
      this.getPoliciesInstallmentSub.unsubscribe();
    }
    if (this.selectMyPoliciesSub) {
      this.selectMyPoliciesSub.unsubscribe();
    }
  }

  policyInstallmentCASCO() {
    if (this.CASCOPolicies) {
      this.CASCOPolicies.forEach(AllCASCOPolicies => {
        AllCASCOPolicies.forEach(CASCOPolicyItem => {
          this.getPoliciesInstallmentSub =
            this.myPolicyService.getPoliciesInstallment(CASCOPolicyItem.id).subscribe(PolicyIdInstallments => {
              if (PolicyIdInstallments) {
                const policyIdInstallmentsLength = PolicyIdInstallments.length;
                PolicyIdInstallments.some(policyIdInstallmentItem => {
                  if (policyIdInstallmentItem.payment === null) {
                    if
                    (!this.CASCOPoliciesAndInstallment.find(installment => installment.policyId === CASCOPolicyItem.number)) {
                      this.CASCOPoliciesAndInstallment.push({
                        policyData: CASCOPolicyItem,
                        policyId: CASCOPolicyItem.number,
                        installmentToPay: policyIdInstallmentItem,
                        installments: PolicyIdInstallments,
                        fullPayed: false
                      });
                    }
                  }
                  if (policyIdInstallmentItem.payment !== null && policyIdInstallmentsLength === policyIdInstallmentItem.number) {
                    if
                    (!this.CASCOPoliciesAndInstallment.find(installment => installment.policyId === CASCOPolicyItem.number)) {
                      this.CASCOPoliciesAndInstallment.push({
                        policyData: CASCOPolicyItem,
                        policyId: CASCOPolicyItem.number,
                        installmentToPay: policyIdInstallmentItem,
                        installments: PolicyIdInstallments,
                        fullPayed: true
                      });
                    }
                  }
                });
              }
            });
        });
      });
    }
  }

  policyInstallmentMTPL() {
    if (this.MTPLPolicies) {
      this.MTPLPolicies.forEach(x => {
        x.forEach(y => {
          this.myPolicyService.getPoliciesInstallment(y.id).subscribe(rez => {
            if (this.MTPLPoliciesAndInstallment.length === 0) {
              this.MTPLPoliciesAndInstallment.push({
                policyData: y,
                policyId: y.number,
                installments: rez
              });
            }
            if (this.MTPLPoliciesAndInstallment.length > 0) {
              if (!this.MTPLPoliciesAndInstallment.find(installment => installment.policyId === y.number)) {
                this.MTPLPoliciesAndInstallment.push({
                  policyData: y,
                  policyId: y.number,
                  installments: rez
                });
              }
            }
          });
        });
      });
    }
  }

  seePayments() {
    const state = this.myPolicyService.sendDataToPaymentsPage(
      this.CASCOPoliciesAndInstallment, this.MTPLPoliciesAndInstallment, this.chassisId, this.activeTab
    );
    this.store.dispatch(fetchPolicyInstallmentsSuccess({selectedPolicyInstallments: state}));
    this.router.navigate(['/payments']);
  }

}
