import { Component, OnInit } from '@angular/core';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Utils from '../../../../shared/utils/utils';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-warning-casco-offer-details',
  templateUrl: './warning-casco-offer-details.component.html',
  styleUrls: ['./warning-casco-offer-details.component.scss']
})
export class WarningCascoOfferDetailsComponent implements OnInit {
  appType = environment.app;

  faClose = faWindowClose;
  isMobile: boolean;
  constructor(private modal: NgbModal) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
  }

  closeModal(){
    this.modal.dismissAll();
  }

}
