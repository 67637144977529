import {Injectable} from '@angular/core';
import {AppStorageService} from './app-storage.service';
import {environment} from '../../environments/environment';
import {OktaAuthService} from '@okta/okta-angular';
import {ApigeeService} from './apigee.service';
import { Store, select } from '@ngrx/store';
import { AppState, selectApigeeToken } from '../store';
import { take, mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject, from, of } from 'rxjs';
import { initialState } from '../store/reducers/auth.reducers';

@Injectable()
export class SessionDataService {
  baseUri = environment.apiUrl;
  sessionData;
  apigeeToken;

  constructor(
    private oktaAuthService: OktaAuthService,
    private apigeeService: ApigeeService,
  ) {
  }

   async getSessionData(who = null, apigee = null) {
    if (!this.sessionData) {
      this.sessionData = await this.requestSessionData(apigee);
    }
    return this.sessionData;
  }

  public async requestSessionData(apigee = null) {
    let returnData = null;
    const oktaToken = await this.oktaAuthService.getAccessToken();
    if (!apigee) {
      apigee = await this.apigeeService.getToken('session-data');
    }

    const options = {
      method: 'POST',
      headers: {Authorization: 'MARSH-SPA userAccessToken=' + oktaToken + ',apiGatewayAccessToken=' + (apigee || this.apigeeToken)},
    };
    const url = `${this.baseUri}/tools/session-data`;
    if (oktaToken && (apigee || this.apigeeToken)) {
      return new Promise((res, rej) => {
        fetch(url, options)
          .then(response => response.json())
          .then((data) => {
            if (data.statusCode && data.statusCode !== 200) {

              rej(null);
            } else {
              returnData = data;
            }
            this.sessionData = returnData;
            res(returnData);
          });
      });
    } else {
      return null;
    }
  }

  getItem() {

  }
}
