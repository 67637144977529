import {Action} from '@ngrx/store';
import {CarsActionTypes} from '../actions/cars.actions';

export interface StateCars {
  cars: any;
}

export const initialState: StateCars = {
  cars: null,
};

export function reducer(stateCars = initialState, action: Action): StateCars {
  switch (action.type) {
    case CarsActionTypes.FETCH_CARS_DATA:
      return {
        ...stateCars
      };
    case CarsActionTypes.FETCH_CARS_DATA_SUCCESS:
      return {
        ...stateCars,
        cars: action[`myCars`]
      };
    default:
      return stateCars;
  }
}


export const fetchMyCars = (stateCars: StateCars) => stateCars.cars;
