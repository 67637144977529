import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Utils from "../../utils/utils";

@Component({
  selector: 'app-marsh-decont',
  templateUrl: './decontare.component.html',
  styleUrls: ['./decontare.component.scss']
})
export class DecontareComponent implements OnInit {
  faClose = faWindowClose;
  isMobile: boolean;
  constructor(private modal: NgbModal) {
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit() {
  }

  closeModal(){
    this.modal.dismissAll();
  }
}
