import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckHeadersService {

  isSet: boolean;
  data: BehaviorSubject<boolean>;
  constructor() {
    this.data = new BehaviorSubject(this.isSet);
  }

  setHeadersStatus(option){
    if (option === true) {
      this.data.next(true);
    } else {
      this.data.next(false);
    }
  }
}
