import { createAction, props } from '@ngrx/store';

export enum ToggleOfferActionTypes {
  TOGGLE_OFFER_CASCO = '[Offer Toggle] Toggle if an CASCO offer was selected',
  TOGGLE_OFFER_MTPL = '[Offer Toggle] Toggle if an MTPL offer was selected',
  TOGGLE_ACCEPT_TERMS_AND_CONDITIONS = '[Offer Toggle] Toggle if an Terms of use of offer was selected',
  TOGGLE_FOR_CONSULTANCY_CASCO = '[Offer Toggle] Toggle if the user wants consultancy or not for CASCO',
  TOGGLE_FOR_CONSULTANCY_MTPL = '[Offer Toggle] Toggle if the user wants consultancy or not for MTPL',
  TOGGLE_JUST_CASCO = '[Offer Toggle] Toggle if the user wants just CASCO',
  TOGGLE_JUST_MTPL = '[Offer Toggle] Toggle if the user wants just MTPL',
}

export const toggleIfSelectedOfferCASCO = createAction(
  ToggleOfferActionTypes.TOGGLE_OFFER_CASCO,
  props<{toggleIfSelectedOfferCASCO: any}>()
);

export const toggleIfSelectedOfferMTPL = createAction(
  ToggleOfferActionTypes.TOGGLE_OFFER_MTPL,
  props<{toggleIfSelectedOfferMTPL: any}>()
);

export const toggleTermsAndConditions = createAction(
  ToggleOfferActionTypes.TOGGLE_ACCEPT_TERMS_AND_CONDITIONS,
  props<{toggleTermsAndConditions: any}>()
);

export const toggleForConsultancyCASCO = createAction(
  ToggleOfferActionTypes.TOGGLE_FOR_CONSULTANCY_CASCO,
  props<{wantsConsultancyCASCO: any}>()
);

export const toggleForConsultancyMTPL = createAction(
  ToggleOfferActionTypes.TOGGLE_FOR_CONSULTANCY_MTPL,
  props<{wantsConsultancyMTPL: any}>()
);

export const toggleJustCASCO = createAction(
  ToggleOfferActionTypes.TOGGLE_JUST_CASCO,
  props<{toggleJustCASCO: any}>()
);

export const toggleJustMTPL = createAction(
  ToggleOfferActionTypes.TOGGLE_JUST_MTPL,
  props<{toggleJustMTPL: any}>()
);




