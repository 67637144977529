import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Utils from '../../../../shared/utils/utils';
import {GetDataService} from '../../../../shared/services/get-data.service';

@Component({
  selector: 'app-marsh-cr-package-modal',
  templateUrl: './casco-mtpl-package-modal.component.html',
  styleUrls: ['./casco-mtpl-package-modal..scss']
})
export class CascoMtplPackageModalComponent implements OnInit {
  faClose = faWindowClose;
  private isMobile: boolean;
  constructor(
    private getDataService: GetDataService,
    private modal: NgbModal
  ) {
  }
  @Input() public packageData;
  packageDataLoaded: any;
  ngOnInit() {
    this.isMobile = Utils.checkIfMobile();
    this.packageDataLoaded = undefined;
    this.getCRPackageData();
  }

  getCRPackageData(){
    this.getDataService.getPackageData(
      this.packageData.insurerName, this.packageData.tabRate).subscribe(a => {
        if (a && a.length > 0){
          this.packageDataLoaded = a[0];
        }
    });
  }

  closeModal(){
    this.packageDataLoaded = undefined;
    this.modal.dismissAll();
  }

}
