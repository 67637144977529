import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcceptConditionsInsurerService {

  option: boolean;
  data: BehaviorSubject<boolean>;
  constructor() {
    this.option = false;
    this.data = new BehaviorSubject(this.option);
  }

  setOptionSelected(option) {
    if (option === true) {
      this.data.next(true);
    } else {
      this.data.next(false);
    }

  }
}
