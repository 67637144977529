import {Injectable, OnDestroy} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  AppState,
  selectMyPolicies,
  selectOfferDataCustomerId,
  selectOfferDataVehicle,
  selectOfferDataVehicleId
} from '../../../store';
import {
  fetchPoliciesDataSuccess,
  fetchPolicyHistorySuccess,
  fetchUnPayedInstallment
} from '../../../store/actions/policies.actions';
import {OktaAuthService} from '@okta/okta-angular';
import {ApigeeService} from 'src/app/services/apigee.service';
import {PaymentService} from 'src/app/services/payment.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MyPoliciesService implements OnDestroy{
  baseUri = environment.apiUrl;
  MTPLPoliciesActive: any[];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  MTPLPoliciesOther: any[];
  CASCOPoliciesActive: any[];
  CASCOPoliciesOther: any[];
  dataHistoryPaymentsObj: any;
  notificationsRate: any[];
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private router: Router,
    private oktaAuthService: OktaAuthService,
    private apigeeService: ApigeeService,
    private paymentService: PaymentService,
    private localStorageService: LocalStorageService
  ) {
    this.notificationsRate = [];
    this.dataHistoryPaymentsObj = new BehaviorSubject([]);
  }

  public ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getAllPoliciesByCustomerId(customerID) {
    const url = `${this.baseUri}/policies?limit=50&page=0&cache=0&filter[0]=customer.sourceCustomer.id||$eq||${customerID}`;
    return this.http.get<any>(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getPolicies(customerID) {
    const url = `${this.baseUri}/policies?limit=50&page=0&cache=0&filter[0]=customer.sourceCustomer.id||$eq||${customerID}&filter[1]=status.code||$eq||ACTIVE`;
    return this.http.get<any>(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getPoliciesToDownload(customerID, policyId) {
    const url = `${this.baseUri}/policies?limit=50&page=0&cache=0&filter[0]=customer.sourceCustomer.id||$eq||${customerID}&filter[1]=id||$eq||${policyId}`;
    return this.http.get<any>(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getPoliciesToDownloadContent(policyIds, customerId) {
    const policiesIds = policyIds;
    const allPoliciesToDownloadData = [];

    policiesIds.forEach(id => {
      this.getPoliciesToDownload(customerId, id).subscribe(rez => {
        allPoliciesToDownloadData.push(rez[`data`][0]);
      });
    });
    return allPoliciesToDownloadData;

  }

  getPoliciesInstallment(policyId) {
    const url = `${this.baseUri}/installments/${policyId}`;
    return this.http.get<any>(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getPoliciesList(customerId?: number) {
    let allPolicies = [];
    this.getAllPoliciesByCustomerId(customerId).subscribe(rez => {
      let myPoliciesFiltered = [];
      if (rez[`count`] > 0) {
        allPolicies = rez.data;

        this.MTPLPoliciesActive = allPolicies.filter(a => a.product.type.code === 'MTPL' && a.status.code === 'ACTIVE');
        this.MTPLPoliciesOther = allPolicies.filter(a => a.product.type.code === 'MTPL' && a.status.code !== 'ACTIVE');
        this.CASCOPoliciesActive = allPolicies.filter(a => a.product.type.code === 'CASCO' && a.status.code === 'ACTIVE');
        this.CASCOPoliciesOther = allPolicies.filter(a => a.product.type.code === 'CASCO' && a.status.code !== 'ACTIVE');

        myPoliciesFiltered = [{
          MTPL: {
            active: this.MTPLPoliciesActive,
            activeLengthMTPL: this.MTPLPoliciesActive.length,
            other: this.MTPLPoliciesOther
          },
          CASCO: {
            active: this.CASCOPoliciesActive,
            activeLengthCASCO: this.CASCOPoliciesActive.length,
            other: this.CASCOPoliciesOther
          },
          allActivePoliciesLength: {
            activeLength: this.CASCOPoliciesActive.length + this.MTPLPoliciesActive.length,
          }
        }];
      }
      this.store.dispatch(fetchPoliciesDataSuccess({myPolicies: myPoliciesFiltered[0]}));
    });
    return null;
  }

  sendDataToPaymentsPage(CASCODataWithInstallments, MTPLDataWithInstallments, chassisId, activeTab) {
    const dataToPayments = [];
    const seePaymentsMTPL = [];
    const seePaymentsCASCO = [];

    if (MTPLDataWithInstallments.length > 0) {
      MTPLDataWithInstallments.forEach(x => {
        if (x.policyData.vehicle.chassis === chassisId) {
          seePaymentsMTPL.push(x);
        }
      });
    }

    if (CASCODataWithInstallments.length > 0) {
      CASCODataWithInstallments.forEach(x => {
        if (x.policyData.vehicle.chassis === chassisId) {
          seePaymentsCASCO.push(x);
        }
      });
    }

    dataToPayments.push({
      activeTabSet: activeTab,
      seePaymentsCASCO,
      seePaymentsMTPL
    });
    return dataToPayments;
  }

  seeHistoryPayments(chassisId, activeTab, customerId: number) {
    this.getAllPoliciesByCustomerId(customerId).subscribe(rez => {
      const today = new Date();
      let allPolicies = [];
      const dataHistoryPayments = [];
      let seeHistoryPaymentsMTPL = [];
      let seeHistoryPaymentsCASCO = [];
      if (rez[`count`] > 0) {
        allPolicies = rez.data;

        seeHistoryPaymentsCASCO =
          allPolicies.filter(a => a.product.type.code === 'CASCO' && a.status.code === 'EXPIRED' && a.vehicle.chassis === chassisId);
        seeHistoryPaymentsMTPL =
          allPolicies.filter(a => a.product.type.code === 'MTPL' && a.status.code === 'EXPIRED' && a.vehicle.chassis === chassisId);

        dataHistoryPayments.push({
          activeTabSet: activeTab,
          seeHistoryPaymentsCASCO,
          seeHistoryPaymentsMTPL
        });
        this.store.dispatch(fetchPolicyHistorySuccess({selectedPolicyHistory: dataHistoryPayments}));
        this.router.navigate(['/payment-history']);
      }
    });
  }

  sendPolicyByEmail(body) {
    // const url = `${this.baseUri}/policies/send-email`;
    const url = `${this.baseUri}/tools/send-email-policy`;
    return this.http.post<any>(url, body).pipe(
      catchError(this.errorManagement)
    );
  }

  checkUnpayedPolicies(customerId?: number): Promise<boolean> {
    let result;
    this.notificationsRate = [];
    result = new Promise((resolve, reject) => {
      this.getCascoActivePolicies(customerId).then(allActivePolicies => {
        if (allActivePolicies) {
          allActivePolicies.forEach(policy => {
            if (
              !this.notificationsRate.find(
                notificationList => notificationList.number === policy.number)
            ) {
              this.notificationsRate.push(policy);
              this.getPoliciesInstallment(policy.id).subscribe(allPoliciesInstallments => {
                if (allPoliciesInstallments.length > 0) {
                  let isMissed:boolean = false;
                  allPoliciesInstallments.forEach( unpayedInstallment => {
                    if (unpayedInstallment.payment === null) {
                      if (unpayedInstallment) {
                        // const test = new Date('2023-08-24T00:00:00.000Z'); //<---- Uncomment to test

                        const installmentDueDate = new Date(unpayedInstallment.dueDate);
                        const currentDate = new Date();
                        const dueDate:Date = new Date(installmentDueDate.getTime() + 30 * 24*60*60*1000);                       
                          if (!isMissed && (currentDate.getTime() < dueDate.getTime())) {
                          resolve(this.isValidInstallment(unpayedInstallment));
                          isMissed = false;
                        } else {
                          resolve(true);
                          isMissed = true;
                        }
                      } else {
                        return;
                      }
                    }
                  });
                } else {
                  reject({error: 'Policy id is not set'});
                }
              });
            }
          });
        }
      });
    });
    return result;
  }


  private isValidInstallment(installmentProduct: any): boolean {
    // send policy installment data to service
    this.store.dispatch(fetchUnPayedInstallment({unPayedInstallment: installmentProduct}));
    this.paymentService.setProductData(installmentProduct);
    // this.paymentService
    //   .validateInstallment(installmentProduct)
    //   .then(_ => {
    //   });
    return false;
  }

  getCascoActivePolicies(customerId?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllPoliciesByCustomerId(customerId).subscribe(rez => {
        if (rez[`count`] > 0) {
          const cascoPolicies = rez.data.filter(a => a.product.type.code === 'CASCO' && a.status.code === 'ACTIVE');
          resolve(cascoPolicies);
        }
      });
    });
  }
}