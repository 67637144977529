import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {CoreModule} from '../core/core.module';
import {OfferComponent} from './offer/offer/offer.component';
import {SharedModule} from '../shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {OfferSummaryComponent} from './offer/offer-summary/offer-summary.component';
import {DownloadBoughtOfferComponent} from './offer/download-bought-offer/download-bought-offer.component';
import {ManagePaymentsComponent} from './payment/manage-payments/manage-payments.component';
import {PaymentConfirmedComponent} from './payment/payment-confirmed/payment-confirmed.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PayInstallmentComponent} from './payment/manage-payments/pay-installment/pay-installment.component';
import {WantConsultancyCascoComponent} from './offer/offer/want-consultancy-casco/want-consultancy-casco.component';
import {AcceptConditionsInsurerComponent} from './offer/offer/accept-conditions-insurer/accept-conditions-insurer.component';
import {TablesOfferCascoComponent} from './offer/offer/tabels-offer-casco/tables-offer-casco.component';
import {TablesOfferMTPLComponent} from './offer/offer/tables-offer-MTPL/tables-offer-MTPL.component';
import {OfferButtonsComponent} from './offer/offer/offer-buttons/offer-buttons.component';
import {PaymentHistoryComponent} from './payment/manage-payments/payment-history/payment-history.component';
import {DatePickerComponent} from './offer/offer/date-picker-offer/date-picker.component';
import { InstallmentsComponent } from './offer/offer/tabels-offer-casco/installments.component';
import {NgbDatepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { PaymentConfirmationComponent } from './offer/payment-confirmation/payment-confirmation.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import {NgxPopperjsModule} from 'ngx-popperjs';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { WantConsultancyMtplComponent } from './offer/offer/want-consultancy-mtpl/want-consultancy-mtpl.component';
import { ChangeBothOfferDatesComponent } from './offer/offer/date-picker-offer/change-both-offer-dates/change-both-offer-dates.component';
import { OfferDetailsPopupComponent } from './offer/offer/offer-details-popup/offer-details-popup.component';
import { CampaignDetailsPopupComponent } from './offer/offer/campaign-details-popup/campaign-details-popup/campaign-details-popup.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgxPopperjsModule,
        ToastrModule.forRoot({
            maxOpened: 1,
            preventDuplicates: true,
        }),
        NgbTooltipModule,
    ],
  declarations: [
    HomeComponent,
    OfferComponent,
    OfferSummaryComponent,
    DownloadBoughtOfferComponent,
    PaymentConfirmedComponent,
    ManagePaymentsComponent,
    PayInstallmentComponent,
    WantConsultancyCascoComponent,
    AcceptConditionsInsurerComponent,
    TablesOfferCascoComponent,
    TablesOfferMTPLComponent,
    InstallmentsComponent,
    OfferButtonsComponent,
    PaymentHistoryComponent,
    DatePickerComponent,
    PaymentConfirmationComponent,
    LandingPageComponent,
    WantConsultancyMtplComponent,
    ChangeBothOfferDatesComponent,
    OfferDetailsPopupComponent,
    CampaignDetailsPopupComponent
  ],
    exports: [
        HomeComponent,
        TablesOfferCascoComponent,
        TablesOfferMTPLComponent,
        InstallmentsComponent,
        DatePickerComponent
    ]
})
export class PagesModule {
}
