import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {UserNotificationSubscriptions} from 'src/app/models/user.model';
import {UserSubscriptionsService} from 'src/app/services/user-subscriptions.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss'],
})
export class UserSubscriptionsComponent implements OnInit {
  userSubscriptions: UserNotificationSubscriptions;

  constructor(
    private userNotificationService: UserSubscriptionsService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.userSubscriptions = new UserNotificationSubscriptions();
    this.getUserNotifications();
  }

  getUserNotifications(): void {
    this.userNotificationService.getSubscriptions().subscribe((result: any) => {
      if (result && result.statusCode !== '404') {
        this.userSubscriptions = result;
      }
    });
  }

  save(): void {
    this.userSubscriptions.termMarketing = this.isTermsTrueOrFalse;
    this.userNotificationService.updateSubscriptions(this.userSubscriptions).subscribe((result: any) => {
      this.closeModal();
    });
  }

  get isTermsTrueOrFalse() {
    return !(this.userSubscriptions.canCommunicateByEmail === false &&
      this.userSubscriptions.canCommunicateByPhone === false &&
      this.userSubscriptions.canCommunicateByPostalService === false &&
      this.userSubscriptions.canCommunicateBySms === false);
  }

  closeModal() {
    this.activeModal.close();
  }
}
