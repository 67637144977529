import {Injectable, OnDestroy} from '@angular/core';
import {OfferService} from '../../services/offers.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
  fetchOfferDataCASCO,
  fetchOfferDataCASCOSuccess,
  fetchOfferDataCustomer,
  fetchOfferDataCustomerId,
  fetchOfferDataCustomerSuccess,
  fetchOfferDataMTPL,
  fetchOfferDataMTPLSuccess,
  fetchOfferDataSuccess,
  fetchOfferDataVehicle,
  fetchOfferDataVehicleId,
  fetchOfferDataVehicleSuccess,
  fetchOfferListData,
  fetchOfferListDataSuccess
} from '../../store/actions/offer.actions';
import {Store} from '@ngrx/store';
import * as fromStore from '../../store';
import {AppState, selectOfferDataVehicleId} from '../../store';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MyPoliciesService} from './myPoliciesService/my-policies.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {CascoInstallments, InsurerCodes} from 'src/app/models/mapping.model';
import {CascoData} from 'src/app/models/casco-table.model';
import {Step} from 'src/app/models/stepper.models';
import {MyCarsService} from './my-cars.service';
import {PayRateObserverService} from './pay-rate-observer.service';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetDataService implements OnDestroy{
  packageDataObjMTPL = [];
  packageDataObjCASCO = [];
  MTPLWithSettlementObj = [];
  MTPLWithoutSettlementObj = [];
  offerProductCASCO;
  offerProductMTPL;
  offerProductsAll;
  getOfferByIDSubscription: Subscription;
  cascoMyData = new BehaviorSubject(new CascoData());
  mtplMyData = new BehaviorSubject([]);
  packageMyData = new BehaviorSubject([]);
  offer: any;
  interval;
  quotationComplete = false;
  quotationInstallment4 = false;
  products: CascoData;
  customerId: number;
  cancelOnRequestConsultant: BehaviorSubject<boolean>;
  spinner$: Observable<boolean>;

  constructor(
    private offerService: OfferService,
    private apiService: ApiService,
    private myPoliciesService: MyPoliciesService,
    private myCarsService: MyCarsService,
    private store: Store<AppState>,
    private policiesService: MyPoliciesService,
    private payRateObserverService: PayRateObserverService,
    private localStorageService: LocalStorageService) {
    // this.store.select(selectOfferDataCustomerId).subscribe(customerId => {this.customerId = customerId});
    // this.customerId = window.sessionStorage.getItem('customerId');
    this.cancelOnRequestConsultant = new BehaviorSubject(false);
  }

  ngOnDestroy(): void {
      if (this.getOfferByIDSubscription) {
        this.getOfferByIDSubscription.unsubscribe();
      }
  }

  refreshAllTheData() {
    // this.store.dispatch({type: 'startSpinner'});
    const customerId = window.sessionStorage.getItem('customerId');
    if (customerId !== null) {
      this.store.dispatch(fetchOfferDataCustomer());
      this.store.dispatch(fetchOfferDataVehicle());
      this.store.dispatch(fetchOfferListData());
      this.store.dispatch(fetchOfferDataCASCO());
      this.store.dispatch(fetchOfferDataMTPL());
      this.store.select(fromStore.selectOfferDataCustomer).subscribe(customer => {
        if (customer && customer.id) {
          this.policiesService.checkUnpayedPolicies(customer.id)?.then(result => {
            this.payRateObserverService.sendUnpayedRateCheck(result);
          }).catch(error => {
            console.error(error);
          });
        }
      });
      // this.store.dispatch({type: 'stopSpinner'});
    } else {
      this.apiService.sessionData().subscribe(res => {
        window.sessionStorage.setItem('customerId', res.customerId);
        this.refreshAllTheData();
      });
    }
  }

  getOffers() {
    this.customerId = JSON.parse(window.sessionStorage.getItem('customerId'));
    if (this.customerId) {
      this.offerService.getOffersByCustomerId(this.customerId).subscribe(offerList => {
        this.store.dispatch(fetchOfferListDataSuccess({offerList: offerList[`data`]}));
        this.myPoliciesService.getPoliciesList(this.customerId);
        this.myCarsService.getVehiclesList(this.customerId);
      });
    }
    return null;
  }

  getOfferById(offerId: any, modal?: NgbModalRef) {
   this.getOfferByIDSubscription = this.offerService.getOfferByID(offerId).subscribe(offer => {
      if (offer) {
        this.offer = offer;
        this.store.dispatch(fetchOfferDataSuccess({offer: this.offer}));
        this.store.dispatch(fetchOfferDataVehicleId({offerVehicleId: this.offer.vehicle.id}));
        this.getAllProductsCASCO();
        this.getAllProductsMTPL();
      }
    });
   return null;
  }

  getOfferByIdWithMTPLAndCasco(offerId: number, modal?: NgbModalRef) {
    this.getOfferByIDSubscription = this.offerService.getOfferByID(offerId).subscribe(offer => {
      this.offer = offer;
      this.store.dispatch(fetchOfferDataSuccess({offer: this.offer}));
      window.sessionStorage.setItem('offerId', offer.id);

      // Reinitiate everything for the new quotations search
      clearInterval(this.interval);
      this.quotationInstallment4 = false;
      const reloadTime = environment.recalculateOfferTime;
      // let timeLeft: number = 2.5 * 60 * 1000 - reloadTime;
      // const modalAnimationTime = timeLeft - 30000 + reloadTime; //loading animation duration for new offer is set to 30.000

      // Call offer-products for max 2 minutes until all the products are received
      this.interval = setInterval(() => {
        // if (timeLeft === modalAnimationTime) { //automatically close the modal after 0.5 minutes, even if quotations are not completed
        //   debugger;
        //   modal.close();
        // }
        // if (this.quotationComplete === true || timeLeft <= 0) {
        //   timeLeft = -1;
        //   clearInterval(this.interval);
        //   modal.close();
        // } else {
        //   timeLeft = timeLeft - reloadTime;
          this.getAllProductsCASCO();
          // if (this.quotationInstallment4 === true || timeLeft <= 0) {
          modal.close();
          // }
        // }
      }, reloadTime);
      this.getAllProductsMTPL();
    });
    return null;
  }

  getOfferWithMTPLAndCasco() {
    this.offerService.getCurrentOffer().subscribe((result: any) => {
      this.offerService.getOfferByID(result.data[0].id).subscribe(offer => {
        this.offer = offer;
        const vehicleIds = [];
        vehicleIds.push(this.offer[`vehicle`][`id`]);
        this.store.dispatch(fetchOfferDataSuccess({offer: this.offer}));
        this.store.dispatch(fetchOfferDataCustomerId({offerCustomerId: this.offer[`customer`][`sourceCustomer`][`id`]}));
        this.store.dispatch(fetchOfferDataVehicleId({offerVehicleId: vehicleIds}));
        this.getAllProductsCASCO();
        this.getAllProductsMTPL();
      });
    });
    return null;
  }

  getCustomerData() {
    const customerId = window.sessionStorage.getItem('customerId');
    this.offerService.getCustomerByID(customerId).subscribe(x => {
      this.store.dispatch(fetchOfferDataCustomerSuccess({offerCustomerData: x}));
    });
    return null;
  }

  getVehicleData() {
    this.store.select(selectOfferDataVehicleId).subscribe(
      vehicleId => {
        if (vehicleId !== null) {
          this.myCarsService.getVehicleById(vehicleId).subscribe(x => {
            this.store.dispatch(fetchOfferDataVehicleSuccess({offerVehicleData: x}));
          });
        }
      }
    );
    return null;
  }

  getVehicleDataByChassis() {
    this.store.select(selectOfferDataVehicleId).subscribe(
      vehicleChassis => {
        if (vehicleChassis && vehicleChassis.length > 0) {
          const vehicles = [];
          vehicleChassis.forEach(chassis => {
            this.myCarsService.getVehiclesByChassis(chassis).subscribe(result => {
              vehicles.push(result[`data`][0]);
            });
          });
          this.store.dispatch(fetchOfferDataVehicleSuccess({offerVehicleData: vehicles}));
        }
      }
    );
    return null;
  }

  getAllProductsCASCO() {
    if (this.offer && this.offer.offerForCasco) {
      this.offerService.getProductsOfferList(this.offer.id).subscribe((data) => {
        this.offerProductCASCO = data.data;
        if (this.offerProductCASCO !== undefined) {
          const cascoData = new CascoData();
          InsurerCodes.forEach(insurerCode => {
            const products = this.offerProductCASCO
              .filter(product => product.product.insurer.code === insurerCode
                && product.product.type.code === 'CASCO');
            CascoInstallments.forEach(installmentNumber => {
              const productsByInstallment = products
                .filter(product => product.installmentNumber.code === installmentNumber);
              if (products[0] === undefined) {
                const cascoGold =
                  productsByInstallment.filter(
                    x => x.premium !== null && x.package.name === 'no' && x.product.cascoType.code === 'GOLD'
                  );
                const insurerName = null;
                if (installmentNumber === CascoInstallments[0]) {
                  cascoData.installment1.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                } else if (installmentNumber === CascoInstallments[1]) {
                  cascoData.installment4.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                } else if (installmentNumber === CascoInstallments[2]) {
                  cascoData.installment12.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                }
              } else {
                const cascoGold =
                  productsByInstallment.filter(
                    x => x.premium !== null && x.package.name === 'no' && x.product.cascoType.code === 'GOLD'
                  );
                const insurerName = products[0].product.insurer.name;
                if (installmentNumber === CascoInstallments[0]) {
                  cascoData.installment1.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                } else if (installmentNumber === CascoInstallments[1]) {
                  cascoData.installment4.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                } else if (installmentNumber === CascoInstallments[2]) {
                  cascoData.installment12.push({
                    insurer: insurerName,
                    packageDiscount: 0,
                    cascoGold: cascoGold['0']?.premium,
                    products: productsByInstallment
                  });
                }
              }
            });
          });
          this.cascoMyData.next(cascoData);
          this.store.dispatch(fetchOfferDataCASCOSuccess({offerCASCO: cascoData}));

          this.products = cascoData;
          let quotationCount = 0;
          let quotationInstallment4Count = 0;
          let productCount = 0;
          cascoData.installment4.forEach(data => {
            quotationInstallment4Count += data.products.filter(x => x.premium != null).length;
            quotationCount += data.products.filter(x => x.premium != null).length;
            productCount += data.products.length;
          });
          cascoData.installment1.forEach(data => {
            quotationCount += data.products.filter(x => x.premium != null).length;
            productCount += data.products.length;
          });
          cascoData.installment12.forEach(data => {
            quotationCount += data.products.filter(x => x.premium != null).length;
            productCount += data.products.length;
          });
          this.quotationInstallment4 = quotationInstallment4Count >= 8;
          this.quotationComplete = quotationCount === productCount;
        }
      });
    }
    return null;
  }

  getAllProductsMTPL() {
    if (this.offer && this.offer.offerForMtpl) {
      this.offerService.getProductsOfferList(this.offer.id).subscribe((data) => {
        this.offerProductMTPL = data.data;
        const MTPLData = [];
        this.MTPLWithSettlementObj = this.offerProductMTPL.filter(
          a => a.product.type.code === 'MTPL' && a.directSettlement === true && a.installmentNumber.code === '1'
        );
        this.MTPLWithoutSettlementObj = this.offerProductMTPL.filter(
          a => a.product.type.code === 'MTPL' && a.directSettlement === false && a.installmentNumber.code === '1'
        );
        MTPLData.push({
          MTPL: {
            1: {
              withSettlement: this.MTPLWithSettlementObj.filter(a => a.product.period === 1),
              withoutSettlement: this.MTPLWithoutSettlementObj.filter(a => a.product.period === 1)
            },
            6: {
              withSettlement: this.MTPLWithSettlementObj.filter(a => a.product.period === 6),
              withoutSettlement: this.MTPLWithoutSettlementObj.filter(a => a.product.period === 6)
            },
            12: {
              withSettlement: this.MTPLWithSettlementObj.filter(a => a.product.period === 12),
              withoutSettlement: this.MTPLWithoutSettlementObj.filter(a => a.product.period === 12)
            }
          }
        });
        this.mtplMyData.next(MTPLData);
        this.store.dispatch(fetchOfferDataMTPLSuccess({offerMTPL: MTPLData}));
      });
    }
    return null;
  }

  getPackageData(packageName, installmentNr) {
    this.offerService.getProductsOfferList(this.offer.id).subscribe((data) => {
      this.offerProductsAll = data.data;
      const Package = [];
      this.packageDataObjMTPL = this.offerProductsAll.filter(
        a =>
          a.product.type.code === 'MTPL' &&
          a.product.insurer.name === packageName
      );
      this.packageDataObjCASCO = this.offerProductsAll.filter(
        a =>
          a.product.type.code === 'CASCO' &&
          a.installmentNumber.code === installmentNr &&
          a.package.name === packageName &&
          a.product.insurer.name === packageName
      );

      Package.push({
        CASCO: {
          GOLD: this.offerProductsAll.filter(a =>
            a.product.type.code === 'CASCO' &&
            a.product.insurer.name === packageName &&
            a.product.cascoType.code === 'GOLD' &&
            a.package.name === 'no' &&
            a.installmentNumber.code === installmentNr),
          SILVER: this.offerProductsAll.filter(a =>
            a.product.type.code === 'CASCO' &&
            a.product.insurer.name === packageName &&
            a.product.cascoType.code === 'SILVER' &&
            a.package.name === 'no' &&
            a.installmentNumber.code === installmentNr)
        },
        CASCOPackage: {
          GOLD: this.packageDataObjCASCO.filter(a => a.product.cascoType.code === 'GOLD'),
          SILVER: this.packageDataObjCASCO.filter(a => a.product.cascoType.code === 'SILVER'),
        },
        MTPLPackage: {
          withSettlement: this.packageDataObjMTPL.filter(a => a.directSettlement === true),
          withoutSettlement: this.packageDataObjMTPL.filter(a => a.directSettlement === false),
        }
      });
      this.packageMyData.next(Package);
    });
    return this.packageMyData;
  }

  initiateOfferSteps(): Step[] {
    return [
      {
        number: 1,
        title: 'Alege Casco',
        description: 'Configurează cea mai bună ofertă de CASCO. Poți alege un preț avantajos de pachet sau poți sări acest pas și să-ți alegi doar o polița RCA.',
        isActive: (this.offer && this.offer.offerForCasco === true)
      } as Step,
      {
        number: 2,
        title: 'Alege RCA',
        description: 'Configurează cea mai bună ofertă de RCA.',
        isActive: (this.offer && this.offer.offerForMtpl === true)
      } as Step,
      {
        number: 3,
        title: 'Sumar și plată',
        description: 'Previzualizează selecția și plătește cu cardul, securizat',
        isActive: true
      } as Step,
      {
        number: 4,
        title: 'Descarcă polițe',
        description: null,
        isActive: true
      } as Step
    ];
  }
}
