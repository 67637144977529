import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import Utils from '../../../../../../shared/utils/utils';

@Component({
  selector: 'app-conditions-with-chapters-groupama',
  templateUrl: './conditions-with-chapters-groupama.component.html',
  styleUrls: ['./conditions-with-chapters-groupama.component.scss']
})
export class ConditionsWithChaptersGroupamaComponent implements OnInit {
  appType = environment.app;
  isMobile: boolean;
  constructor() {
    this.isMobile = Utils.checkIfMobile();
  }
  @Input() chapter: number;
  ngOnInit(): void {
  }

}
