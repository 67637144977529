import {Action} from '@ngrx/store';
import {ToggleOfferActionTypes} from '../actions/offerToggle.actions';

export interface StateToggleOffer {
  isCASCOSelected: any;
  isMTPLSelected: any;
  isTermsSelected: any;
  wantsConsultancyCASCO: any;
  wantsConsultancyMTPL: any;
  wantsJustCASCO: any;
  wantsJustMTPL: any;
}

export const initialState: StateToggleOffer = {
  isCASCOSelected: null,
  isMTPLSelected: null,
  isTermsSelected: false,
  wantsConsultancyCASCO: null,
  wantsConsultancyMTPL: null,
  wantsJustCASCO: false,
  wantsJustMTPL: false,
};

export function reducer(stateToggleOffer = initialState, action: Action): StateToggleOffer {
  switch (action.type) {
    case ToggleOfferActionTypes.TOGGLE_OFFER_CASCO:
      return {
        ...stateToggleOffer,
        isCASCOSelected: action[`toggleIfSelectedOfferCASCO`]
      };
    case ToggleOfferActionTypes.TOGGLE_OFFER_MTPL:
      return {
        ...stateToggleOffer,
        isMTPLSelected: action[`toggleIfSelectedOfferMTPL`]
      };
    case ToggleOfferActionTypes.TOGGLE_ACCEPT_TERMS_AND_CONDITIONS:
      return {
        ...stateToggleOffer,
        isTermsSelected: action[`toggleTermsAndConditions`]
      };
    case ToggleOfferActionTypes.TOGGLE_FOR_CONSULTANCY_CASCO:
      return {
        ...stateToggleOffer,
        wantsConsultancyCASCO: action[`wantsConsultancyCASCO`]
      };
    case ToggleOfferActionTypes.TOGGLE_FOR_CONSULTANCY_MTPL:
      return {
        ...stateToggleOffer,
        wantsConsultancyMTPL: action[`wantsConsultancyMTPL`]
      };
    case ToggleOfferActionTypes.TOGGLE_JUST_CASCO:
      return {
        ...stateToggleOffer,
        wantsJustCASCO: action[`toggleJustCASCO`]
      };
    case ToggleOfferActionTypes.TOGGLE_JUST_MTPL:
      return {
        ...stateToggleOffer,
        wantsJustMTPL: action[`toggleJustMTPL`]
      };
    default:
      return stateToggleOffer;
  }
}

export const checkIfCASCOSelectedReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.isCASCOSelected;
export const checkIfMTPLSelectedReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.isMTPLSelected;
export const checkIfTermsSelectedReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.isTermsSelected;
export const checkIfWantsConsultancyCASCOReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.wantsConsultancyCASCO;
export const checkIfWantsConsultancyMTPLReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.wantsConsultancyMTPL;
export const checkIfWantsJustCASCOReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.wantsJustCASCO;
export const checkIfWantsJustMTPLReducer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer.wantsJustMTPL;
export const checkStateToggleOffer = (stateToggleOffer: StateToggleOffer) => stateToggleOffer;
