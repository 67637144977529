import {Action, createAction, props} from '@ngrx/store';

export enum AuthActionTypes {
  LOGOUT = '[Logout Page] Logout',
  LOGIN_SUCCESS = '[Okta Auth] Login Success',
  SET_USER_DETAILS = '[Login page] Set user details ',
  PRE_VALIDATE_USER_DETAILS = '[Login Page] Get user data if it has an account registered'
}

export const LoginSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<{ payload: any }>()
);

export const SetUserDetails = createAction(
  AuthActionTypes.SET_USER_DETAILS,
  props<{payload: any}>()
);

export const SetPreValidateUserDetails = createAction(
  AuthActionTypes.PRE_VALIDATE_USER_DETAILS,
  props<{payload: {
      preValidateUserDetails: any,
      offerToken: any
    }
  }>()
);
