export default class Utils {

  static checkIfMobile() {
    const screenWidth = window.innerWidth;
    return screenWidth <= 426;
  }

  static doSomethingElse(val: string) {
    return val;
  }
}
