import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {
  UserConfirmEmailModel,
  UserPreValidateModel, UserRegisterPasswordModel,
  UserSendPinFromEmailModel
} from '../../../../models/user-register.model';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {CustomValidators} from './validation';
import {AppState} from '../../../../store';
import {Store} from '@ngrx/store';
import {selectPreValidateUserDetails} from '../../../../store/';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MarketingAgreementsModalComponent} from './marketing-agreements-modal/marketing-agreements-modal.component';
import {ToastrService} from 'ngx-toastr';
import {GdprComponent} from '../../../../shared/components/footer-links/gdpr/gdpr.component';
import {
  TermsConditionsComponent
} from '../../../../shared/components/footer-links/terms-conditions/terms-conditions.component';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  pinFromEmail = false;
  setPass = false;
  register = false;
  preValidate = true;
  userName: string;
  firstName: string;
  lastName: string;
  public ConfirmEmailForm: FormGroup;
  public ActivateCodeForm: FormGroup;
  public PreValidateForm: FormGroup;
  public SetPasswordForm: FormGroup;
  preValidateNotFound: boolean;
  errors;
  errorBackend;
  offerStatus;
  pinFromEmailError = false;
  confirmEmailError = false;
  responsePreValidate;
  customerId: number;

  userPreValidate: UserPreValidateModel;
  userConfirmEmail: UserConfirmEmailModel;
  userSendPinFromEmail: UserSendPinFromEmailModel;
  userRegister: UserRegisterPasswordModel;
  submitted = false;
  firstNameValidation: boolean;
  lastNameValidation: boolean;
  userNameValidation: boolean;
  fromToken: boolean;
  offerToken: string;
  offerUuidRegister: string;
  warningMessage = false;
  optionalUserData = null;
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    public form: FormBuilder,
    public router: Router,
    public modal: NgbModal,
    public store: Store<AppState>,
    public apiService: ApiService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.PreValidateForm = this.form.group({
      phoneNr: ['', [
        Validators.required,
        Validators.pattern(`^(\\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\\s|\\.|\\-)?([0-9]{3}(\\s|\\.|\\-|)){2}$`)
      ]],
      offerNr: ['', [Validators.required]],
    });
    this.ConfirmEmailForm = this.form.group({
      emailRegister: ['', [Validators.required, Validators.email]],
      termsRegister: [false, Validators.requiredTrue],
      gdprRegister: [false, Validators.requiredTrue],
      marketingRegister: [false],
    });
    this.ActivateCodeForm = this.form.group({
      activationPin: ['', [Validators.required]],
    });
    this.SetPasswordForm = this.form.group({
      password: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          Validators.minLength(8)
        ])
      ],
      confirmPassword: [null, Validators.compose([Validators.required])]
    },
    {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    }
    );
    this.fromOfferToken();
  }

  fromOfferToken() {
    this.store.select(selectPreValidateUserDetails).subscribe( resPreValidateUserData => {
      if (resPreValidateUserData !== null) {
        if (resPreValidateUserData.preValidateUserDetails.hasAccount === true) {
          this.toastrService.info('Pentru acest user ai creat deja cont. Te rugăm să te autentifici. Îți mulțumim!', '', {
            timeOut: 5000,
          });
          this.router.navigate(['/login']);
        } else {
          this.fromToken = true;
          this.register = true;
          this.preValidate = false;
          this.offerToken = resPreValidateUserData.offerToken;
          this.customerId = resPreValidateUserData.preValidateUserDetails.customerId;
          this.firstName = resPreValidateUserData.preValidateUserDetails.firstName;
          this.lastName = resPreValidateUserData.preValidateUserDetails.lastName;
          this.ConfirmEmailForm.patchValue({
            emailRegister: resPreValidateUserData.preValidateUserDetails.email
          });
        }
      } else {
        this.fromToken = false;
      }
    });
  }

  preValidateForm() {
    this.recaptchaV3Service.execute('preValidateSubmit').subscribe(
      (token) => {
        this.userPreValidate = new UserPreValidateModel();
        this.userPreValidate.offerNumber = this.PreValidateForm.controls.offerNr.value;
        this.userPreValidate.phoneNumber = this.PreValidateForm.controls.phoneNr.value;
        this.submitPreForm(token);
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3) preValidateForm',];
      });
  }

  submitPreForm(token) {
    this.preValidateNotFound = false;

    this.apiService.preValidateUser(this.userPreValidate, token).then(r => {
      this.responsePreValidate = r;
      this.offerStatus = {offerUuid: this.responsePreValidate.offerUuid, status: this.responsePreValidate.isActive};

      if (
        this.responsePreValidate.message === 'NotFound' ||
        this.responsePreValidate.statusCode === 500 ||
        this.responsePreValidate.statusCode === 403) {
        this.preValidateNotFound = true;
        return;
      } else {
        if (this.responsePreValidate.hasAccount === false) {
          this.ConfirmEmailForm.patchValue({
            emailRegister: this.responsePreValidate.email
          });
          this.customerId = this.responsePreValidate.customerId;
          this.register = true;
          this.preValidate = false;
          this.firstName = this.responsePreValidate.firstName;
          this.lastName = this.responsePreValidate.lastName;
          window.sessionStorage.setItem('offerStatus', this.offerStatus);
        } else {
          window.sessionStorage.setItem('offerStatus', this.offerStatus);
          this.toastrService.info('Pentru acest user ai creat deja cont. Te rugăm să te autentifici. Îți mulțumim!', '', {
            timeOut: 5000,
          });
          this.router.navigate(['/login']);
        }
      }
    });
  }

  confirmEmail() {
    if (this.ConfirmEmailForm.invalid) {
      this.warningMessage = true;
    } else {
      this.warningMessage = false;
      this.recaptchaV3Service.execute('confirmEmailSubmit').subscribe(
        (token) => {
          if (this.fromToken === false) {
            this.userConfirmEmail = new UserConfirmEmailModel();
            this.userConfirmEmail.offerToken = null;
            this.userConfirmEmail.email = this.ConfirmEmailForm.controls.emailRegister.value;
            this.userConfirmEmail.offerNumber = this.PreValidateForm.controls.offerNr.value;
            this.userConfirmEmail.terms = this.ConfirmEmailForm.controls.termsRegister.value;
            this.userConfirmEmail.gdpr = this.ConfirmEmailForm.controls.gdprRegister.value;
            this.userConfirmEmail.objectEnvironment = environment.app ?? '';
          } else {
            this.userConfirmEmail = new UserConfirmEmailModel();
            this.userConfirmEmail.offerToken = this.offerToken;
            this.userConfirmEmail.email = this.ConfirmEmailForm.controls.emailRegister.value;
            this.userConfirmEmail.offerNumber = null;
            this.userConfirmEmail.terms = this.ConfirmEmailForm.controls.termsRegister.value;
            this.userConfirmEmail.gdpr = this.ConfirmEmailForm.controls.gdprRegister.value;
            this.userConfirmEmail.objectEnvironment = environment.app ?? '';

          }
          this.apiService.validateUserEmail(this.userConfirmEmail, token).then(result => {
            const validateUserResult: any = result;
            if (validateUserResult.error === false) {
              this.setPass = false;
              this.register = false;
              this.preValidate = false;
              this.pinFromEmail = true;
              this.userName = this.ConfirmEmailForm.controls.emailRegister.value;
            } else {
              this.confirmEmailError = true;
            }
          });

        },
        (error) => {
          this.errors = ['Error trying to verify request (reCaptcha v3) confirmEmail'];
          // console.log(this.errors);
        });
    }
  }

  getMarketingStatus() {
    const marketingCheckbox = this.ConfirmEmailForm.controls.marketingRegister.value;
    if (this.optionalUserData && marketingCheckbox) {
      this.userRegister.canCommunicateByEmail = this.optionalUserData.canCommunicateByEmail;
      this.userRegister.canCommunicateByPhone = this.optionalUserData.canCommunicateByPhone;
      this.userRegister.canCommunicateBySms = this.optionalUserData.canCommunicateBySms;
      this.userRegister.canCommunicateByPostalService = this.optionalUserData.canCommunicateByPostalService;
      this.userRegister.termMarketing = this.optionMarketingCheckboxStatus;
      this.confirmEmailFormControls.marketingRegister.setValue(this.optionMarketingCheckboxStatus);
    } else if (marketingCheckbox){
      this.userRegister.canCommunicateByEmail = true;
      this.userRegister.canCommunicateByPhone = true;
      this.userRegister.canCommunicateBySms = true;
      this.userRegister.canCommunicateByPostalService = true;
      this.userRegister.termMarketing = true;
      this.confirmEmailFormControls.marketingRegister.setValue(true);
    } else if (!marketingCheckbox){
      this.userRegister.canCommunicateByEmail = false;
      this.userRegister.canCommunicateByPhone = false;
      this.userRegister.canCommunicateBySms = false;
      this.userRegister.canCommunicateByPostalService = false;
      this.userRegister.termMarketing = false;
    }
  }

  get optionMarketingCheckboxStatus() {
    return this.userRegister.canCommunicateByEmail ||
      this.userRegister.canCommunicateByPhone ||
      this.userRegister.canCommunicateBySms ||
      this.userRegister.canCommunicateByPostalService;
  }

  confirmPinFromEmail() {
    this.recaptchaV3Service.execute('sendPinSubmit').subscribe(
      (token) => {
        if (!this.offerToken){
          this.userSendPinFromEmail = new UserSendPinFromEmailModel();
          this.userSendPinFromEmail.offerNumber = this.PreValidateForm.controls.offerNr.value;
          this.userSendPinFromEmail.pin = this.ActivateCodeForm.controls.activationPin.value;
        } else {
          this.userSendPinFromEmail = new UserSendPinFromEmailModel();
          this.userSendPinFromEmail.offerNumber = null;
          this.userSendPinFromEmail.offerToken = this.offerToken;
          this.userSendPinFromEmail.pin = this.ActivateCodeForm.controls.activationPin.value;
        }
        this.apiService.validatePinEmail(this.userSendPinFromEmail, token).then(result => {
          const pinRequestRez: any = result;
          if (pinRequestRez.error === false) {
            this.setPass = true;
            this.register = false;
            this.preValidate = false;
            this.pinFromEmail = false;
          } else {
            this.pinFromEmailError = true;
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3) confirmPin'];
        // console.log(this.errors);
      });
  }

  public setPassForm(): void {
    this.recaptchaV3Service.execute('sendPinSubmit').subscribe(
      (token) => {
        this.userRegister = new UserRegisterPasswordModel();
        this.userRegister.email = this.userConfirmEmail.email;
        this.userRegister.gdpr = this.userConfirmEmail.gdpr;
        this.userRegister.terms = this.userConfirmEmail.terms;
        this.userRegister.password = this.SetPasswordForm.controls.password.value;
        this.userRegister.customerId = this.customerId;
        this.getMarketingStatus();
        this.apiService.registerUser(this.userRegister, token).then(result => {
          // console.log(result, 'userRegister');
          const setPassRequestRez: any = result;
          if (setPassRequestRez.error === false) {
            this.router.navigate(['/login']);
          } else if (setPassRequestRez.message === 'UserExist' || setPassRequestRez.status === 409){
            this.errorBackend = 'Pentru acest user ai creat deja cont. Te rog sa accesezi acest link <a\n' +
              '            href="https://asigurari.dacia.ro/login">asigurari.dacia.ro/login</a> pentru a te loga in aplicatie';
          } else {
            this.errorBackend = setPassRequestRez.error.message;
            console.log(setPassRequestRez);
          }
        });
      },
      (error) => {
        this.errors = ['Error trying to verify request (reCaptcha v3) setPassForm'];
        // console.log(this.errors);
      });
  }

  marketingModal() {
    const modalRef = this.modal.open(MarketingAgreementsModalComponent as Component, {
      size: 'sm', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.optionalUserData = receivedEntry;
      this.confirmEmailFormControls.marketingRegister.setValue(!!this.optionalUserData);
    });
    modalRef.componentInstance.backToMarketingAgreementsModal = this.optionalUserData;
  }

  openGDPRModal() {
    const modalRef = this.modal.open(GdprComponent as Component, {
      size: 'sm', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.fromModal = true;
  }

  openTermsConditionsModal() {
    const modalRef = this.modal.open(TermsConditionsComponent as Component, {
      size: 'sm', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.fromModal = true;
  }

  validatePass(input) {
    const valueOfInput = input.target.value;
    this.userNameValidation = !!valueOfInput.includes(this.userName);
    this.lastNameValidation = !!valueOfInput.includes(this.firstName);
    this.firstNameValidation = !!valueOfInput.includes(this.lastName);
  }

  get preValidateFormControls() {
    return this.PreValidateForm.controls;
  }

  get confirmEmailFormControls() {
    return this.ConfirmEmailForm.controls;
  }

  get activateCodeFormControls() {
    return this.ActivateCodeForm.controls;
  }

  get setPassFormControls() {
    return this.SetPasswordForm.controls;
  }

}
