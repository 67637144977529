import {Subscription} from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {POLICY_TYPES} from 'src/app/models/casco-table.model';
import {Store} from '@ngrx/store';
import {AppState, selectSelectedOfferState, selectUUIDCreditNote} from 'src/app/store';
import {Step} from 'src/app/models/stepper.models';
import {toggleJustMTPL} from '../../../store/actions/offerToggle.actions';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../shared/services/trigger-function-from-another-comp.service';
import {OfferErrorsService} from '../../../services/offer-errors.service';
import {take} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {PaymentService} from '../../../services/payment.service';

@Component({
  selector: 'app-marsh-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
})
export class StepperComponent implements OnInit {
  @Input() steps: Step[] = [];
  @Input() selectedStep: Step;
  type;
  faClose = faWindowClose;
  policyTypes = POLICY_TYPES;
  subCon: Subscription;
  sub: Subscription;
  showCASCOButtons: boolean;
  showPaymentButtons: number;
  showRCAButtons: boolean;
  packType: any;
  creditNoteUUid: string;
  wantsJustCASCO: any;
  constructor(
    private modal: NgbModal,
    private router: Router,
    private offerErrors: OfferErrorsService,
    private store: Store<AppState>,
    private paymentService: PaymentService,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService
  ) {
  }

  ngOnInit() {
    this.navigateTo(this.type);
    if (this.steps) {
      this.steps = this.steps.filter(step => step.isActive === true);
      this.RCAStep();
      this.CASCOStep();
      this.PaymentStep();
    } else {
      this.type = this.router.url;
      this.PaymentStep();
    }
  }

  navigateTo(type) {
    if (type === '/offer-summary') {
      this.router.navigate(['/offer-summary']);
    } else {
      this.router.navigate(['/offer'], {queryParams: {type}});
    }
  }

  get validateJustCASCO() {
    const selectedCascoItem = JSON.parse(sessionStorage.getItem('selectedCASCOOffer'));
    if (selectedCascoItem !== null) {
      this.packType = selectedCascoItem['0'][`packType`];
      this.wantsJustCASCO = this.packType === 'individual';
    }
    return this.wantsJustCASCO;
  }

  goToMtpl() {
    this.triggerFunctionFromAnotherCompService.sendClickEventGoToMTPL();
    this.close();
  }

  goToCASCO(type: string) {
    sessionStorage.setItem('policyMTPL', 'true');
    sessionStorage.setItem('policyCASCO', 'true');
    sessionStorage.setItem('justMtplButtonPressed', 'false');
    this.store.dispatch(toggleJustMTPL({toggleJustMTPL: false}));
    this.type = type;
    this.router.navigate(['/offer'], {queryParams: {type}});
    this.close();
  }

  gotoSummaryJustCASCO() {
    this.triggerFunctionFromAnotherCompService.sendClickEventJustCASCO();
    this.close();
  }

  goToJustMTPL() {
    this.triggerFunctionFromAnotherCompService.sendClickEventJustMTPL();
    this.close();
  }

  gotoSummary() {
    this.triggerFunctionFromAnotherCompService.sendClickEventGoToSummery();
    this.close();
  }

  RCAStep() {
    if (this.steps && this.steps.length > 0) {
      const rcaStep = this.steps.find(step => step.number === 2);
      if (rcaStep) {
        this.showRCAButtons = rcaStep.isActive;
      }
    }
  }

  get disableBackJustMTPL() {
    return JSON.parse(window.sessionStorage.getItem('policyMTPL'));
  }

  get disableBackToCASCO() {
    return (JSON.parse(window.sessionStorage.getItem('policyCASCO')) === false &&
        JSON.parse(window.sessionStorage.getItem('justMtplButtonPressed')) === true) ||
      (JSON.parse(window.sessionStorage.getItem('policyCASCO')) === true &&
        JSON.parse(window.sessionStorage.getItem('justMtplButtonPressed')) === false);
  }

  get userChosePackageOffer() {
    if (JSON.parse(window.sessionStorage.getItem('selectedCASCOOffer'))) {
      const selectedCASCOOffer = JSON.parse(window.sessionStorage.getItem('selectedCASCOOffer'));
      return selectedCASCOOffer['0'].packType === 'package';
    } else {
      return false;
    }
  }

  CASCOStep() {
    if (this.steps && this.steps.length > 0) {
      const cascoStep = this.steps.find(step => step.number === 1);
      if (cascoStep) {
        this.showCASCOButtons = cascoStep.isActive;
      }
    }
  }

  PaymentStep() {
    if (
      this.type === '/offer-summary' &&
      JSON.parse(window.sessionStorage.getItem('policyMTPL')) &&
      JSON.parse(window.sessionStorage.getItem('policyCASCO'))
    ) {
        this.showPaymentButtons = 1;
    } else if (
      this.type === '/offer-summary' &&
      JSON.parse(window.sessionStorage.getItem('policyMTPL')) &&
      !JSON.parse(window.sessionStorage.getItem('policyCASCO'))
    ) {
      this.showPaymentButtons = 3;
    }
  }

  close() {
    this.modal.dismissAll();
  }
}
