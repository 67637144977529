import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'marsh-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  showCookiePolicyContent = false;
  showGDPRContent = false;
  showContactContent = false;
  showPetitionContent = false;
  showTermsContent = true;

  appType = environment.app;
  @Input() public fromModal;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  icons = {
    chevronLeft: faChevronLeft
  };

  ngOnInit(): void {
    setTimeout(() => {
      if (this.fromModal === true) {
        const top = document.getElementById('top');
        top.scrollIntoView();
      }
    }, 800);
  }

  closeModal() {
    this.activeModal.close();
  }

  showCookiePolicy() {
    this.showCookiePolicyContent = true;
    this.showTermsContent = false;
  }

  showContact() {
    this.showTermsContent = false;
    this.showContactContent = true;
  }

  showPetitions() {
    this.showTermsContent = false;
    this.showPetitionContent = true;
  }

  showGDPR() {
    this.showTermsContent = false;
    this.showCookiePolicyContent = false;
    this.showGDPRContent = true;
  }

  backToTermsPolicy() {
    this.showGDPRContent = false;
    this.showPetitionContent = false;
    this.showContactContent = false;
    this.showTermsContent = true;
  }

}
