import { Component, OnInit } from '@angular/core';
import {InsuranceConditionsModalComponent} from '../../../../pages/offer/offer/insurance-conditions-modal/insurance-conditions-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'marsh-pid',
  templateUrl: './pid.component.html',
  styleUrls: ['./pid.component.css']
})
export class PIDComponent implements OnInit {
  env = environment.env;
  constructor(private modal: NgbModal) { }

  ngOnInit() { }

  openInsuranceConditionsModal(cascoProviderName){
    const modalRef = this.modal.open(InsuranceConditionsModalComponent as Component, {
      centered: true, size: 'lg'
    });
    modalRef.componentInstance.showAccept = false;
    modalRef.componentInstance.notFromOffer = true;
    modalRef.componentInstance.inputInsurer = cascoProviderName;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
