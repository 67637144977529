import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromAuth from './reducers/auth.reducers';
import * as fromOffer from './reducers/offer.reducers';
import * as fromSpinner from './reducers/spinner.reducers';
import * as fromSelectedOffer from './reducers/selectedOffer.reducer';
import * as fromOfferToggle from './reducers/offerToggle.reducers';
import * as fromPolicies from './reducers/policies.reducers';
import * as fromCars from './reducers/cars.reducers';
import {StateSelectedOffer} from './reducers/selectedOffer.reducer';
import {checkStateToggleOffer} from './reducers/offerToggle.reducers';

export interface AppState {
  authState: fromAuth.StateAuth;
  offerState: fromOffer.StateOffer;
  offerToggles: fromOfferToggle.StateToggleOffer;
  selectedOffer: fromSelectedOffer.StateSelectedOffer;
  policiesState: fromPolicies.StatePolicies;
  carsState: fromCars.StateCars;
  spinner: fromSpinner.StateSpinner;
}

export const reducers: ActionReducerMap<AppState> = {
  authState: fromAuth.reducer,
  offerState: fromOffer.reducer,
  offerToggles: fromOfferToggle.reducer,
  selectedOffer: fromSelectedOffer.reducer,
  policiesState: fromPolicies.reducer,
  carsState: fromCars.reducer,
  spinner: fromSpinner.reducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];


// Selectors

export const selectAuthState = createFeatureSelector<AppState, fromAuth.StateAuth>('authState');
export const selectOfferState = createFeatureSelector<AppState, fromOffer.StateOffer>('offerState');
export const selectSelectedOfferState = createFeatureSelector<AppState, fromSelectedOffer.StateSelectedOffer>('selectedOffer');
export const selectOfferTogglesState = createFeatureSelector<AppState, fromOfferToggle.StateToggleOffer>('offerToggles');
export const selectPolicies = createFeatureSelector<AppState, fromPolicies.StatePolicies>('policiesState');
export const selectCars = createFeatureSelector<AppState, fromCars.StateCars>('carsState');

export const selectIsOktaAuthenticated = createSelector(
  selectAuthState,
  fromAuth.selectIsOktaAuthenticated
);
export const selectOktaToken = createSelector(
  selectAuthState,
  fromAuth.selectOktaToken
);
export const selectApigeeToken = createSelector(
  selectAuthState,
  fromAuth.selectApigeeToken
);

export const selectUserDetails = createSelector(
  selectAuthState,
  fromAuth.selectUserDetails
);

export const selectPreValidateUserDetails = createSelector(
  selectAuthState,
  fromAuth.selectPreValidateUserDetails
);

export const selectOfferDataCASCO = createSelector(
  selectOfferState,
  fromOffer.selectOfferCASCOReducer
);

export const selectOfferDataMTPL = createSelector(
  selectOfferState,
  fromOffer.selectOfferMTPLReducer
);

export const selectOfferData = createSelector(
  selectOfferState,
  fromOffer.selectOfferReducer
);

export const selectOfferExpired = createSelector(
  selectOfferState,
  fromOffer.selectOfferExpiredReducer
);

export const selectOfferExpiredWarningStatus = createSelector(
  selectOfferState,
  fromOffer.selectOfferExpiredWarningStatusReducer
);


export const selectOfferListData = createSelector(
  selectOfferState,
  fromOffer.selectOfferListReducer
);

export const selectOfferDataCustomerId = createSelector(
  selectOfferState,
  fromOffer.selectOfferCustomerIdReducer
);

export const selectOfferDataCustomer = createSelector(
  selectOfferState,
  fromOffer.selectOfferCustomerDataReducer
);

export const selectOfferDataVehicle = createSelector(
  selectOfferState,
  fromOffer.selectOfferVehicleDataReducer
);

export const selectOfferDataVehicleId = createSelector(
  selectOfferState,
  fromOffer.selectOfferVehicleIdDataReducer
);

export const selectSelectedOfferCASCO = createSelector(
  selectSelectedOfferState,
  fromSelectedOffer.selectOfferCASCOReducer
);

export const selectSelectedOfferMTPL = createSelector(
  selectSelectedOfferState,
  fromSelectedOffer.selectOfferMTPLReducer
);

export const selectUUIDCreditNote = createSelector(
  selectSelectedOfferState,
  fromSelectedOffer.selectUUIDCreditNote
);

export const selectSelectedCascoInsurer = createSelector(
  selectSelectedOfferState,
  fromSelectedOffer.selectCascoInsurerReducer
);

export const selectActiveCascoAndMTPL = createSelector(
  selectSelectedOfferState,
  (state: StateSelectedOffer) => {
    return {
      casco: state.selectedOfferCASCO,
      mtpl: state.selectedOfferMTPL
    };
  },
);

export const selectOfferCartInformation = createSelector(
  selectOfferState,
  (state) => {
    return {
      cascoStartDate: state.offer.startCascoDateSource,
      mtplStartDate: state.offer.startMtplDateSource
    };
  }
);

export const selectIsCASCOSelected = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfCASCOSelectedReducer
);

export const selectIsMTPLSelected = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfMTPLSelectedReducer
);

export const selectIsTerms = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfTermsSelectedReducer
);

export const selectWantsConsultancyCASCO = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfWantsConsultancyCASCOReducer
);

export const selectWantsConsultancyMTPL = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfWantsConsultancyMTPLReducer
);

export const selectWantsJustCASCO = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfWantsJustCASCOReducer
);

export const selectAllTogglesState = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkStateToggleOffer
);

export const selectWantsJustMTPL = createSelector(
  selectOfferTogglesState,
  fromOfferToggle.checkIfWantsJustMTPLReducer
);

export const selectMyPolicies = createSelector(
  selectPolicies,
  fromPolicies.fetchMyPolicies
);

export const selectUnPayedInstallment = createSelector(
  selectPolicies,
  fromPolicies.fetchUnPayedInstallment
);

// export const selectMyActivePoliciesCount = createSelector(
//   selectPolicies,
//   (state) => {
//     return {
//       cascoStartDate: state.offer.startCascoDateSource,
//       mtplStartDate: state.offer.startMtplDateSource
//     };
//   }
// );

export const selectPolicyInstallments = createSelector(
  selectPolicies,
  fromPolicies.fetchMyPolicyInstallments
);

export const selectPolicyPaymentConfirmationData = createSelector(
  selectPolicies,
  fromPolicies.fetchPolicyConfirmationData
);

export const selectPolicyHistory = createSelector(
  selectPolicies,
  fromPolicies.fetchMyPolicyHistory
);

export const selectPoliciesToDownload = createSelector(
  selectPolicies,
  fromPolicies.fetchMyPolicesToDownload
);

export const selectPayInstallmentAndCar = createSelector(
  selectPolicies,
  fromPolicies.fetchPayInstallmentAndCar
);

export const selectMyCars = createSelector(
  selectCars,
  fromCars.fetchMyCars
);
