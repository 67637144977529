import { createAction, props } from '@ngrx/store';

export enum SelectedOfferActionTypes {
  SET_SELECTED_OFFER_CASCO = '[Offer Data] Set selected CASCO offer data',
  SET_SELECTED_OFFER_MTPL = '[Offer Data] Set selected MTPL offer data',
  SET_SELECTED_CASCO_INSURER = '[Offer Data] Set selected CASCO insurer data',
  SET_CREDIT_NOTE_UUID = '[Offer Data] Set UUID Credit Note',
}

export const setSelectedOfferCASCO = createAction(
  SelectedOfferActionTypes.SET_SELECTED_OFFER_CASCO,
  props<{offerSelectedCASCO: any}>()
);

export const setSelectedOfferMTPL = createAction(
  SelectedOfferActionTypes.SET_SELECTED_OFFER_MTPL,
  props<{offerSelectedMTPL: any}>()
);

export const setSelectedCascoInsurer = createAction(
  SelectedOfferActionTypes.SET_SELECTED_CASCO_INSURER,
  props<{selectedCascoInsurer: string}>()
);

export const setCreditNoteUUID = createAction(
  SelectedOfferActionTypes.SET_CREDIT_NOTE_UUID,
  props<{setCreditNoteUUID: any}>()
);
