import {Action} from '@ngrx/store';
import { CascoData } from 'src/app/models/casco-table.model';
import {OfferActionTypes} from '../actions/offer.actions';
export interface StateOffer {
  offer: any;
  offerList: any[];
  offerCustomerId: any;
  offerCustomerData: any;
  offerVehicleId: any;
  offerVehicleData: any;
  offerCASCO: any;
  offerMTPL: any;
  offerExpired: any;
  warningStatus: any;
}

export const initialState: StateOffer = {
  offer: null,
  offerList: null,
  offerCustomerId: null,
  offerCustomerData: null,
  offerVehicleId: null,
  offerVehicleData: null,
  offerCASCO: new CascoData(),
  offerMTPL: null,
  offerExpired: null,
  warningStatus: false,
};

export function reducer(stateOffer = initialState, action: Action): StateOffer {
  switch (action.type) {
    case OfferActionTypes.FETCH_OFFER_DATA:
      return {
        ...stateOffer
      };
    case OfferActionTypes.FETCH_OFFER_DATA_SUCCESS:
      return {
        ...stateOffer,
        offer: action[`offer`]
      };
    case OfferActionTypes.FETCH_OFFER_LIST_DATA:
      return {
        ...stateOffer
      };
    case OfferActionTypes.FETCH_OFFER_LIST_DATA_SUCCESS:
      return {
        ...stateOffer,
        offerList: action[`offerList`]
      };
    case OfferActionTypes.FETCH_OFFER_DATA_CASCO:
      return {
        ...stateOffer
      };
    case OfferActionTypes.FETCH_OFFER_DATA_CASCO_SUCCESS:
      return {
        ...stateOffer,
        offerCASCO: action[`offerCASCO`]
      };
    case OfferActionTypes.FETCH_OFFER_DATA_MTPL:
      return {
        ...stateOffer
      };
    case OfferActionTypes.FETCH_OFFER_DATA_MTPL_SUCCESS:
      return {
        ...stateOffer,
        offerMTPL: action[`offerMTPL`]['0']
      };
    case OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER:
      return {
        ...stateOffer
      };
    case OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER_SUCCESS:
      return {
        ...stateOffer,
        offerCustomerData: action[`offerCustomerData`]
      };
    case OfferActionTypes.FETCH_OFFER_DATA_CUSTOMER_ID:
      return {
        ...stateOffer,
        offerCustomerId: action[`offerCustomerId`]
      };
    case OfferActionTypes.FETCH_OFFER_DATA_VEHICLE:
      return {
        ...stateOffer,
      };
    case OfferActionTypes.FETCH_OFFER_DATA_VEHICLE_SUCCESS:
      return {
        ...stateOffer,
        offerVehicleData: action[`offerVehicleData`]
      };
    case OfferActionTypes.FETCH_OFFER_DATA_VEHICLE_ID:
      return {
        ...stateOffer,
        offerVehicleId: action[`offerVehicleId`]
      };
    case OfferActionTypes.FETCH_IS_OFFER_EXPIRED:
      return {
        ...stateOffer,
        offerExpired: action[`offerExpired`]
      };
    case OfferActionTypes.FETCH_OFFER_EXPIRED_WARNING_STATUS:
      return {
        ...stateOffer,
        warningStatus: action[`warningStatus`]
      };
    default:
      return stateOffer;
  }
}

export const selectOfferReducer = (stateOffer: StateOffer) => stateOffer.offer;
export const selectOfferListReducer = (stateOffer: StateOffer) => stateOffer.offerList;
export const selectOfferCASCOReducer = (stateOffer: StateOffer) => stateOffer.offerCASCO;
export const selectOfferMTPLReducer = (stateOffer: StateOffer) => stateOffer.offerMTPL;
export const selectOfferCustomerIdReducer = (stateOffer: StateOffer) => stateOffer.offerCustomerId;
export const selectOfferCustomerDataReducer = (stateOffer: StateOffer) => stateOffer.offerCustomerData;
export const selectOfferVehicleIdDataReducer = (stateOffer: StateOffer) => stateOffer.offerVehicleId;
export const selectOfferVehicleDataReducer = (stateOffer: StateOffer) => stateOffer.offerVehicleData;
export const selectOfferExpiredReducer = (stateOffer: StateOffer) => stateOffer.offerExpired;
export const selectOfferExpiredWarningStatusReducer = (stateOffer: StateOffer) => stateOffer.warningStatus;
