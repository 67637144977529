import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState, selectWantsConsultancyCASCO, selectWantsConsultancyMTPL} from '../../../../store';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import {ActivatedRoute} from '@angular/router';
import {ConsultancyComponent} from '../consultancy-info-modal/consultancy.component';
import {toggleForConsultancyCASCO, toggleForConsultancyMTPL} from '../../../../store/actions/offerToggle.actions';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';

@Component({
  selector: 'app-want-consultancy-mtpl',
  templateUrl: './want-consultancy-mtpl.component.html',
  styleUrls: ['./want-consultancy-mtpl.component.scss']
})
export class WantConsultancyMtplComponent implements OnInit {

  acceptCond: boolean;
  @Input() wantConsultancy: boolean;
  @Input() fromPage: string;
  sub: Subscription;
  triggerCheckIfConsultancyMTPLIsCheckedFromAnotherCompSubscription: Subscription;
  valueMTPLConsultancy: boolean;

  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private offerErrorsService: OfferErrorsService,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private route: ActivatedRoute) {
    this.acceptCond = null;
    this.triggerCheckIfConsultancyMTPLIsCheckedFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckIfConsultancyMTPLIsChecked().subscribe(() => {
        this.isMTPLConsultancyChecked();
      });
  }


  ngOnInit(): void {
    this.setConsultancy(this.acceptCond);
    if (this.fromPage === 'rca') {
      this.store.select(selectWantsConsultancyMTPL).subscribe(wantConsultancy => {
        this.acceptCond = wantConsultancy;
      });
    }
  }

  consultancyStatus() {
    if (this.fromPage === 'rca') {
      this.store.select(selectWantsConsultancyMTPL).subscribe(
        rez => {
          this.acceptCond = rez;
        });
    }

    return this.acceptCond;
  }

  isMTPLConsultancyChecked() {
    if (this.fromPage === 'rca') {
      this.offerErrorsService.wantConsultancyMTPL.subscribe(status => {
        if (status || !status) {
          this.valueMTPLConsultancy = status;
        } else {
          this.valueMTPLConsultancy = null;
        }
      });
    }
  }

  openConsultancy() {
    const modalRef = this.modal.open(ConsultancyComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.fromPage = this.fromPage;
    modalRef.result.then((result) => {
      this.consultancyStatus();
    });
  }

  setConsultancy(option) {
    if (this.fromPage === 'rca') {
      this.store.dispatch(toggleForConsultancyMTPL({wantsConsultancyMTPL: option}));
    }
  }
}
