import {Component, OnDestroy, OnInit} from '@angular/core';
import {MonthMapping, WarningTypeEnum} from '../../../models/mapping.model';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState, selectOfferCartInformation, selectUUIDCreditNote} from 'src/app/store';
import {take} from 'rxjs/operators';
import {PaymentService} from '../../../services/payment.service';
import {POLICY_TYPES} from '../../../models/casco-table.model';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../services/local-storage.service';
import {Subscription} from 'rxjs';
import {TriggerFunctionFromAnotherCompService} from '../../../shared/services/trigger-function-from-another-comp.service';
import Utils from '../../../shared/utils/utils';

@Component({
  selector: 'app-offer-summary',
  templateUrl: './offer-summary.component.html',
  styleUrls: ['./offer-summary.component.scss']
})
export class OfferSummaryComponent implements OnInit, OnDestroy {
  warningTypesEnums = WarningTypeEnum;
  selectedCascoOffer: any;
  selectedCascoOfferInit: any;
  triggerCheckGoTOPaymentFromAnotherCompSubscription: Subscription;
  getCreditNoteDataSubscription: Subscription;
  selectedMTPLOffer: any;
  selectedMTPLOfferInit: any;
  cascoStartDate;
  gridItemId;
  mtplStartDate;
  creditNoteUUid: string;
  cart: { items: any[], casco: any, mtpl: any } = {
    items: [],
    casco: null,
    mtpl: null
  };
  isMobile: boolean;

  selectedCASCOOffer;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private localStorageService: LocalStorageService,
              private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
              private store: Store<AppState>,
              private paymentService: PaymentService) {
    this.isMobile = Utils.checkIfMobile();
    this.triggerCheckGoTOPaymentFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckExpiredOffer().subscribe(() => {
        this.goToPaymentPage();
      });
  }

  // http://localhost:5000/payment-confirmation/f0495392-96fe-459d-8173-5400666c9378?&orderId=f0495392-96fe-459d-8173-5400666c9378

  ngOnInit(): void {
    this.getProducts();
    this.store.pipe(
      select(selectOfferCartInformation)
    ).subscribe(data => {
      this.cascoStartDate = this.getStartDate(data.cascoStartDate) ;
      this.mtplStartDate = this.getStartDate(data.mtplStartDate);
    });
  }

  ngOnDestroy() {
    if (this.getCreditNoteDataSubscription) {
      this.getCreditNoteDataSubscription.unsubscribe();
    }
    if (this.triggerCheckGoTOPaymentFromAnotherCompSubscription) {
      this.triggerCheckGoTOPaymentFromAnotherCompSubscription.unsubscribe();
    }
  }

  getStartDate(date?: string) {
    if (date) {
      const getDate = string => (([year, month, day]) => ({
        year,
        month: MonthMapping[month - 1],
        day
      }))(string.split('-'));
      return getDate(date);
    }
  }

  getProducts() {
    this.paymentService.getActiveCartItems().subscribe(items => {
      this.selectedCascoOffer = items.casco ? items.casco[0] : null;
      this.selectedMTPLOffer = items.mtpl ? items.mtpl[0] : null;

      this.selectedCascoOfferInit = items.casco ? items.casco[0] : null;
      this.selectedMTPLOfferInit = items.mtpl ? items.mtpl[0] : null;
      if (!this.selectedCascoOffer && !this.selectedMTPLOffer) {
        this.goBack();
      }
      // console.log('selected stuff:', this.selectedCascoOffer, this.selectedMTPLOffer);
      this.getCreditNoteDataSubscription = this.paymentService.getCreditNoteData().subscribe(note => {
        if (note) {
          this.cart.items = note as any[];
          (note as any[]).map(item => {
            if (typeof this.selectedCascoOffer?.gridItemId === 'string') {
              this.gridItemId = this.selectedCascoOffer?.gridItemId.replace(/P/g, '');
            } else {
              this.gridItemId = this.selectedCascoOffer?.gridItemId;
            }
            if (this.selectedCascoOffer && +this.gridItemId === item.offerProduct.id) {
              this.cart.casco = item;
              // console.log('this.cart.casco:', this.cart.casco);

            }
            if (this.selectedMTPLOffer && +this.selectedMTPLOffer?.productId === item.offerProduct.id) {
              this.cart.mtpl = item;
              // console.log('this.cart.mtpl:', this.cart.mtpl);

            }
          });
        }

      });

    });
  }

  get currency() {
    return this.cart.items[0].offerProduct.product.currency.name;
  }

  get exchangeRate() {
    return this.cart.items[0].exchangeRate;
  }

  get totalPayment() {
    return this.cart
      .items
      .map(item => +item.dueValue)
      .reduce((prev, current) => prev + current);
  }

  goToPaymentPage() {
    this.store.select(selectUUIDCreditNote).pipe(take(1)).subscribe(offerUUID => {
      this.creditNoteUUid = offerUUID;
    });
    this.paymentService.preparePayment(this.creditNoteUUid).subscribe(preparePayment => {
      if (preparePayment[`error`] === true) {
        this.toastrService.error('Ne pare rău, a intervenit o eroare. Te rugăm să ne contactezi la adresa de mail asigurare.cobrand@marsh.com sau la numărul de telefon 021.380 85 53 de luni până vineri în intervalul 9.00-18.00.', '', {
          timeOut: 8000,
        });
      } else {
        this.paymentService.redirectByPost(preparePayment[`data`], environment.netopiaUrl);
      }
    });
  }

  goBack() {
    if (this.selectedCascoOffer && this.selectedMTPLOffer) {
      const type = POLICY_TYPES.rca;
      this.router.navigate(['/offer'], {queryParams: {type}});
    } else if (!this.selectedCascoOffer && this.selectedMTPLOffer) {
      const type = POLICY_TYPES.rca;
      this.router.navigate(['/offer'], {queryParams: {type}});
    } else if (this.selectedCascoOffer && !this.selectedMTPLOffer) {
      const type = POLICY_TYPES.casco;
      this.router.navigate(['/offer'], {queryParams: {type}});
    } else {
      this.router.navigate(['/home']);
    }
  }

}
