import { Component, OnInit } from '@angular/core';
import {faArrowCircleDown, faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-marsh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  icoBool = false;

  constructor() {
  }
  ngOnInit() {
  }
  alert(){
    // hey dawg I heard you like alerts
    alert('so I put an alert inside ur alert so you can alert while alerting');
  }

  changeIcon(el: HTMLElement) {
    setTimeout(() => {
      if (this.icoBool === true) {
        el.scrollIntoView();
      }
    }, 800);


    this.icoBool = !this.icoBool;
  }

}
