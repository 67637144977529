import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private errorManagement: any;

  constructor(
    private http: HttpClient,
  ) {
  }
  baseUri = environment.apiUrl;

  private static errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        // this.router.navigate(['logout']);
      }
      // Get server-side error
      errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  async preValidateUser(body, token) {
    const url = `${this.baseUri}/online/pre-validate`;
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((data) => {
          res(data);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  resetOfferSessionStorageAndStore() {
    sessionStorage.removeItem('selectedMTPLOffer');
    sessionStorage.removeItem('selectedCASCOOffer');
    sessionStorage.removeItem('offerId');
    sessionStorage.removeItem('policyMTPL');
    sessionStorage.removeItem('policyCASCO');
    sessionStorage.removeItem('showToast');
    sessionStorage.removeItem('selectedProduct');
  }

  unsubscribeNotificationFromEmail(data, token){
    const url = `${this.baseUri}/online/unsubscribe`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
        });
    });
  }

  async validateUserEmail(body, token) {
    const url = `${this.baseUri}/online/send-email-check`;
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((data) => {
          res(data);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  async validatePinEmail(body, token) {
    const url = `${this.baseUri}/online/validate-email`;
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((data) => {
          res(data);
        })
        .catch((e) => {
        });
    });
  }

  async registerUser(data, token){
    const url = `${this.baseUri}/online/register`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
          console.log(e)
        });
    });
  }

  async LoginUser(data, token){
    const url = `${this.baseUri}/online/login`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
          console.log("Error "+e);
        });
    });
  }

  async MFACheck(data, token){
    const url = `${this.baseUri}/online/mfa-check`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
        });
    });
  }

  sessionData(){
    const url = `${this.baseUri}/tools/session-data`;
    return this.http.post(url, null).pipe(
      catchError(this.errorManagement)
    );
  }

  logoutSession(){
    const url = `${this.baseUri}/online/logout`;
    return this.http.post(url, null).pipe(
      catchError(this.errorManagement)
    );
  }

  getRefreshToken(){
    const url = `${this.baseUri}/online/refresh-token`;
    return this.http.post(url, null).pipe(
      catchError(this.errorManagement)
    );
  }

  async ChangeUserPassword(data, token){
    const url = `${this.baseUri}/online/reset-password`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
        });
    });
  }

  ChangeUserPasswordMyAccount(data){
    const url = `${this.baseUri}/online/change-password`;
    return this.http.post<any[]>(url, data).pipe(
      catchError(ApiService.errorManagement)
    );
  }

  async RequestResetPassword(data, token){
    const url = `${this.baseUri}/online/initiate-reset-password`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Recaptcha: token,
        'Content-Type': 'application/json',
      },
    };
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then((dataRes) => {
          res(dataRes);
        })
        .catch((e) => {
        });
    });
  }

  reportIncorrectObjectData(body) {
    const url = `${this.baseUri}/tools/report-incorrect-object-data`;
    return this.http.post(url, body)
      .pipe(
        catchError(ApiService.errorManagement)
      );
  }

  downloadAgreementFile(customerID, agreementID) {
    const url = `${this.baseUri}/agreements/${customerID}/files/${agreementID}`;
    return this.http.get<Blob>(url, {responseType: 'blob' as any});
  }

  downloadCertificatePdf(policyId) {
    const url = `${this.baseUri}/policies/pdf/${policyId}`;
    return this.http.get<Blob>(url, {responseType: 'blob' as any});
  }

  payInstallment(installmentId) {
    const url = `${this.baseUri}/credit_notes/pay-installment/${installmentId}`;
    return this.http.post<any>(url, {}).pipe(
      catchError(ApiService.errorManagement)
    );
  }
}
