export class UserChangePasswordModel{
  emailToken: string;
  newPassword: string;
  email: string;
  passwordConfirm: string;
  objectEnvironment: string;
}

export class UserChangePasswordFromMyAccountModel{
  oldPassword: string;
  newPassword: string;
  objectEnvironment: string;
}
