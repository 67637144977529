import {Component, Input, OnInit} from '@angular/core';
import {ConsultancyComponent} from '../consultancy-info-modal/consultancy.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import {Store} from '@ngrx/store';
import {AppState, selectWantsConsultancyCASCO, selectWantsConsultancyMTPL} from '../../../../store';
import {toggleForConsultancyCASCO, toggleForConsultancyMTPL} from '../../../../store/actions/offerToggle.actions';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';

@Component({
  selector: 'app-want-consultancy-casco',
  templateUrl: './want-consultancy-casco.component.html',
  styleUrls: ['./want-consultancy-casco.component.scss']
})
export class WantConsultancyCascoComponent implements OnInit {
  acceptCond: boolean;
  @Input() wantConsultancy: boolean;
  @Input() fromPage: string;
  sub: Subscription;
  triggerCheckIfConsultancyCASCOIsCheckedFromAnotherCompSubscription: Subscription;
  valueConsultancyChecked: boolean;

  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private offerErrorsService: OfferErrorsService,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private route: ActivatedRoute) {
    this.acceptCond = null;
    this.triggerCheckIfConsultancyCASCOIsCheckedFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckIfConsultancyCASCOIsChecked().subscribe(() => {
        this.isConsultancyChecked();
      });
  }


  ngOnInit(): void {
    this.setConsultancy(this.acceptCond);
    if (this.fromPage === 'casco') {
      this.store.select(selectWantsConsultancyCASCO).subscribe(wantConsultancy => {
        this.acceptCond = wantConsultancy;
      });
    }
  }

  consultancyStatus() {
    if (this.fromPage === 'casco') {
      this.store.select(selectWantsConsultancyCASCO).subscribe(
        rez => {
          this.acceptCond = rez;
        });
    }
    return this.acceptCond;
  }

  isConsultancyChecked() {
    if (this.fromPage === 'casco') {
      this.offerErrorsService.wantConsultancyCASCO.subscribe(status => {
        if (status || !status){
          this.valueConsultancyChecked = status;
        } else {
          this.valueConsultancyChecked = null;
        }
      });
    }
  }

  openConsultancy() {
    const modalRef = this.modal.open(ConsultancyComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.fromPage = this.fromPage;
    modalRef.result.then((result) => {
      this.consultancyStatus();
    });
  }

  setConsultancy(option) {
    if (this.fromPage === 'casco') {
      this.store.dispatch(toggleForConsultancyCASCO({wantsConsultancyCASCO: option}));
    }
  }

}
