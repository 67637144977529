import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'conditions-with-chapters-omniasig',
  templateUrl: './conditions-with-chapters-omniasig.component.html',
  styleUrls: ['./conditions-with-chapters-omniasig.component.scss']
})
export class ConditionsWithChaptersOmniasigComponent implements OnInit{
  appType = environment.app;
  @Input() chapter: number;
  ngOnInit(){
    // console.log('Omniasig ', this.chapter);
  }
}
