import { Component, OnInit } from '@angular/core';
import {InsuranceConditionsModalComponent} from '../insurance-conditions-modal/insurance-conditions-modal.component';
import {AcceptConditionsInsurerService} from '../../../../services/accept-conditions-insurer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {POLICY_TYPES} from '../../../../models/casco-table.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import {AppState, selectIsTerms, selectSelectedOfferCASCO} from '../../../../store';
import {Store} from '@ngrx/store';
import {toggleTermsAndConditions} from '../../../../store/actions/offerToggle.actions';
import {faCheck, faCheckSquare, faSquare} from '@fortawesome/free-solid-svg-icons';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';

@Component({
  selector: 'app-accept-conditions-insurer',
  templateUrl: './accept-conditions-insurer.component.html',
  styleUrls: ['./accept-conditions-insurer.component.scss']
})
export class AcceptConditionsInsurerComponent implements OnInit {
  insuranceCond = false;

  sub: Subscription;
  policyTypes = POLICY_TYPES;
  type: any = POLICY_TYPES.casco;
  conditionsChecked: boolean;
  triggerCheckIfTermsIsCheckedFromAnotherCompSubscription: Subscription;
  cascoProviderName: string;
  icons = {
    checkmarkOn: faCheckSquare,
    checkmarkOff: faSquare
  };
  constructor(
    private acceptConditionsInsurer: AcceptConditionsInsurerService,
    private modal: NgbModal,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private offerErrors: OfferErrorsService)
  {
    this.cascoProviderName = null;
    this.conditionsChecked = null;
    this.triggerCheckIfTermsIsCheckedFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckIfTermsIsChecked().subscribe(() => {
        this.isInsurerConditionsChecked();
      });
  }

  ngOnInit(): void {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        if (params.type) {
          this.type = params.type;
        }
      });
  }

  isInsurerConditionsChecked() {
    this.offerErrors.acceptInsurerConditions.subscribe( status => {
      if (status) {
        this.conditionsChecked = status;
      } else {
        this.conditionsChecked = false;
      }
    });
  }

  get cascoProviderNameGetter() {
    this.store.select(selectSelectedOfferCASCO).subscribe( rez => {
      if (rez && rez[0]) {
        if (this.cascoProviderName !== rez[0][`cascoProviderName`]) {
          this.cascoProviderName = rez[0][`cascoProviderName`];
          this.store.dispatch(toggleTermsAndConditions({toggleTermsAndConditions: false}));
        }
      } else {
        this.cascoProviderName = null;
      }
    });
    return this.cascoProviderName;
  }

  setTermsAndConditions() {
    this.store.dispatch(toggleTermsAndConditions({toggleTermsAndConditions: false}));
  }

  get isTermsSelected() {
    this.store.select(selectIsTerms).subscribe( rez => {
      this.insuranceCond = rez;
    });
    return this.insuranceCond;
  }

  openInsuranceConditionsModal(){
    const modalRef = this.modal.open(InsuranceConditionsModalComponent as Component, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.showAccept = true;
    modalRef.componentInstance.inputInsurer = this.cascoProviderName;
    modalRef.result.then((result) => {
      // console.log(result, 'asdasdasdasd');
    }, (reason) => {
    });
  }

}
