import {Injectable} from '@angular/core';
import {AppStorageService} from './app-storage.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {dictionary} from '../dictionary';
import {ApigeeService} from './apigee.service';
import {OktaAuthService} from '@okta/okta-angular';
import {SessionDataService} from './session-data.service';

@Injectable()
export class TranslationService {
  public language = -1;
  private baseUri = environment.apiUrl;
  private translations: { [key: string]: string };
  private subject = new BehaviorSubject(null);

  constructor(
    private appStorageService: AppStorageService,
    private http: HttpClient,
    private apigeeService: ApigeeService,
    private oktaAuthService: OktaAuthService,
    private sessionDataService: SessionDataService,
  ) {
    // this.subject.next(this.appStorageService.getTranslations());
    // this.subject.subscribe((data) => {
    //   this.translations = data;
    // });
  }

  translate(label: string): string {
    if (this.translations && this.translations[label]) {
      return this.translations[label];
    } else {
      if (this.translations && !this.translations[label]) {
        this.appStorageService.setDictionary(label);
      }
      return label;
    }
  }

  async checkTranslations() {
    if (!this.translations) {
      await this.getTranslatedData();
    }
  }

  async getTranslatedData(apigeeToken = null, sessionData = null) {
    // console.log('getTranslatedData: ', apigeeToken, sessionData);
    const oktaToken = await this.oktaAuthService.getAccessToken();

    if (!apigeeToken)
      apigeeToken = await this.apigeeService.getToken('translation');
    if (!sessionData)
      sessionData = await this.sessionDataService.getSessionData('translation');

    const body = {
      phrases: dictionary,
      language: sessionData.displayLanguageSelected,
      languageGroup: sessionData.languageGroup,
    };
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'MARSH-SPA userAccessToken=' + oktaToken + ',apiGatewayAccessToken=' + apigeeToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    const url = `${this.baseUri}/tools/translate-phrases`;
    return new Promise((res, rej) => {
      fetch(url, options)
        .then(response => response.json())
        .then(async (data) => {
          // await this.appStorageService.setTranslations(data);
          this.translations = data;
          res(data);
        })
        .catch((e) => {
        });
    });
  }
}
