import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  showCookiePolicyContent = false;
  showGDPRContent = false;
  showCookieModule = true;
  icons = {
    chevronLeft: faChevronLeft
  };
  constructor(
    private cookieService: CookieService,
    private modal: NgbModal,
  ) {
    // this.cookieService.set('acceptCookies', 'true', 30);
  }

  ngOnInit(): void {
  }

  acceptCookies() {
    this.cookieService.set('acceptCookies', 'true', 30);
    this.modal.dismissAll();
  }

  showCookiePolicy() {
    this.showCookiePolicyContent = true;
    this.showCookieModule = false;
  }

  backShowCookieModule() {
    this.showCookiePolicyContent = false;
    this.showCookieModule = true;
  }

  showGDPR() {
    this.showCookiePolicyContent = false;
    this.showGDPRContent = true;
  }

  backToCookiePolicy() {
    this.showCookiePolicyContent = true;
    this.showGDPRContent = false;
  }

}
