import {Component, Input} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'conditions-with-chapters-alianz',
  templateUrl: './conditions-with-chapters-alianz.component.html',
  styleUrls: ['./conditions-with-chapters-alianz.component.scss']
})
export class ConditionsWithChaptersAlianzComponent {
  appType = environment.app;
  @Input() chapter: number;
}
