import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserAgreementsComponent} from './user-agreements/user-agreements.component';
import {PoliciesListComponent} from './user-policy/policies-list/policies-list.component';
import { UserCarDetailsComponent } from './user-car-details/user-car-details.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserCarSelectorComponent } from './user-car-selector/user-car-selector.component';
import { UserSubscriptionsComponent } from './user-subscriptions/user-subscriptions.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        RouterModule,
        NgbModule,
        NgbModule,
        SharedModule,
        ReactiveFormsModule,
        BrowserModule
    ],
  declarations:
    [ UserAgreementsComponent,
      PoliciesListComponent,
      UserCarDetailsComponent,
      UserCarSelectorComponent,
      UserSubscriptionsComponent
    ],
  exports:
    [ UserAgreementsComponent,
      PoliciesListComponent,
    ]
})
export class UserModule {
}
