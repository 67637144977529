export class PaymentConfirmed{
  rata: string;
  nrPolita: string;
  EUR: string;
  lei: string;
  date: string;
  dueDate: string;
  ora: string;
  type: string;
}


