export class UserDetailsConfig{
  CNP: string;
  id: number;
  prenume: string;
  nume: string;
  email: string;
  generalLocation: UserGeneralLocation;
  exactLocation: UserExactLocation;
}

export class UserGeneralLocation{
  judet: string;
  oras: string;
  datapermis: string;
}

export class UserExactLocation {
  str: string;
  nr: string;
  bl: string;
  ap: string;
  phone: string;
}

export class UserPetitionDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.email = '';
    this.phone = '';
  }
}

export class UserNotificationSubscriptions {
  isOffersSubscribed: boolean;
  isInstalmentsSubscribed: boolean;
  isOthersSubscribed: boolean;
  isOfferRenewalSubscribed: boolean;
  canCommunicateByEmail: boolean;
  canCommunicateByPhone: boolean;
  canCommunicateByPostalService: boolean;
  canCommunicateBySms: boolean;
  termMarketing: boolean;
  constructor(){
    this.isOffersSubscribed = false;
    this.isInstalmentsSubscribed = false;
    this.isOthersSubscribed = false;
    this.isOfferRenewalSubscribed = false;
    this.canCommunicateByEmail = false;
    this.canCommunicateByPhone = false;
    this.canCommunicateByPostalService = false;
    this.canCommunicateBySms = false;
    this.termMarketing = false;

  }
}

export class UserCanCommunicateRegister {
  canCommunicateByEmail: boolean;
  canCommunicateByPhone: boolean;
  canCommunicateByPostalService: boolean;
  canCommunicateBySms: boolean;
  constructor(){
    this.canCommunicateByEmail = true;
    this.canCommunicateByPhone = true;
    this.canCommunicateByPostalService = true;
    this.canCommunicateBySms = true;
  }
}
