import {
  RequestConsultantComponent
} from '../../../../shared/components/request-consultant/request-consultant.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReactivatePolicyComponent} from 'src/app/pages/user/user-policy/reactivate-policy/reactivate-policy.component';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import {MyPoliciesService} from '../../../../shared/services/myPoliciesService/my-policies.service';
import {
  AppState,
  selectMyCars,
  selectMyPolicies,
  selectOfferDataCustomer,
} from '../../../../store';
import {Store} from '@ngrx/store';
import {ApiService} from '../../../../services/api.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {
  CancelPolicyConfirmComponent
} from '../../../../shared/components/cancel-policy-confirm/cancel-policy.component';
import {PaymentService} from '../../../../services/payment.service';
import {
  fetchInstallmentToPayAndCar,
  fetchPolicyInstallmentsSuccess
} from '../../../../store/actions/policies.actions';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-marsh-policies',
  templateUrl: './policies-list.component.html',
  styleUrls: ['./policies-list.component.css']
})
export class PoliciesListComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  activeTab: any = 0;
  policiesList: any;
  selectMyPoliciesSub: Subscription;
  getPoliciesInstallmentSub: Subscription;
  error = false;
  MTPLPoliciesAndInstallment: any;
  MTPLPolicies: any;
  CASCOPolicies: any;
  CASCOPoliciesAndInstallment: any;
  isLoading: boolean;
  warningTypesEnums = WarningTypeEnum;
  customerId: any;
  isPolicyDownloading = false;
  noOlderPolicies = false;
  today = new Date();
  appType = environment.app;

  constructor(private modal: NgbModal,
              private myPoliciesService: MyPoliciesService,
              private router: Router,
              private apiService: ApiService,
              private toastrService: ToastrService,
              private store: Store<AppState>,
              private paymentService: PaymentService
  ) {
    this.CASCOPolicies = [];
    this.MTPLPolicies = [];
    this.MTPLPoliciesAndInstallment = [];
    this.CASCOPoliciesAndInstallment = [];
  }

  ngOnInit() {
    this.modal.dismissAll();
    this.store.dispatch(fetchInstallmentToPayAndCar({installmentToPayAndCar: null}));
    this.isLoading = true;
    this.customerId = JSON.parse(sessionStorage.getItem('customerId'));
    this.isDataLoaded();
    this.selectMyPoliciesSub = this.store.select(selectMyPolicies).subscribe(rez => {
      if (rez && Object.keys(rez).length > 1) {
        if (rez[`MTPL`][`active`].length > 0) {
          this.MTPLPolicies.push(rez[`MTPL`][`active`]);
        }

        if (rez[`MTPL`][`other`].length > 0) {
          this.MTPLPolicies.push(rez[`MTPL`][`other`]);
        }

        if (rez[`CASCO`][`active`].length > 0) {
          this.CASCOPolicies.push(rez[`CASCO`][`active`]);
        }

        if (rez[`CASCO`][`other`].length > 0) {
          this.CASCOPolicies.push(rez[`CASCO`][`other`]);
        }

        if (this.checkForPolicies) {
          setTimeout(() => {
            this.policyInstallmentMTPL();
            this.policyInstallmentCASCO();
          });
        }

      }
    });
  }

  isDataLoaded() {
    setTimeout(() => {
      this.isLoading = false;
      // console.log(this.CASCOPoliciesAndInstallment, 'CASCOPoliciesAndInstallment');
    }, 2000);
    setTimeout(() => {
      // console.log(this.CASCOPoliciesAndInstallment, 'CASCOPoliciesAndInstallment');
    }, 5000);
  }

  ngOnDestroy() {
    if (this.getPoliciesInstallmentSub) {
      this.getPoliciesInstallmentSub.unsubscribe();
    }
    if (this.selectMyPoliciesSub) {
      this.selectMyPoliciesSub.unsubscribe();
    }
  }

  showRedColor(dueDate) {
    return moment().diff(dueDate, 'days') >= 30 || moment().diff(dueDate, 'days') >= 1;
  }

  goToHistory(chassisId, activeTab) {
    this.seePaymentHistory(chassisId, activeTab, this.customerId);
  }

  get checkForPolicies() {
    return !!this.CASCOPolicies;
  }

  seePaymentHistory(chassisId, activeTab, costumerId) {
    this.myPoliciesService.seeHistoryPayments(chassisId, activeTab, costumerId);
  }

  policyInstallmentCASCO() {
    if (this.CASCOPolicies) {
      this.CASCOPolicies.forEach(AllCASCOPolicies => {
        AllCASCOPolicies.forEach(CASCOPolicyItem => {
          this.getPoliciesInstallmentSub =
            this.myPoliciesService.getPoliciesInstallment(CASCOPolicyItem.id).subscribe(PolicyIdInstallments => {
              if (PolicyIdInstallments) {
                const policyIdInstallmentsLength = PolicyIdInstallments.length;
                PolicyIdInstallments.some(policyIdInstallmentItem => {
                  if (policyIdInstallmentItem.payment === null) {
                    if
                    (!this.CASCOPoliciesAndInstallment.find(installment => installment.policyId === CASCOPolicyItem.number)) {
                      this.CASCOPoliciesAndInstallment.push({
                        policyData: CASCOPolicyItem,
                        policyId: CASCOPolicyItem.number,
                        installmentToPay: policyIdInstallmentItem,
                        installments: PolicyIdInstallments,
                        fullPayed: false,
                        daysPassed: this.getDaysElapsedSinceToday(policyIdInstallmentItem.dueDate)
                      });
                    }
                  }
                  if (policyIdInstallmentItem.payment !== null && policyIdInstallmentsLength === policyIdInstallmentItem.number) {
                    if
                    (!this.CASCOPoliciesAndInstallment.find(installment => installment.policyId === CASCOPolicyItem.number)) {
                      // console.log(this.CASCOPoliciesAndInstallment);
                      this.CASCOPoliciesAndInstallment.push({
                        policyData: CASCOPolicyItem,
                        policyId: CASCOPolicyItem.number,
                        installmentToPay: policyIdInstallmentItem,
                        installments: PolicyIdInstallments,
                        fullPayed: true,
                      });
                    }
                  }
                });
              }

            });
        });
      });
    }
  }

  getDaysElapsedSinceToday(dueDate) {
    return moment().diff(dueDate, 'days');
  }

  policyInstallmentMTPL() {
    if (this.MTPLPolicies) {
      this.MTPLPolicies.forEach(x => {
        x.forEach(y => {
          this.myPoliciesService.getPoliciesInstallment(y.id).subscribe(rez => {
            if (this.MTPLPoliciesAndInstallment.length === 0) {
              this.MTPLPoliciesAndInstallment.push({
                policyData: y,
                policyId: y.number,
                installments: rez
              });
            }
            if (this.MTPLPoliciesAndInstallment.length > 0) {
              if (!this.MTPLPoliciesAndInstallment.find(installment => installment.policyId === y.number)) {
                this.MTPLPoliciesAndInstallment.push({
                  policyData: y,
                  policyId: y.number,
                  installments: rez
                });
              }
            }
          });
        });
      });
    }
  }

  openPolicyReactivation(policyData: any) {
    const modalRef = this.modal.open(ReactivatePolicyComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.policyType = policyData.product.type.code;
    modalRef.componentInstance.policyNumber = policyData.number;
    this.getCustomerFullName().then(result => {
      if (result) {
        modalRef.componentInstance.customerFullName = result;
      }
    });
    modalRef.componentInstance.customerId = this.customerId;
  }

  openCancelPolicyModal(policy) {
    const modalRef = this.modal.open(CancelPolicyConfirmComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.policyData = policy;
  }

  inspectieRisc() {
    this.modal.open(RequestConsultantComponent as Component, {
      size: 'lg', centered: true
    });
  }

  downloadPolicy(dataItem) {
    if (this.isPolicyDownloading === false) {
      this.isPolicyDownloading = true;
      const policy = dataItem.policyData;
      this.apiService.downloadCertificatePdf(policy.id).pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = policy.vehicle.chassis + '_' + policy.product.type.code + '.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.isPolicyDownloading = false;
        });
    }
  }

  seePayments(chassisId, activeTab) {
    const state = this.myPoliciesService.sendDataToPaymentsPage(
      this.CASCOPoliciesAndInstallment, this.MTPLPoliciesAndInstallment, chassisId, activeTab
    );
    this.store.dispatch(fetchPolicyInstallmentsSuccess({selectedPolicyInstallments: state}));
    this.router.navigate(['/payments']);
  }

  get userEmail() {return sessionStorage.getItem('customerEmail'); }

  sendPolicyByEmail(policyData: any) {
      const payload = {
        objectId: parseInt(policyData.id),
        objectType: "policy",
        email: this.userEmail
    };
    this.getCustomerEmail().then(resultGetCustomerEmail => {
      if (resultGetCustomerEmail) {
        payload.email = resultGetCustomerEmail;
        this.myPoliciesService.sendPolicyByEmail(payload).subscribe(resultSendPolicyByEmail => {
          if (resultSendPolicyByEmail) {
            this.toastrService.success('Polița a fost trimisă cu succes pe mail. Îți mulțumim!', '', {
              timeOut: 5000,
            });
            if (resultSendPolicyByEmail[`error`] === true) {
              this.toastrService.error('A apărut o eroare. Îți mulțumim, încercă mai târziu!', '', {
                timeOut: 5000,
              });
            }
          }
        });
      }
    });
  }

  selectTab(tab: any) {
    this.activeTab = tab;
  }

  private async getCustomerFullName(): Promise<string> {
    let customerFullName: string;
    this.store.select(selectOfferDataCustomer).subscribe(customer => {
      if (customer) {
        customerFullName = customer.firstName + ' ' + customer.lastName;
      }
    });
    return customerFullName;
  }

  private async getCustomerEmail(): Promise<string> {
    let customerEmail: string;
    this.store.select(selectOfferDataCustomer).subscribe(customer => {
      if (customer) {
        customerEmail = customer.email;
      }
    });
    return customerEmail;
  }

  setIsOverdueDate(date) {
    const currentDate = new Date();
    const policyDueDate = new Date(date);
    if (currentDate > policyDueDate) {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(true));
    } else {
      sessionStorage.setItem('isOverdueDate', JSON.stringify(false));
    }
  }

  validateInstallment(installmentProduct, chassisId, policy) {
    this.store.select(selectMyCars).subscribe(allCars => {
      if (allCars) {
        const allAccountCars = allCars;
        const selectedToPayCar = allAccountCars.filter(car => car.chassis === chassisId);
        if (
          selectedToPayCar[0].chassis === chassisId &&
          (selectedToPayCar[0].registrationNumber === null || selectedToPayCar[0].registrationNumber === '')
        )
        {
          const installmentPolicyAndCar = {vehiclePolicy: policy, vehicle: selectedToPayCar[0]};
          this.store.dispatch(fetchInstallmentToPayAndCar({installmentToPayAndCar: installmentPolicyAndCar}));
        } else {
          this.store.dispatch(fetchInstallmentToPayAndCar({installmentToPayAndCar: null}));
        }
      }
    });
    this.setIsOverdueDate(installmentProduct.dueDate);
    this.paymentService
      .validateInstallment(installmentProduct)
      .then(_ => {
        this.router.navigate(['/pay-installment']);
      });
    // send policy installment data to service
    this.paymentService.setProductData(installmentProduct);
  }
}