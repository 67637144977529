import {AppStorageService} from './services/app-storage.service';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {PagesModule} from './pages/pages.module';
import {NgbActiveModal, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NbStepperModule, NbLayoutModule, NbThemeModule, NbSpinnerModule} from '@nebular/theme';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {SharedModule} from './shared/shared.module';
import {SidebarModule} from 'ng-sidebar';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {environment} from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {OktaAuthGuard, OktaAuthModule, OKTA_CONFIG} from '@okta/okta-angular';
import {ApiService} from './services/api.service';
import {ApigeeService} from './services/apigee.service';
import {SessionDataService} from './services/session-data.service';
import {HeadersInterceptor} from './services/headers.interceptor';
import {EffectsModule} from '@ngrx/effects';
import {reducers, metaReducers} from './store';
import {StoreModule} from '@ngrx/store';
import {registerLocaleData} from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import {MyNgbDateParserFormatter} from './shared/date-formatter';
import {DropDownDataService} from './services/dropDown.data.service';
import {TranslationService} from './services/translation.service';
import {TimeoutInterceptorService} from './services/timeout-interceptor.service';
import {ErrorInterceptorService} from './services/error-interceptor.service';
import {FieldsService} from './services/fields.service';
import {FunctionsService} from './services/functions.service';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {OfferEffects} from './store/effects/offer.effects';
import {PoliciesEffects} from './store/effects/policies.effects';
import {SelectedOfferEffects} from './store/effects/selectedOffer.effects';
import {JwtModule} from '@auth0/angular-jwt';
import {RouteEventsService} from './shared/services/route-events.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {FooterLinksModule} from './shared/components/footer-links/footer-links.module';
import {CookieService} from 'ngx-cookie-service';

registerLocaleData(localeRo);

export function tokenGetter() {
  return window.sessionStorage.getItem('okta-token-storage');
}

const oktaConfig = {
  issuer: `${environment.oktaOrgURL}`,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: environment.oktaClientId,
  pkce: true,
  storage: `sessionStorage`,
};

@NgModule({
  declarations: [AppComponent],

  imports: [
    NbThemeModule.forRoot(),
    NbEvaIconsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    PagesModule,
    FooterLinksModule,
    OktaAuthModule,
    NbStepperModule,
    ReactiveFormsModule,
    NgbModule,
    NbSpinnerModule,
    NbLayoutModule,
    FormsModule,
    SharedModule,
    SidebarModule.forRoot(),
    FontAwesomeModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    EffectsModule.forRoot([
      OfferEffects,
      PoliciesEffects,
      SelectedOfferEffects]),
    StoreDevtoolsModule.instrument({
      name: 'Marsh Dev Global State',
      logOnly: environment.production
    }),
    PdfViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      },
    }),
  ],
  providers: [
    FieldsService, ApiService, TranslationService, AppStorageService, RouteEventsService,
    DropDownDataService, FunctionsService, ApigeeService, SessionDataService, CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {provide: OKTA_CONFIG, useValue: oktaConfig},
    {provide: LOCALE_ID, useValue: 'ro-RO'},
    NgbActiveModal,
    HttpClient,
    ApiService,
    AppStorageService,
    ApigeeService,
    SessionDataService,
    DropDownDataService,
    OktaAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new MyNgbDateParserFormatter('longDate'),
    },
  ],

  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
