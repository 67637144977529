import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {concat, Observable, throwError} from 'rxjs';
import {catchError, debounceTime, finalize, share, timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeoutInterceptorService implements HttpInterceptor {
  private twoMinutes = 120000;
  private halfMinute = 49000;
  private wayToMuch = 240000;

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.url.includes('simplified-data')) {
      return next.handle(req).pipe(timeout(this.wayToMuch)).pipe(catchError(error => {
        return this.errorManagement(error);
      }), finalize(() => throwError('finalize')));
    }
    if (req.url.includes('products-set')) {
      return next.handle(req).pipe(timeout(this.twoMinutes)).pipe(catchError(error => {
        return this.errorManagement(error);
      }), finalize(() => throwError('finalize')));
    }
    if (req.url.includes('validate-step')) {
      return next.handle(req).pipe(timeout(this.twoMinutes)).pipe(catchError(error => {
        return this.errorManagement(error);
      }), finalize(() => throwError('finalize')));
    }
    return next.handle(req).pipe(timeout(this.halfMinute)).pipe(catchError(error => {
      return this.errorManagement(error);
    }), finalize(() => throwError('finalize')));
  }

  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
