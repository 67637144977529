import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuthService } from '@okta/okta-angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApigeeService } from 'src/app/services/apigee.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AppState } from 'src/app/store';
import { fetchCarsDataSuccess } from 'src/app/store/actions/cars.actions';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyCarsService {
  baseUri = environment.apiUrl;
  constructor(private http: HttpClient,
    private oktaAuthService: OktaAuthService,
    private apigeeService: ApigeeService,
    private localStorage: LocalStorageService,
    private store: Store<AppState>) {}

  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  addUpdateVehicle(body, vehicle?) {
    const url = `${this.baseUri}/online/vehicles/${vehicle.id}`;
    return this.http.patch(url, body);
  }

  addVehicle(body) {
    const url = `${this.baseUri}/online/vehicles`;
    return this.http.post(url, body)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  updateVehicle(body) {
    const url = `${this.baseUri}/online/vehicle`;
    const { id, ...rest } = body;
    return this.http.put(`${url}/${id}`, rest).pipe(
      catchError(this.errorManagement)
    );
  }

  getVehicleById(id) {
    const url = `${this.baseUri}/online/vehicles/${id}`;
    return this.http.get(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getVehiclesByChassis(chassis: string) {
    const url = `${this.baseUri}/online/vehicles?limit=50&offset=0&cache=0&filter[0]=chassis||$contL||${chassis}`;
    return this.http.get(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getVehiclesByCustomerId(customerId: number) {
    const url = `${this.baseUri}/online/vehicles?limit=50&offset=0&cache=0&filter[0]=customer.id||$eq||${customerId}`;
    return this.http.get(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getVehiclesList(customerId?: number){
    const customerIdSessionStorage = JSON.parse(window.sessionStorage.getItem('customerId'));
    if (customerId){
      this.getVehiclesByCustomerId(customerId).subscribe(vehicles => {
        if (vehicles[`count`] > 0) {
          this.store.dispatch(fetchCarsDataSuccess({myCars: vehicles[`data`]}));
        }
      });
    } else {
      this.getVehiclesByCustomerId(customerIdSessionStorage).subscribe(vehicles => {
        if (vehicles[`count`] > 0) {
          this.store.dispatch(fetchCarsDataSuccess({myCars: vehicles[`data`]}));
        }
      });
    }
    return null;
  }
}
