import {Component, OnInit} from '@angular/core';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-marsh-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationComponent implements OnInit {
  faClose = faWindowClose;
  text: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onConfirm(){
    this.activeModal.close(true);
  }

  onClose(){
    this.activeModal.close();
  }
}
