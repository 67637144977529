import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarningService {
  fromWhatWarningComponent = new BehaviorSubject([]);
  constructor(private router: Router) { }

  sendDataToReportDataComp(from, dataOfComponent) {
    if (from && dataOfComponent) {
      this.fromWhatWarningComponent.next([{from, dataOfComponent}]);
      this.router.navigate(['/report-wrong-data']);
    }
  }
}
