import {Component, Input, OnInit} from '@angular/core';
import {UserPetitionDetails} from 'src/app/models/user.model';
import {Store} from '@ngrx/store';
import {AppState, selectOfferDataCustomer} from 'src/app/store';
import {ApiService} from 'src/app/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-marsh-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  userDetails = new UserPetitionDetails();
  petitionContent: string;
  sendingPetition = false;
  sendingPetitionApiResponse: string;
  firstNameErrors = false;
  lastNameErrors = false;
  emailErrors = false;
  phoneErrors = false;
  @Input() objectType: string;
  mobNumberPattern = new RegExp('^(\\\\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\\\\s|\\\\.|\\\\-)?([0-9]{3}(\\\\s|\\\\.|\\\\-|)){2}$');
  emailAdrPattern = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');
  get userId() {return JSON.parse(sessionStorage.getItem('customerId')); }
  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
    private toastrService: ToastrService,
  ) {

  }

  ngOnInit() {
    this.getUserDetailsFromStore();
  }

  send() {
    this.sendingPetition = true;
    if (this.emptyFields()) {
      this.firstNameErrors = !this.userDetails?.firstName || this.userDetails?.firstName === '';
      this.lastNameErrors = !this.userDetails?.lastName || this.userDetails?.lastName === '';
      this.emailErrors = !this.userDetails?.email || this.userDetails?.email === '';
      this.phoneErrors = !this.userDetails?.phone || this.userDetails?.phone === '';
      return;
    }
    const body = {
      objectId: this.userId,
      objectType: this.objectType,
      email: this.userDetails?.email,
      phone: this.userDetails?.phone,
      customerName: `${this.userDetails.firstName} ${this.userDetails.lastName}`,
      text: `${this.petitionContent}`,
      objectEnvironment: environment.app

    };
    this.apiService.reportIncorrectObjectData(body).subscribe((result: any) => {
      if (result) {
        if (result.error === true) {
          this.sendingPetitionApiResponse = result.message;
          this.toastrService.error(`A aparut o eroare ${result.message}.`, '', {
            timeOut: 5000,
          });
        } else {
          this.petitionContent = '';
          this.sendingPetition = false;
          this.toastrService.success('Mesajul tău a fost transmis către echipa Marsh care urmează să te contacteze în cel mai scurt timp. Mulțumim pentru sesizare. Îți dorim o zi frumoasă!.', '', {
            timeOut: 5000,
          });
        }
      }
    });
  }

  userDetailsFirstname(value) {
    if (value === '') {
      this.firstNameErrors = true;
    } else if (value === null) {
      this.firstNameErrors = false;
    }
    else if (value !== '') {
      this.firstNameErrors = false;
    }
  }

  userDetailsLastname(value) {
    if (value === '') {
      this.lastNameErrors = true;
    } else if (value === null) {
      this.lastNameErrors = false;
    }
    else if (value !== '') {
      this.lastNameErrors = false;
    }
  }


  private emptyFields(): boolean {
    return ((!this.userDetails?.firstName || this.userDetails?.firstName === '') ||
      (!this.userDetails?.lastName || this.userDetails?.lastName === '') ||
      (!this.userDetails?.email || this.userDetails?.email === '') ||
      (!this.userDetails?.phone || this.userDetails?.phone === '') ||
      (!this.petitionContent || this.petitionContent === ''));
  }

  private getUserDetailsFromStore() {
    this.store.select(selectOfferDataCustomer).subscribe(customerData => {
        if (customerData) {
          this.userDetails = customerData;
        } else {
          this.emailErrors = false;
          this.phoneErrors = false;
        }
      }
    );
  }

}
