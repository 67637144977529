// tslint:disable-next-line:max-line-length
const all = ['col_policy', 'tit_credit_notes', 'policies', 'lab_type', 'lab_firstName', 'lab_lastName', 'lab_uniqueIdentifier', 'lab_county', 'lab_city',
  'lab_email', 'lab_phone', 'lab_streetName', 'lab_streetNumber', 'lab_buildingNumber', 'lab_apartment', 'lab_driverLicenseDate', 'lab_discount', 'lab_activityCode',
  'tit_view_details', 'go_to_list_button', 'btn_user_profile', 'loading_message', 'col_offer_number', 'col_created_by', 'col_offer_date', 'col_chassis', 'col_model',
  'col_first_name', 'col_last_name', 'col_status', 'col_type', 'col_actions', 'btn_add', 'btn_refresh_grid', 'btn_export_xls', 'btn_export_pdf', 'btn_print',
  'btn_copy', 'email', 'btn_quotations', 'btn_risk_inspection', 'vehicle_details', 'lab_chassis', 'chassis_code', 'lab_fuel', 'lab_make', 'lab_model',
  'lab_category', 'lab_vehicle_use', 'lab_purchase_value', 'value', 'lab_currency', 'lab_equipment_value', 'lab_insured_value', 'lab_authorized_weight',
  'lab_odometer', 'lab_no_of_seats', 'lab_engine_kw', 'lab_engine_capacity', 'lab_provisional_registration', 'lab_registration_number', 'lab_build_year',
  'lab_vehicle_status', 'lab_date_first_registration', 'lab_purchase_date', 'lab_certificate_number', 'lab_drpciv_category', 'lab_groupama_model',
  'lab_groupama_natcode', 'vehicle', 'customer', 'agreements', 'drivers', 'policy_details', 'quotations', 'risk_inspection', 'payments', 'certificate',
  'info_mandatory_fields', 'info_validate_button', 'btn_continue', 'btn_validate', 'select_nat_code', 'col_variant', 'col_engine_capacity',
  'col_authorized_weight', 'col_seats_number', 'col_value', 'col_drpciv_nat_code', 'col_build_year', 'btn_select', 'search_customer_placeholder',
  'lab_driver_license_date', 'lab_discount_description', 'btn_new_customer', 'customer_details', 'lab_customer_type', 'lab_unique_identifier', 'lab_last_name',
  'lab_first_name', 'lab_phone_number', 'lab_street_name', 'lab_street_number', 'lab_building_number', 'btn_back', 'col_agreement_type', 'col_answer',
  'col_answer_type', 'col_start_date', 'col_end_date', 'col_obtained_by', 'col_file', 'btn_edit', 'btn_update', 'btn_cancel', 'btn_upload', 'tit_upload_file',
  'tit_driver_panel', 'btn_delete', 'lab_id_series', 'lab_id_number', 'btn_add_driver', 'info_minimum_one_driver', 'tit_policy_details', 'lab_policy_package',
  'lab_direct_settlement', 'tit_rca_casco_details', 'lab_start_mtpl_date', 'lab_start_casco_date', 'lab_installments_number', 'insurance_type', 'product_name',
  'col_id', 'col_created_on', 'col_error', 'col_insurer', 'col_policy_type', 'col_product', 'col_payment_type', 'col_due_value', 'col_exchange_rate',
  'col_payment_details', 'col_is_payed', 'tit_edit_payment', 'lab_payment_details', 'btn_save', 'col_policy_number', 'col_make', 'coly_customer',
  'col_grid_actions', 'btn_download', 'btn_disable', 'add_new_payment_summary', 'export_excel_paym_summary_grid', 'export_pdf_paym_summary_grid',
  'export_excel_customer_grid', 'export_pdf_customer_grid', 'btn_view', 'btn_agreements', 'btn_export_excel', 'lab_customer', 'lab_status', 'lab_vehicleUse',
  'lab_purchaseValue', 'lab_purchaseCurrency', 'lab_equipmentValue', 'lab_insuredValue', 'lab_authorizedWeight', 'lab_yearlyMileage', 'lab_seats',
  'lab_engineCapacity', 'lab_engineKw', 'lab_registrationNumber', 'lab_buildYear', 'lab_purchaseDate', 'lab_dateOfFirstRegistration', 'lab_certificateNumber',
  'lab_orderDate', 'lab_drpcivCategory', 'lab_drpcivModel', 'lab_groupamaNatcode', 'export_products_to_excel', 'export_products_pdf', 'lab_insurer', 'lab_name',
  'lab_period', 'lab_cascoType', 'tit_users', 'btn_user_programs', 'lab_group', 'lab_dealer', 'lab_showroom', 'lab_displayLanguage', 'lab_isActive', 'lab_password',
  'tit_programs', 'col_check', 'col_program', 'tit_groups', 'btn_permissions', 'tit_view_record', 'tit_group_permissions', 'col_menu', 'col_view', 'col_add',
  'col_edit', 'col_export', 'col_extra1', 'col_extra2', 'col_screen_fields', 'btn_fields', 'tit_fields', 'col_name', 'col_is_displayed', 'col_position',
  'lab_rateDate', 'lab_sourceCurrencyUnits', 'lab_sourceCurrency', 'lab_rate', 'lab_destinationCurrencyUnits', 'lab_destinationCurrency', 'lab_context',
  'lab_log', 'btn_decrypt_file', 'btn_view_errors', 'tit_errors', 'col_title', 'lab_area', 'lab_code', 'lab_dataBlock', 'export_model_excel', 'export_model_pdf',
  'btn_mappings', 'lab_position', 'model_mapping_list', 'col_insurer_code', 'col_insurer_name', 'add_new', 'lab_omniasigCode', 'lab_language', 'lab_languageGroup',
  'lab_phrase', 'lab_translation', 'select_language', 'select_program', 'btn_refresh_translations', 'btn_update_profile', 'loading_message', 'btn_user_profile',
  'col_actions', 'tit_groups', 'btn_add', 'btn_export_xls', 'btn_export_pdf', 'btn_refresh_grid', 'btn_view', 'btn_delete', 'btn_edit', 'btn_permissions',
  'tit_group_permissions', 'col_menu', 'col_view', 'col_add', 'col_edit', 'col_export', 'col_extra1', 'col_extra2', 'col_screen_fields', 'btn_cancel', 'btn_save',
  'btn_fields', 'tit_fields', 'col_id', 'col_name', 'col_is_displayed', 'col_position', 'err_email_required', 'product', 'insurer', 'period', 'start_date',
  'end_date', 'premium', 'currency', 'btn_payments', 'tit_policies', 'col_customer', 'col_unique_identifier', 'col_product_name', 'col_premium', 'col_currency',
  'col_pdf', 'col_number', 'col_due_date', 'btn_pay', 'col_payment_date', 'files_loaded', 'pla_unique_identifier', 'pla_last_name', 'pla_first_name',
  'tit_offers', 'dnt', 'btn_form_reset', 'tit_customer_details', 'tit_demands_and_needs_test', 'lab_mtpl_offer', 'lab_is_consulting_required', 'lab_casco_offer',
  'lab_start_date', 'lab_end_date', 'err_unique_identifier_required', 'err_lastName_required', 'err_firstName_required', 'err_customer_type_required',
  'err_cnp_incorrect', 'col_is_active', 'form_errors', 'err_field_is_required', 'lab_fileUpload', 'err_last_name_required', 'err_first_name_required',
  'err_start_mtpl_date_required', 'col_file_name', 'tit_new_file', 'lab_file', 'lab_is_payed', 'err_start_casco_date_required', 'err_installment_number_required',
  'err_company_type_required', 'err_company_name_required', 'pla_company_type', 'pla_company_name', 'err_consulting_required', 'err_cui_incorrect', 'lab_activity_code',
  'lab_policies_report', 'btn_export_csv', 'lab_offers_vs_policies_report', 'lab_payments_summaries_report', 'tit_dealers_reports', 'tit_payments_summaries',
  'tit_technical_documents', 'tit_insurance_documents', 'search_offer', 'tit_messages', 'tit_customers', 'tit_vehicles', 'lab_is_active', 'btn_check',
  'btn_query', 'col_insurer_id', 'tit_exchange_rates', 'err_query_required', 'err_type_required', 'btn_execute', 'btn_download_offers', '', 'btn_clear_selection',
  'tit_send_email', 'pla_email', 'btn_send_email', 'btn_email', 'btn_user_profile', 'col_id', 'col_chassis', 'col_first_name', 'col_last_name', 'col_offer_number',
  'col_is_active', 'col_actions', 'tit_credit_notes', 'btn_add', 'btn_export_xls', 'btn_export_pdf', 'btn_refresh_grid', 'loading_message', 'btn_payments', 'btn_print',
  'col_created_by', 'col_offer_date', 'col_model', 'col_type', 'tit_offers', 'btn_copy', 'btn_quotations', 'btn_risk_inspection', 'dnt', 'agreements', 'customer',
  'vehicle', 'quotations', 'risk_inspection', 'payments', 'certificate', 'btn_continue', 'btn_form_reset', 'tit_customer_details', 'pla_unique_identifier', 'pla_first_name',
  'pla_last_name', 'tit_demands_and_needs_test', 'lab_policy_package', 'lab_mtpl_offer', 'lab_start_mtpl_date', 'lab_direct_settlement', 'lab_is_consulting_required',
  'lab_casco_offer', 'lab_start_casco_date', 'lab_installments_number', 'btn_back', 'col_agreement_type', 'col_answer', 'col_start_date', 'col_end_date',
  'col_obtained_by', 'col_file', 'btn_edit', 'btn_update', 'btn_cancel', 'btn_upload', 'lab_driver_license_date', 'lab_discount', 'lab_discount_description',
  'value', 'customer_details', 'lab_customer_type', 'lab_unique_identifier', 'lab_first_name', 'lab_last_name', 'lab_county', 'lab_city', 'lab_email',
  'lab_phone_number', 'lab_street_name', 'lab_street_number', 'lab_building_number', 'lab_apartment', 'info_mandatory_fields', 'info_validate_button',
  'btn_validate', 'vehicle_details', 'lab_chassis', 'chassis_code', 'lab_fuel', 'lab_make', 'lab_model', 'lab_category', 'lab_vehicle_use', 'lab_purchase_value',
  'lab_currency', 'lab_equipment_value', 'lab_insured_value', 'lab_authorized_weight', 'lab_odometer', 'lab_no_of_seats', 'lab_engine_kw', 'lab_engine_capacity',
  'lab_registration_number', 'lab_build_year', 'lab_vehicle_status', 'lab_date_first_registration', 'lab_purchase_date',
  'lab_certificate_number', 'lab_drpciv_category', 'lab_groupama_model', 'lab_groupama_natcode', 'err_city_required', 'err_provisional_registration_incorrect',
  'btn_new_offer', 'tit_admin_offers', 'tit_', 'tit_logs', 'btn_gdpr', 'tit_gdpr_form', 'err_fuel_required', 'tit_cancel_policy', 'err_cancel_reason_required',
  'lab_cancel_date', 'lab_cancel_reason', 'tit_policies_reports', 'tit_showrooms', 'btn_get_offer', 'btn_get_policy', 'btn_attachments', 'tit_policy_attachments',
  'tit_payments', 'btn_close_payment', 'err_vehicle_model_required', 'err_email_incorrect', 'customer_agreements_list', 'err_category_required',
  'tit_admin_reports', 'rpt_omniasig', 'rpt_policies_vehicles', 'rpt_customers_import_ba', 'rpt_policies_import_ba', 'rpt_expired_policies',
  'rpt_paris_synthesis', 'rpt_policies_by_customer_type', 'rpt_policies_by_dealer', 'rpt_credit_notes', 'rpt_policies_xls', 'rpt_bulk_offers_import',
  'rpt_zone_manager_policies', 'tit_claims_reports', 'err_phone_required', 'err_street_name_required', 'col_casco_type', 'tit_dealers', 'btn_print_agreement',
  'tit_print_agreement', 'col_notice_method', 'btn_change_status', 'btn_login', 'btn_download_inspection_risk', 'MTPL', 'CASCO', 'btn_add_risk_inspection', 'lab_delivery_date',
  'col_raj_code', 'lab_raj_code', 'pla_raj_code', 'col_raf_code', 'lab_raf_code', 'pla_raf_code', 'products', 'btn_download_offers_with', 'tit_translations',
  'err_registration_number_required', 'err_certificate_number_required', 'tit_dashboard', 'msg_allianz_monthly_installment', 'col_credit_note_number', 'tit_user_settings',
  'col_email', 'col_vehicle_status', 'col_value_currency', 'yes', 'no', 'bulk_files', 'pla_search_offer_product', 'tit_add_policies', 'lab_search_offer_product', 'lab_vehicle',
  'lab_product', 'lab_premium', 'lab_number', 'lab_insurer_id', 'col_original_name', 'btn_download_offers_without_emails', 'btn_download_offers_white_image', 'btn_download_offers_all',
  'btn_enable', 'col_items', 'lab_created_by', 'col_dealer', 'lab_operation_date', 'lab_operation_date', 'col_delete', 'col_category_name', 'btn_discard', 'lab_rajCode',
  'err_number_required', 'err_insurer_id_required', 'err_date_first_registration_required', 'col_emails_sent_count', 'lab_yes', 'lab_no', 'tit_claims', 'col_reserve_number',
  'col_reference', 'col_date', 'col_recipient', 'col_franchise_status', 'col_franchise_value', 'col_details', 'btn_add_claim', 'btn_add_payment', 'tit_view_claims', 'lab_userType',
  'lab_rafCode', 'lab_policy', 'lab_eventDate', 'lab_approvalDate', 'lab_closeDate', 'lab_eventType', 'lab_reserveValue', 'lab_reserveCurrency', 'lab_regres', 'lab_totalDamage',
  'lab_franchiseValue', 'lab_franchiseCurrency', 'tit_view_claims_payments', 'lab_claim', 'lab_paymentReference', 'lab_paymentValue', 'lab_paymentCurrency', 'lab_paymentDate',
  'lab_recipient', 'lab_franchiseStatus', 'lab_details', 'btn_upload_claims', 'btn_upload_payments'
];

const dictionary = [...new Set(all.map(item => item))];
export {dictionary};
