import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggerFunctionFromAnotherCompService {
  private subjectJustCASCO = new Subject<any>();
  private subjectJustMTPL = new Subject<any>();
  private subjectGoToMTPL = new Subject<any>();
  private subjectGoToSummery = new Subject<any>();
  private subjectCheckExpiredOffer = new Subject<any>();
  private subjectCheckSelectedCASCOOffer = new Subject<any>();
  private subjectCheckSelectedMTPLOffer = new Subject<any>();
  private subjectCheckIfTermsIsChecked = new Subject<any>();
  private subjectCheckIfConsultancyCASCOIsChecked = new Subject<any>();
  private subjectCheckIfConsultancyMTPLIsChecked = new Subject<any>();

  sendClickEventJustCASCO() {
    this.subjectJustCASCO.next();
  }

  getClickEventJustCASCO(): Observable<any>{
    return this.subjectJustCASCO.asObservable();
  }

  sendClickEventJustMTPL() {
    this.subjectJustMTPL.next();
  }

  getClickEventJustMTPL(): Observable<any>{
    return this.subjectJustMTPL.asObservable();
  }

  sendClickEventGoToMTPL() {
    this.subjectGoToMTPL.next();
  }

  getClickEventGoToMTPL(): Observable<any>{
    return this.subjectGoToMTPL.asObservable();
  }

  sendClickEventGoToSummery() {
    this.subjectGoToSummery.next();
  }

  getClickEventGoToSummery(): Observable<any>{
    return this.subjectGoToSummery.asObservable();
  }

  sendClickEventCheckExpiredOffer() {
    this.subjectCheckExpiredOffer.next();
  }

  getClickEventCheckExpiredOffer(): Observable<any>{
    return this.subjectCheckExpiredOffer.asObservable();
  }

  sendClickEventCheckSelectedCASCOOffer() {
    this.subjectCheckSelectedCASCOOffer.next();
  }

  getClickEventCheckSelectedCASCOOffer(): Observable<any>{
    return this.subjectCheckSelectedCASCOOffer.asObservable();
  }

  sendClickEventCheckSelectedMTPLOffer() {
    this.subjectCheckSelectedMTPLOffer.next();
  }

  getClickEventCheckSelectedMTPLOffer(): Observable<any>{
    return this.subjectCheckSelectedMTPLOffer.asObservable();
  }

  sendClickEventCheckIfTermsIsChecked() {
    this.subjectCheckIfTermsIsChecked.next();
  }

  getClickEventCheckIfTermsIsChecked(): Observable<any>{
    return this.subjectCheckIfTermsIsChecked.asObservable();
  }

  sendClickEventCheckIfConsultancyCASCOIsChecked() {
    this.subjectCheckIfConsultancyCASCOIsChecked.next();
  }

  getClickEventCheckIfConsultancyCASCOIsChecked(): Observable<any>{
    return this.subjectCheckIfConsultancyCASCOIsChecked.asObservable();
  }

  sendClickEventCheckIfConsultancyMTPLIsChecked() {
    this.subjectCheckIfConsultancyMTPLIsChecked.next();
  }

  getClickEventCheckIfConsultancyMTPLIsChecked(): Observable<any>{
    return this.subjectCheckIfConsultancyMTPLIsChecked.asObservable();
  }

}
