import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cascoProductsFilter',
    pure: false
})
export class CascoProductsFilterPipe implements PipeTransform {
    transform(items: any[], withPackage: boolean): any {
        if (!items) {
            return items;
        }
        if (withPackage === true)
        {
          return items.filter(product => product.package?.name !== 'no');
        } else {
          return items.filter(product => product.package?.name === 'no');
        }
    }
}
