import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable()
export class AppStorageService {

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
  ) {
  }

  public setOnLocalStorage(key: string, data: any): any {
    return this.localStorage.set(key, data);
  }

  public getOnLocalStorage(key: string, who?: string): any {
    if (this.localStorage.has(key)) {
      return this.localStorage.get(key);
    } else {
      return null;
    }
  }

  clearLocalStorage() {
    this.localStorage.clear();
  }

  setTranslations(data) {
    this.localStorage.set('translations', data);
  }

  getTranslations() {
    return this.localStorage.get('translations') || {};
  }

  setDictionary(label) {
    const dictionary = this.getDictionary();
    if (dictionary.indexOf(label) === -1) {
      dictionary.push(label);
      this.localStorage.set('dictionary', dictionary);
    }
  }

  getDictionary() {
    return this.localStorage.get('dictionary') || [];
  }

  public setOnSessionStorage(key: string, data: any): any {
    return this.sessionStorage.set(key, data);
  }

  public getOnSessionStorage(key: string, who?: string): any {
    if (this.sessionStorage.has(key)) {
      return this.sessionStorage.get(key);
    } else {
      return null;
    }
  }

  clearSessionStorage() {
    this.sessionStorage.clear();
  }

  clearSessionKey(key) {
    if (this.sessionStorage.has(key)) {
      this.sessionStorage.remove(key);
    }
  }
}
