import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MyPoliciesService} from '../../../../shared/services/myPoliciesService/my-policies.service';
import {AppState, selectPolicyHistory} from '../../../../store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  activeTab: number;
  product = [];

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectPolicyHistory).subscribe(policyHistory => {
      if (policyHistory) {
        this.product = policyHistory;
        this.activeTab = this.product ? this.product[`activeTabSet`] : 0;
      } else {
        this.router.navigateByUrl('/my-policies');
        return;
      }
    });
  }

  selectTab(tab: any) {
    this.activeTab = tab;
  }

  goToPayments(){
    this.router.navigate(['/my-policies']);
  }
}
