import {
  AfterContentChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {SidebarAccordionComponent} from 'ng-sidebar-accordion';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from '@angular/router';
import Utils from './shared/utils/utils';
import {StepperComponent} from './core/components/stepper/stepper.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MappingNames, OfferSteps} from './models/mapping.model';
import {faArrowDown, faChevronDown, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {OktaAuthService} from '@okta/okta-angular';
import {ApiService} from './services/api.service';
import {AppStorageService} from './services/app-storage.service';
import {select, Store} from '@ngrx/store';
import {ApigeeService} from './services/apigee.service';
import * as fromStore from './store';
import {LocalStorageService} from './services/local-storage.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {GetDataService} from './shared/services/get-data.service';
import {Step} from './models/stepper.models';
import {selectOfferData, selectOfferListData} from './store';
import {filter, map, take, takeLast, takeUntil} from 'rxjs/operators';
import {UserSubscriptionsComponent} from './pages/user/user-subscriptions/user-subscriptions.component';
import {environment} from '../environments/environment';
import {CoreService} from './core/services/core.service';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {ProgressModalComponent} from './shared/components/progress-modal/progress-modal.component';
import {POLICY_TYPES} from './models/casco-table.model';
import {SetRenewalProductsService} from './shared/services/set-renewal-products.service';
import {
  throwError
} from 'rxjs';
import {
  catchError
} from 'rxjs/operators';
import {CookieBannerComponent} from './shared/components/cookie-banner/cookie-banner.component';
import {CookieService} from 'ngx-cookie-service';
import {setSelectedOfferCASCO} from './store/actions/selectedOffer.actions';
import {OfferErrorsService} from './services/offer-errors.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('accordion') accordion: SidebarAccordionComponent;
  arrowDown = faArrowDown;
  icoToggle = false;
  icoToggleMa = false;
  icoToggleInfo = false;
  sidebarToggle = false;
  faArrowDown = faChevronDown;
  mapping = MappingNames;
  sub: Subscription;
  subCon: Subscription;
  oktaTokenStorage;
  apigeeTokenStorage;
  showNavBar;
  faClose = faWindowClose;
  type: any;
  spinner$: Observable<boolean>;
  loading: boolean;
  routerSubscription: Subscription;
  customerId: number;
  showToastMessage;
  appType: any;
  stepperNr: any;
  steps: Step[] = [];
  stepperName: string;
  selectedStep: Step;
  isMobile: boolean;
  componentName: any;
  toastRef;

  constructor(
    private coreService: CoreService,
    private toastrService: ToastrService,
    public oktaAuth: OktaAuthService,
    public title: Title,
    public apiService: ApiService,
    public appStorageService: AppStorageService,
    public apigeeService: ApigeeService,
    private modal: NgbModal,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public activeModal: NgbActiveModal,
    private store: Store<fromStore.AppState>,
    private localStorage: AppStorageService,
    private localStorageService: LocalStorageService,
    private jwtHelper: JwtHelperService,
    private offerErrorsService: OfferErrorsService,
    private getDataService: GetDataService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private getRenewalProductsService: SetRenewalProductsService
  ) {
    this.appType = environment.app;

    this.isMobile = Utils.checkIfMobile();

    router.events.pipe().subscribe((val: any) => {
      if (val instanceof ActivationEnd) {
        this.componentName = (val.snapshot.component as any).name;
      }
    });

    // router.events.pipe(
    //   filter(e => e instanceof NavigationEnd),
    // ).subscribe((e: NavigationEnd) => {
    //   this.componentName = this.activatedRoute.routeConfig.component.name;
    // });
  }

  get compNameLength() {
    let count = 0;
    count = this.mapping[this.componentName]?.length;
    return +count;
  }

  ngOnInit() {
    this.setPageTitle();
    this.sub = this.activatedRoute.queryParams.subscribe((params) => {
      if (params.type) {
        this.type = params.type;
      }
    });

    if (this.isLoggedIn) {
      if (this.jwtHelper.isTokenExpired(this.oktaTokenStorage)) {
        this.toastrService.info('Sesiunea a expirat. Mulțumim pentru intelegere.', '', {
          timeOut: 5000,
        });
        this.logOutExpiredSession();
      } else {
        this.spinner$ = this.store.pipe(select(state => state.spinner.isOn));
        this.getDataService.refreshAllTheData();
      }
    }
    setTimeout(() => {
      this.showCookieBanner();
    }, 1000);
  }

  get showToastStatus() {
    return JSON.parse(window.sessionStorage.getItem('showToast'));
  }

  // get compWithoutToastMessage() {
  //   return this.componentName === '/offer-download' ||
  //     this.componentName === '/offer?type=casco' ||
  //     this.componentName === '/offer?type=rca' ||
  //     this.componentName === '/offer-summary' ||
  //     this.componentName === '/payment-confirmation' ||
  //     this.componentName === '/report-wrong-data';
  // }

  get compWithoutToastMessage() {
    return this.componentName === 'DownloadBoughtOfferComponent' ||
      this.componentName === 'OfferComponent' ||
      this.componentName === 'ReportWrongDataComponent' ||
      this.componentName === 'OfferSummaryComponent' ||
      this.componentName === 'PaymentConfirmedComponent' ||
      this.componentName === 'PaymentConfirmationComponent';
  }

  get compWithTimeline() {
    return this.componentName === 'OfferComponent' ||
      this.componentName === 'OfferSummaryComponent' ||
      this.componentName === 'DownloadBoughtOfferComponent';
  }

  // get compWithTimeline() {
  //   return this.componentName === '/offer-download' ||
  //     this.componentName === '/offer?type=casco' ||
  //     this.componentName === '/offer' ||
  //     this.componentName === '/offer?type=rca' ||
  //     this.componentName === '/offer-summary';
  // }

  goBackToOffer() {
    const offerId = JSON.parse(window.sessionStorage.getItem('offerId'));
    const policyMTPL = JSON.parse(window.sessionStorage.getItem('policyMTPL'));
    const policyCASCO = JSON.parse(window.sessionStorage.getItem('policyCASCO'));
    if (offerId) {
      this.getDataService.getOfferById(offerId);
      window.sessionStorage.setItem('offerId', String(offerId));
      window.sessionStorage.setItem('showToast', String(true));
      window.sessionStorage.setItem('policyMTPL', policyMTPL);
      window.sessionStorage.setItem('policyCASCO', policyCASCO);
      sessionStorage.setItem('justMtplButtonPressed', 'false');

      this.store.pipe(select(selectOfferListData), take(1)).subscribe(offers => {
        if (offers && offers.length > 0) {
          const offer = offers.find(offerList => offerList.id === offerId);
          if (offer) {
            let type: any;
            if ((offer.offerForCasco === true && offer.offerForMtpl === true) && (policyMTPL === true && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === false) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === true && policyCASCO === false)) {
              type = POLICY_TYPES.rca;
            } else if (
              (offer.offerForMtpl === true && offer.offerForCasco === true) &&
              (policyMTPL === false && policyCASCO === true)) {
              type = POLICY_TYPES.casco;
            } else {
              type = POLICY_TYPES.casco;
            }
            this.getRenewalProductsService.saveProducts(offer.id);
            this.router.navigate(['/offer'], {queryParams: {type}});
          }
        }
      });
    }
  }

  closeOfferNotification() {
    window.sessionStorage.setItem('showToast', 'false');
  }

  private setPageTitle(): void {
    const defaultPageTitle = environment.name;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;

        if (!child) {
          return this.activatedRoute.snapshot.data.title || defaultPageTitle;
        }

        while (child.firstChild) {
          child = child.firstChild;
        }

        if (child.snapshot.data.title) {
          return child.snapshot.data.title || defaultPageTitle;
        }
      })
    ).subscribe((title: string) => this.title.setTitle(title));
  }

  openStepperModalCasco() {
    const queryParams = this.activatedRoute.snapshot.queryParams.type;
    const url = this.router.url;
    // console.log(url);
    const modalRef = this.modal.open(StepperComponent as Component, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.steps = this.steps;
    if (url !== '/offer-summary') {
      if (queryParams) {
        modalRef.componentInstance.type = queryParams;
      } else {
        modalRef.componentInstance.type = 'casco';
      }
    } else {
      modalRef.componentInstance.type = url;
    }

    modalRef.componentInstance.selectedStep = this.selectedStep;
  }

  showCookieBanner() {
    const cookieValue = this.cookieService.get('acceptCookies');
    if (cookieValue === '') {
      this.modal.open(CookieBannerComponent as Component, {
        size: 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
    }

  }

  sidebarIcoToggle() {
    this.sidebarToggle = !this.sidebarToggle;
  }

  navBarPosition() {
    if (this.isMobile) {
      return 'left';
    } else {
      return 'right';
    }
  }


  changeIcoMa() {
    this.icoToggleMa = !this.icoToggleMa;
  }

  getStepperName() {
    this.steps = this.getDataService.initiateOfferSteps();
    if (this.steps && this.steps.length > 0) {
      this.selectedStep = this.steps[0];
      let stepNumber = 1;
      const cascoStep = this.steps.find(step => step.number === OfferSteps.casco);
      const mtplStep = this.steps.find(step => step.number === OfferSteps.mtpl);
      this.store.select(selectOfferData).subscribe(offer => {
        if (offer) {
          if (
            this.componentName === 'OfferComponent' &&
            offer.offerForMtpl === true && offer.offerForCasco === false
          ) {
            this.selectedStep = this.steps.find(step => step.number === OfferSteps.mtpl);
            if (this.selectedStep.isActive === true) {
              stepNumber = 1;
              if (cascoStep.isActive === true) {
                stepNumber = 2;
              }
            }
          } else if (this.componentName === 'OfferSummaryComponent') {
            this.selectedStep = this.steps.find(step => step.number === OfferSteps.payment);
            if (mtplStep.isActive === true && cascoStep.isActive === true) {
              stepNumber = 3;
            } else {
              stepNumber = 2;
            }
          } else if (this.componentName === 'DownloadBoughtOfferComponent') {
            this.selectedStep = this.steps.find(step => step.number === OfferSteps.download);
            if (mtplStep.isActive === true && cascoStep.isActive === true) {
              stepNumber = 4;
            } else {
              stepNumber = 3;
            }
          } else {
            const pageType = this.activatedRoute.snapshot.queryParams.type;
            if (pageType === 'rca') {
              this.selectedStep = this.steps.find(step => step.number === OfferSteps.mtpl);
              stepNumber = 2;
            } else {
              this.selectedStep = this.steps.find(step => step.number === OfferSteps.casco);
              stepNumber = 1;
            }
          }
          const stepsCount = this.steps.filter(step => step.isActive === true).length;
          this.stepperNr = stepNumber;
          if (this.componentName === 'DownloadBoughtOfferComponent') {
            this.stepperNr = 4;
          }
          if (this.isMobile) {
            this.stepperName = `${stepNumber}/${stepsCount} ${this.selectedStep.title}`;
          } else {
            this.stepperName = `${this.selectedStep.title}`;
          }
        } else {
          if (this.componentName === 'DownloadBoughtOfferComponent') {
            this.stepperNr = 4;
          }
          if (this.isMobile) {
            this.stepperName = `4/4 Descarcă polițe`;
          }
        }
      });
    }
  }

  get isLoggedIn() {
    this.oktaTokenStorage = window.sessionStorage.getItem('okta-token-storage');
    this.apigeeTokenStorage = window.sessionStorage.getItem('apigee');

    return !!(this.oktaTokenStorage && this.apigeeTokenStorage);
  }

  openUserSubscriptionsModal() {
    const modalRef = this.modal.open(UserSubscriptionsComponent as Component, {
      size: 'sm', centered: true, backdrop: 'static'
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  logOut() {
    this.apiService.logoutSession().subscribe(() => {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  logOutExpiredSession() {
    this.apiService.logoutSession().subscribe(() => {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
      location.reload();
    });
  }

  ngAfterContentChecked() {
    if (this.steps) {
      this.getStepperName();
    }

    this.routerSubscription = this.router.events
      .subscribe(() => {
        document.querySelector('#top-of-page')?.parentElement.scrollTo(0, 0);
      });
  }
}
