import {AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CascoOfferService} from '../../../../shared/services/casco-offer.service';
import {CascoData, InsuranceTableConfig, RenewalOfferProduct} from '../../../../models/casco-table.model';
import Utils from '../../../../shared/utils/utils';
import {CheckHeadersService} from '../../../../services/check-headers.service';
import {WarningTypeEnum} from '../../../../models/mapping.model';
import {Store} from '@ngrx/store';
import {AppState, selectOfferDataCASCO, selectSelectedCascoInsurer, selectSelectedOfferCASCO} from '../../../../store';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {setSelectedCascoInsurer, setSelectedOfferCASCO} from '../../../../store/actions/selectedOffer.actions';
import {toggleIfSelectedOfferCASCO, toggleTermsAndConditions} from '../../../../store/actions/offerToggle.actions';
import {OfferErrorsService} from '../../../../services/offer-errors.service';
import {RequestConsultantComponent} from '../../../../shared/components/request-consultant/request-consultant.component';
import {Subject} from 'rxjs';
import {GetDataService} from '../../../../shared/services/get-data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';

@Component({
  selector: 'app-marsh-tables-offer-casco',
  templateUrl: './tables-offer-casco.component.html',
  styleUrls: ['./tables-offer-casco.component.css']
})
export class TablesOfferCascoComponent implements OnInit, AfterContentChecked, OnDestroy {
  insuranceList: InsuranceTableConfig;
  selectedOffer: string;
  activeTab: any;
  isMobile: boolean;
  warningTypeEnum;
  headersSet: boolean;
  isCASCOSelectedError: boolean;
  triggerCheckSelectedCASCOOfferFromAnotherCompSubscription: Subscription;
  dataInstallments: CascoData;
  @ViewChild('goHereCasco') MyProp: ElementRef;
  @Input() renewalProduct: RenewalOfferProduct;
  @Input() isMarketingCampaignAvailable:boolean;
  selectedProduct: any;
  productWasChanged: Subject<any> = new Subject<any>();


  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private checkHeaders: CheckHeadersService,
    private offerErrorsService: OfferErrorsService,
    public activeModal: NgbActiveModal,
    public getDataService: GetDataService,
    private cascoOffer: CascoOfferService,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private localStorage: LocalStorageService) {
    this.isCASCOSelectedError = null;
    this.isMobile = Utils.checkIfMobile();
    this.insuranceList = this.cascoOffer.getConfig();
    this.warningTypeEnum = WarningTypeEnum;
    this.triggerCheckSelectedCASCOOfferFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckSelectedCASCOOffer().subscribe(() => {
        this.isCASCOSelected();
      });
  }

  ngOnInit() {
    this.store.select(selectOfferDataCASCO).subscribe(
      CASCOData => {
        if (CASCOData.installment1.length > 0 || CASCOData.installment4.length > 0 || CASCOData.installment12.length > 0) {
          if (!this.selectedProduct){
            this.selectTab(1); // select tab for installment 4 by default
          }
          this.dataInstallments = CASCOData;
        }
      }
    );
  }

  ngAfterContentChecked(){
    if (this.dataInstallments && !this.selectedProduct){
      this.setOfferProductFromLS();
    }
  }

  ngOnDestroy(){
    if (this.renewalProduct){
      this.removeSelectedProductFromLocalStorage();
    }
  }

  isCASCOSelected() {
    const isCASCOSelected = JSON.parse(sessionStorage.getItem('selectedCASCOOffer'));
    const offerId = JSON.parse(sessionStorage.getItem('offerId'));
    if (isCASCOSelected !== null) {
      this.isCASCOSelectedError = isCASCOSelected[0]?.offerId === offerId;
    } else {
        this.isCASCOSelectedError = false;
    }
  }

  productSelected(product: any): void {
    if (this.selectedProduct && product) {
      if (this.selectedProduct.renewalDueDate &&
        this.selectedProduct.productId !== product[0].productId &&
        this.selectedProduct.cascoType.toLocaleLowerCase() === 'silver' &&
        this.selectedProduct.cascoType !== product[0].cascoType &&
        this.selectedProduct.cascoProviderName === product[0].cascoProviderName) {
          this.showRiskInspectionModal(WarningTypeEnum.InspectieRiscTipCasco, product);
      } else if (this.selectedProduct.renewalDueDate &&
        this.selectedProduct.productId !== product[0].productId &&
        this.selectedProduct.cascoProviderName !== product[0].cascoProviderName) {
          this.showRiskInspectionModal(WarningTypeEnum.InspectieRiscAsigurator, product);
      } else {
        this.findSelectedProduct(
          this.dataInstallments.installment1, product[0].productId, product[0].packageId, product[0].installmentNumberId
        );
        this.findSelectedProduct(
          this.dataInstallments.installment4, product[0].productId, product[0].packageId, product[0].installmentNumberId
        );
        this.findSelectedProduct(
          this.dataInstallments.installment12, product[0].productId, product[0].packageId, product[0].installmentNumberId
        );

        this.saveSelectedProductToLocalStorage(product);
      }
    } else {
      if (product === null) {
        this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: product}));
        this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: false}));
        this.removeSelectedProductFromLocalStorage();
      } else {
        this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: product}));
        this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: true}));
        this.saveSelectedProductToLocalStorage(product);

        this.store.select(selectSelectedCascoInsurer).subscribe(result => {
          if (result && result !== product[0].cascoProviderName){
            this.store.dispatch(toggleTermsAndConditions({toggleTermsAndConditions: false}));
          }
        });
        this.store.dispatch(setSelectedCascoInsurer({selectedCascoInsurer: product[0].cascoProviderName}));
      }
    }
  }

  selectTab(tab: any) {
    this.activeTab = tab;
  }

  showRiskInspectionModal(warningType: WarningTypeEnum, product: any): NgbModalRef {
    const modalRef = this.modal.open(RequestConsultantComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.product = {product};
    this.store.select(selectSelectedOfferCASCO).subscribe(result => {
      if (result && modalRef.componentInstance) {
        if (warningType === WarningTypeEnum.InspectieRiscTipCasco) {
          modalRef.componentInstance.warningType = WarningTypeEnum.InspectieRiscTipCasco;
        } else if (warningType === WarningTypeEnum.InspectieRiscAsigurator) {
          modalRef.componentInstance.warningType = WarningTypeEnum.InspectieRiscAsigurator;
          modalRef.componentInstance.insurer = result[0].cascoProviderName;
        }
      }
    });
    modalRef.result.then((data) => {
      if (data){
        this.getDataService.cancelOnRequestConsultant.next(true);
      } else {
        this.getDataService.cancelOnRequestConsultant.next(false);
      }
      // on close
    }, (reason) => {
      // this.getDataService.cancelOnRequestConsultant.next(true);
      // on dismiss
    });
    return modalRef;
  }

  private toModel(item) {
    return [{
      item: item.premium,
      cascoProviderName: item.product.insurer.name,
      nrOfInstallments: item.installmentNumber.name,
      packType: item.package?.name === 'no' ? 'individual' : 'package',
      packageId: item.package?.id,
      installmentNumberId: item.installmentNumber.id,
      renewalDueDate: this.selectedProduct?.renewalDueDate,
      cascoType: item.product.cascoType.name,
      offerId: item.offer.id,
      productId: item.product.id,
      isSelected: item.isSelected,
      gridItemId: item.id + (item.package?.name === 'no' ? '' : 'P')
    }];
  }

  private findSelectedProduct(installment: any[], productId: number, packageId: number, installmentNumberId: number) {
    installment.forEach(data => {
      data.products.forEach(product => {
        if (product.product.id === productId
        && product.package.id === packageId
        && product.installmentNumber.id === installmentNumberId
        && (!this.renewalProduct || product.product.insurer.name === this.renewalProduct.cascoProviderName)){
          product.isSelected = true;
          if (!this.selectedProduct && this.renewalProduct){
            this.selectedProduct = this.toModel(product);
          }
          this.store.dispatch(setSelectedOfferCASCO({offerSelectedCASCO: this.toModel(product)}));
          this.store.dispatch(toggleIfSelectedOfferCASCO({toggleIfSelectedOfferCASCO: true}));
        }
        else {
          product.isSelected = false;
        }
      });
    });
  }

  private saveSelectedProductToLocalStorage(product){
    const selectedProductToLocalStorage = JSON.stringify(product);
    sessionStorage.setItem('selectedProduct', selectedProductToLocalStorage);
  }

  private removeSelectedProductFromLocalStorage(){
    sessionStorage.removeItem('selectedProduct');
  }

  private getOfferIdFromLS(): number {
    return JSON.parse(window.sessionStorage.getItem('offerId'));
  }

  private getOfferProductFromLS(){
    const product = JSON.parse(window.sessionStorage.getItem('selectedProduct'));
    const offerId = this.getOfferIdFromLS();
    if (product && product[0] && product[0].offerId === offerId){
      this.selectedProduct = product[0];
    }
  }

  private setOfferProductFromLS(){
    this.getOfferProductFromLS();
    if (this.selectedProduct){
      this.selectInstallmentTab(this.selectedProduct);
      this.findSelectedProduct(
        this.dataInstallments.installment1,
        this.selectedProduct.productId,
        this.selectedProduct.packageId,
        this.selectedProduct.installmentNumberId
      );
      this.findSelectedProduct(
        this.dataInstallments.installment4,
        this.selectedProduct.productId,
        this.selectedProduct.packageId,
        this.selectedProduct.installmentNumberId
      );
      this.findSelectedProduct(
        this.dataInstallments.installment12,
        this.selectedProduct.productId,
        this.selectedProduct.packageId,
        this.selectedProduct.installmentNumberId
      );
    }
  }

  private selectInstallmentTab(product: any){
    if (product?.installmentNumberId === 21) {
      this.selectTab(0); // select tab for installment 12
    } else if (product?.installmentNumberId === 20) {
      this.selectTab(1); // select tab for installment 4
    } else if (product?.installmentNumberId === 19) {
      this.selectTab(2); // select tab for installment 1
    } else {
      this.selectTab(1); // select tab for installment 4 by default
    }
  }
}
